<template>
    <div class="panel">
        <h1 class="text-xl mb-8">Reports</h1>
        <Form :id="id" @submit.prevent="handleSubmit">
            <CustomSelect
                name="report_quiz_id"
                label="Select Quiz"
                :options="data.reportableQuizzes"
                v-model="data.selectedQuizId"
            />

            <button type="submit"
                    class="btn btn-primary text-white" :disabled="data.isLoading || data.selectedQuizId == null" :class="data.isLoading ? 'btn--is-loading' : ''">
                <span class="loader" v-if="data.isLoading"></span>  Download Report
            </button>
        </Form>
    </div>
</template>
<script setup>
import api from "../../api/axios-api";
import Form from "../Form/Form.vue";
import {onMounted, ref} from "vue";
import CustomSelect from "../Form/CustomSelect.vue";

const data = ref({
    reportableQuizzes: [],
    selectedQuizId: null,
    isLoading: false
});

const getUsersQuizzes = async () => {
    const response = await api.get('/admin/quizzes');

    if (response.data.quizzes.data) {
        const quizzes = response.data.quizzes.data;
        quizzes.forEach(quiz => {
            data.value.reportableQuizzes.push({
                value: quiz.id,
                label: quiz.title,
            });
        })
    }
}

const handleSubmit = async (e) => {
        this.isLoading = true;
        e.preventDefault();

        // Create a temporary link element to trigger the download
        var link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        const response = await api.post('/admin/reports/quiz/' + data.value.selectedQuizId + '/download', null, {
            responseType: 'blob', // Set the response type to 'blob'
        });

        const fileNameHeader = response.headers['filename']; // Access the 'FileName' header
        const fileName = fileNameHeader ? fileNameHeader : 'filename'; // Use the received value or provide a fallback name
        const blob = new Blob([response.data], { type: 'text/csv' });
        link.href = URL.createObjectURL(blob);
        link.download = fileName; // Set the desired file name
        link.click();
}

onMounted(async () => {
    await getUsersQuizzes()
});
</script>
