<template>
    <div class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            ></FadeLoader>
        </div>
        <div v-else class="quiz-screen h-full">
            <div class="login-screen h-full">
                <router-link
                    class="nav-link block mb-10 pt-10"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">

                    <img v-if="quizData.meta !== null && quizData.meta?.logo?.url && quizData.meta?.logo?.url.length > 0" alt="" :src="quizData.meta?.logo?.url" class="max-h-11 h-full mx-auto"/>
                    <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-48 mx-auto">

                </router-link>
                <div class="mx-auto max-w-[34rem] px-8">
                    <Notices/>

                    <div class="panel">
                        <ResetPasswordForm @success="handleResetPassword"/>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script setup>
import router from "../../../router/router";
import Notices from "../../../components/Global/Notices.vue";
import Footer from "../../../layouts/Footer.vue";
import {onMounted, ref} from "vue";
import {ScreenData} from "../../../composibles/screenData.js";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import ResetPasswordForm from "../../../components/Account/ResetPasswordForm.vue";

const {setQuizData, quiz} = QuizData();
const {setQuizParticipantData} = ParticipantData();
const {setIsLoading, isScreenLoading} = ScreenData();

const props = defineProps({
    quizData: Object,
    quizParticipantData: Object
});

const setState =  async () => {
    setIsLoading(true);
    await setQuizData(props.quizData);
    await setQuizParticipantData(props.quizParticipantData);
    setIsLoading(false);
}

const handleResetPassword = () => {
    router.push({name: 'quiz-login-screen', params: {quizSlug: quiz.value.slug}});
}

onMounted(async () => {
    await setState();
});
</script>
