<template>
    <div class="panel">
        <h1 class="text-xl">Edit Profile</h1>
        <Form @submit="handleSubmit">
            <TextInput
                label="Name"
                name="name"
                placeholder="Enter your Name"
                v-model="data.updatedName"
                :errors="errors"
            />

            <TextInput
                label="Email Address"
                name="email_address"
                placeholder="Enter your Email Address"
                type="email"
                v-model="data.updatedEmail"
                :errors="errors"
            />

            <TextInput
                label="Password"
                name="password"
                placeholder="Enter your password"
                type="password"
                v-model="data.password"
                :errors="errors"
            />

            <TextInput
                label="Password Confirm"
                name="password_confirm"
                placeholder="Confirm your password"
                type="password"
                v-model="data.password_confirm"
                :errors="errors"
            />

            <LoadingButton class="btn btn-tertiary" type="submit">
                Update
            </LoadingButton>
            <span v-show="data.success">
                Account updated
            </span>
        </Form>
    </div>
</template>

<script setup>
import {useUserStore} from "../../stores/user";
import {ref} from "vue";
import api from "../../api/axios-api";
import {useNoticesStore} from "../../stores/notices";
import TextInput from "../Form/TextInput.vue";
import Form from "../Form/Form.vue";
import LoadingButton from "../Global/LoadingButton.vue";
import {useFormData} from "../../composibles/formData.js";

const {getUser, updateUserName, updateUserEmail} = useUserStore();
const {setNotice} = useNoticesStore();
const {setFormErrors, errors} = useFormData();

const data = ref({
    password: null,
    password_confirm: null,
    updatedName: getUser.name,
    updatedEmail: getUser.email,
    success: false
});
const handleSubmit = async (e) => {
    e.preventDefault();

    data.value.success = false;

    if (data.value.updatedName !== '' && data.value.updatedName !== getUser.name) {
        data.value.name =  data.value.updatedName;
    }

    if (data.value.updatedEmail !== '' && data.value.updatedEmail !== getUser.email) {
        data.value.email =  data.value.updatedEmail;
    }

    for (const key in data.value) {
        if (data.value[key] === null || data.value[key] === undefined) {
            delete data.value[key];
        }
    }

    try {
        const response =  await api.patch('/account', data.value);

        if (response.data.success) {
            if (data.value.updatedName.length > 0) {
                updateUserName( data.value.updatedName);
            }

            if (data.value.updatedEmail.length > 0) {
                updateUserEmail(data.value.updatedEmail);
            }

            data.value.success = true;
        }
    } catch (error) {
        setFormErrors(error);
    }
}
</script>
