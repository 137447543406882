<template>
    <div ref="quizWrapper" class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            />
        </div>
        <div v-else class="quiz-screen h-full">
            <QuizBaseLayout :show-sidebar="currentScreenState?.showSidebar ?? true">
                <template v-slot:nav>
                    <CountdownTimer
                        v-if="!isDesktop && currentScreenState.component.__name === 'QuizRoundPlayScreenState'"
                        :start-date="getRoundStartDate"
                        :end-date="getRoundEndDate"
                        :circle="false"
                        :primary-colour="getQuizThemeColours.primary"
                        :secondary-colour="getQuizThemeColours.secondary"
                        type="micro"
                        @timer-ended="timerEnded"
                    />
                </template>

                <template v-slot:header>
                    <Header v-if="hasCurrentRound"
                            :title="currentRound.title"
                            :round-number="currentRound.order"
                    />
                    <h1 class="text-lg font-bold sm:text-xl md:text-1xl mb-4 sm:mb-8"
                        v-if="currentScreenState.title">
                        {{ currentScreenState.title }}
                    </h1>

                    <div v-if="!isDesktop">
                        <div v-if="isQuizOpen && !quiz.automaticStart && shouldShowBroadcastVideo" class="fixed bottom-[10px] right-[15px] w-[40%] z-[999999]">
                            <BroadcastVideo
                                :quiz-id="quiz.id"
                            />
                        </div>

                        <Info :participant="participant"/>
                    </div>
                </template>

                <component :is="currentScreenState.component"/>

                <template v-slot:sidebar>
                    <template v-if="!isQuizOpen && !isQuizClosed">
                        <CountdownTimer
                            :title="quiz.scheduledStartDate > screenStore.now ? 'Opens in:' : 'Waiting for host to open'"
                            :start-date="quiz.publishedDate"
                            :end-date="quiz.scheduledStartDate"
                            :circle="false"
                            :primary-colour="getQuizThemeColours.primary"
                            format="dd\d hh\h mm\m ss\s"
                            :secondary-colour="getQuizThemeColours.secondary"
                        />
                        <div class="text-center">
                            <ShareButton/>
                        </div>
                    </template>

                    <template v-if="isDesktop">
                        <QuizParticipantInfo
                            :participant="participant"
                        />

                        <div v-if="isQuizOpen && !quiz.automaticStart && shouldShowBroadcastVideo">
                            <BroadcastVideo
                                :quiz-id="quiz.id"
                            />
                        </div>
                    </template>

                    <Overview v-if="currentScreenState.component.__name !== 'QuizRoundPlayScreenState'" :quiz="quiz"/>

                    <div class="panel panel-sm" v-if="shouldShowSidebarPanel">
                        <CountdownTimer
                            v-if="isDesktop && currentScreenState.component.__name === 'QuizRoundPlayScreenState'"
                            :start-date="getRoundStartDate"
                            :end-date="getRoundEndDate"
                            :primary-colour="getQuizThemeColours.primary"
                            :secondary-colour="getQuizThemeColours.secondary"
                            @timer-ended="timerEnded"
                        />
                        <Scoreboard v-if="!quiz.automaticStart"/>
                    </div>
                </template>
            </QuizBaseLayout>
        </div>
    </div>
</template>
<script setup>
import PowerUpPinchForm from "../../components/Quiz/Participant/PowerUpPinchForm.vue";
import QuizBaseLayout from "../../layouts/QuizBaseLayout.vue";
import CountdownTimer from "../../components/Quiz/Global/CountdownTimer.vue";
import Header from "../../components/Quiz/Global/Header.vue";
import BroadcastVideo from "../../components/Quiz/Global/BroadcastVideo.vue";
import ShareButton from "../../components/Global/ShareButton.vue";
import Overview from "../../components/Quiz/Global/Overview.vue";
import Scoreboard from "../../components/Quiz/Round/Scoreboard.vue";
import {computed, onBeforeUnmount, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {QuizData} from "../../composibles/quizData.js";
import {RoundData} from "../../composibles/roundData.js";
import {ParticipantData} from "../../composibles/participantData.js";
import Info from "../../components/Quiz/Round/Info.vue";
import QuizParticipantInfo from "../../components/Quiz/Participant/Info.vue";
import {useScreenStore} from "../../stores/screen.js";
import QuizInfoScreenState from "./States/QuizInfoScreenState.vue";
import {useParticipantStore} from "../../stores/participant.js";
import {ScreenData} from "../../composibles/screenData.js";
import QuizRoundParticipantResultsProcessingScreenState
    from "./States/QuizRoundParticipantResultsProcessingScreenState.vue";
import QuizRoundParticipantResultsScreenState from "./States/QuizRoundParticipantResultsScreenState.vue";
import QuizAccessScreenState from "./States/QuizAccessScreenState.vue";
import QuizClosedScreenState from "./States/QuizClosedScreenState.vue";
import QuizRoundResultsScreenState from "./States/QuizRoundResultsScreenState.vue";
import QuizRoundPlayScreenState from "./States/QuizRoundPlayScreenState.vue";
import QuizRoundWaitingRoomScreenState from "./States/QuizRoundWaitingRoomScreenState.vue";
import {useQuizStore} from "../../stores/quiz.js";
import {EventData} from "../../composibles/eventData.js";
import {useModal, useModalSlot} from "vue-final-modal";
import Modal from "../../components/Global/Modal.vue";
import {useToast} from "vue-toast-notification";

const quizStore = useQuizStore();
const screenStore = useScreenStore();
const participantStore = useParticipantStore();

const $toast = useToast();

const {subscribeToQuizChannelEvents, leaveChannels} = EventData();

const {
    quiz,
    setQuizData,
    currentRound,
    isQuizPrivate,
    isQuizOpen,
    isQuizClosed,
    canUserAccessQuiz,
    getQuizThemeColours,
    resetQuizStore
} = QuizData();

const {
    hasRoundStarted,
    hasRoundEnded,
    hasCurrentRound,
    getRoundStartDate,
    getRoundEndDate,
    getRoundLoadedTime
} = RoundData();

const {
    setQuizParticipantData,
    participant,
    isParticipant,
    isParticipatingInRound,
    roundAnswersFinishedProcessing,
    hasTimeStarted,
    hasCompletedRound,
    hasTimeExpired,
    resetParticipantStore
} = ParticipantData();

const {isDesktop, setIsLoading, isScreenLoading} = ScreenData();

const route = useRoute();

const props = defineProps({
    quizData: Object,
    quizParticipantData: Object
});

const data = ref({
    quizSlug: null,
    quizId: null,
    heartBeatInterval: null,
});

const currentScreenState = computed(() => {
    switch (true) {
        case (isQuizPrivate.value && !canUserAccessQuiz.value) :
            return {
                title: '',
                component: QuizAccessScreenState,
                showSidebar: false
            };
        case (isQuizOpen.value && isQuizClosed.value) :
            return {
                title: 'Quiz Closed',
                component: QuizClosedScreenState
            };
        case (hasRoundStarted.value && hasRoundEnded.value):
            return {
                title: 'Round Ended',
                component: QuizRoundResultsScreenState
            };
        case (hasRoundStarted.value && !hasRoundEnded.value && isParticipant.value && isParticipatingInRound.value && hasTimeStarted.value && !hasCompletedRound.value && !hasTimeExpired.value):
            return {
                title: '',
                component: QuizRoundPlayScreenState
            }
        case (hasRoundStarted.value && !hasRoundEnded.value && isParticipant.value && isParticipatingInRound.value && (hasCompletedRound.value || hasTimeExpired.value)):
            if (roundAnswersFinishedProcessing.value) {
                return {
                    title: hasCompletedRound.value ? 'Round Completed' : 'Time Expired',
                    component: QuizRoundParticipantResultsScreenState
                }
            } else {
                return {
                    title: '',
                    component: QuizRoundParticipantResultsProcessingScreenState
                }
            }
        case (isParticipant.value && isParticipatingInRound.value && ((!hasRoundStarted.value && !hasRoundEnded.value) || !hasTimeStarted.value)) :
            return {
                title: 'Waiting Room',
                component: QuizRoundWaitingRoomScreenState
            }
        default:
            return {
                title: quiz.value.title,
                component: QuizInfoScreenState
            }
    }
});

const shouldShowSidebarPanel = computed(() => {
    const isCurrentScreenIncluded = ['QuizRoundPlayScreenState', 'QuizRoundResultsScreenState'].includes(currentScreenState.value.component.__name);
    return ((quiz.value.automaticStart && isDesktop && !hasCompletedRound.value) || !quiz.value.automaticStart) && isCurrentScreenIncluded;
});

const shouldShowBroadcastVideo = computed(() => {
    return currentScreenState.value?.component.__name !== 'QuizRoundPlayScreenState';
});

const timerEnded = () => {
    participantStore.setRoundAsExpired({
        roundId: currentRound.value.id,
        participantId: participant.value.id
    });
}

const setState = async () => {
    try {
        setIsLoading(true);
        await setQuizData(props.quizData);
        if (canUserAccessQuiz) {
            await setQuizParticipantData(props.quizParticipantData);
            if (!quiz.value.automaticStart) {
                await subscribeToQuizChannelEvents();
                await quizStore.loadParticipants({
                    quizId: quiz.value.id
                });

                // await maybeSendHeartbeatResponse();

                if (currentRound?.powerUpAction) {
                    await showPowerUpModal(currentRound.powerUpAction);
                }
            }
        }
    } catch (err) {
        data.value.toastInstance = $toast.open({
            message: err.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });
    } finally {
        setIsLoading(false);
    }
}
const showPowerUpModal = async (data) => {
    if (!data.questions) return;

    const questions = Object.values(data.questions);
    const question = questions.filter((q) => q.participant_ids.includes(this.participant.id))[0];

    if (typeof question == 'undefined') return;

    const { open, close } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel',
            clickToClose: false,
            adaptive: true,
            draggable: false,
            scrollable: true,
            height: 'auto',
            width: '100%',
            maxWidth: 550,
        },
        slots: {
            default: useModalSlot({
                component: PowerUpPinchForm,
                attrs: {
                    message: data.message,
                    question: question.question,
                    questionId: question.question_id,
                    answers: question.answers,
                    startDate: data.start_date ? new Date(data.start_date) : false,
                    endDate: data.end_date ? new Date(data.end_date) : false
                },
                onClose() {
                    close()
                },
            })
        }
    });

    await open();
}

watch(() => route.params.quizSlug, async (newQuizSlug, oldQuizSlug) => {
    if (newQuizSlug !== oldQuizSlug) {
        await setState(props.quizData);
    }
}, {
    immediate: true
});

onBeforeUnmount(() => {
    leaveChannels();
    clearInterval(data.value.heartBeatInterval);
    resetQuizStore();
    resetParticipantStore();
});
</script>
