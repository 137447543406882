<script setup>
import QuizCard from "./QuizCard.vue";
import {computed, ref} from "vue";

const props = defineProps({
    quizzes: {
        type: Array,
        required: true,
        default: []
    }
});

const data = ref({
    pagination: null,
    activeFilter: 'all'
});

const filterQuizzes = (type) => {
    data.value.activeFilter = type;
}

const getQuizzes = computed(() => {
    if (data.value.activeFilter === 'all') {
        return props.quizzes;
    } else if (data.value.activeFilter === 'play_anytime') {
        return props.quizzes.filter((q) => q.automatic_start === 1);
    } else if (data.value.activeFilter === 'live_tournament') {
        return props.quizzes.filter((q) => q.automatic_start === 0);
    }
});

</script>

<template>
    <div class="flex justify-between items-center border-b border-grey-200">
        <div class="w-full lg:w-5/12">
            <h1 class="text-1xl text-primary mb-2">Quizzes</h1>
            <p class="text-[1.25rem] leading-snug mb-8">Have ago at playing some quizzes...</p>
        </div>
        <div class="lg:w-5/12">
            <div class="flex gap-2 items-center">
                <span class="inline-block mr-4">Filter quizzes:</span>
                <button type="button" class="btn" @click="filterQuizzes('all')" :class="data.activeFilter === 'all' ? 'btn-primary' : 'btn-outline-primary'">All</button>
                <button type="button" class="btn" @click="filterQuizzes('play_anytime')" :class="data.activeFilter === 'play_anytime' ? 'btn-primary' : 'btn-outline-primary'">Play Anytime</button>
                <button type="button" class="btn" @click="filterQuizzes('live_tournament')" :class="data.activeFilter === 'live_tournament' ? 'btn-primary' : 'btn-outline-primary'">Live Tournament</button>
            </div>
        </div>
    </div>
    <div class="mt-8">
        <div class="flex flex-wrap w-full -mx-2">
            <div class="px-2 w-full lg:w-4/12 mb-4" v-for="quiz in getQuizzes" :key="quiz.id">
                <QuizCard :quiz="quiz"/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
