<template>
    <Form @submit.prevent="formSubmit">
        <h1 class="text-xl mb-4">Play as guest</h1>
        <label for="name" class="block relative mb-4 text-lg font-medium font-sans">
            <span class="block text-gray-900 mb-2">What is your name?</span>
            <input type="text"
                   class="w-full block py-5 px-4 text-lg font-medium font-sans border border-grey-200 p-3 rounded-lg"
                   name="name" id="name"
                   v-model="data.name"
                   required
                   placeholder="Enter name">
        </label>
        <button type="submit" class="btn btn-primary">
            <template v-if="quiz.automaticStart">Play</template>
            <template v-else>Enter</template>
        </button>
    </Form>
</template>

<script setup>
import {useParticipantStore} from "../../../stores/participant.js";
import {QuizData} from "../../../composibles/quizData.js";
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useToast} from "vue-toast-notification";
import {areCookiesEnabled} from "../../../utils/helpers.js";

const {quiz} = QuizData();
const {isParticipant, maybeParticipateInRound} = ParticipantData();

const emit = defineEmits(['success']);

const route = useRoute();
const router = useRouter();
const $toast = useToast();

const participantStore = useParticipantStore();

const data = ref({
    id: 'GuestQuizForm',
    name: '',
});

const formSubmit = async (e) => {
    try {
        if (!areCookiesEnabled()) {
            $toast.open({
                message: 'Cookies must be enabled to play as a guest',
                type: 'error',
                duration: 100000,
                dismissible: false,
                position: 'top'
            });
            return;
        }

        await participantStore.participate({
            quizId: route.params.quizId,
            name: data.value.name,
            shouldJoinPresenceChannel: !quiz.value.automaticStart
        });

        await maybeParticipateInRound();

        emit('success');

    } catch (error) {
        $toast.open({
            message: error.response.data.message,
            type: 'error',
            duration: 1000,
            dismissible: false,
            position: 'top'
        });
    }
}

</script>
