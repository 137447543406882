<template>
    <div>
        <div class="mb-3">
            <QuizWinner v-if="quizStore.getQuizWinner"
                                   :name="quizStore.getQuizWinner.name"
                                   :show-confetti="quizStore.getQuizWinner"
                                   title="Quiz winner is"
            />
        </div>

        <div v-if="isParticipant && participant.overall.position !== false" class="md:flex flex-wrap md:flex-nowrap mt-4 gap-4">
            <div class="sm:w-6/12 lg:w-4/12 mb-3">
                <Summary
                    title="Your Overall Position"
                    :correct-answer-count="getOverallAnswerCount"
                    :questions-answered-count="getOverallQuestionsAnswered"
                    :completed-time="getOverallTime"
                    :position="getOverallPosition"
                />
            </div>
            <div class="sm:w-6/12 lg:w-4/12 flex-grow mb-3" v-if="getOverallTime">
                <CompletedTime
                    title="Your Overall Time"
                    :start-date="getQuizStartedAtDate"
                    :end-date="getQuizEndedAtDate"
                    :completed-date="getQuizCompletedDate"
                    :completed-time="getOverallTime"
                    :primary-colour="getQuizThemeColours.primary"
                    :secondary-colour="getQuizThemeColours.accent"
                />
            </div>
            <div class="sm:w-6/12 lg:w-4/12 mb-3">
                <RoundRankings
                    title="Your Round Ranking"
                    :round-rankings="getRoundPositions"
                    :participant-count="getQuizParticipantCount"
                />
            </div>
        </div>

        <Leaderboard/>
    </div>
</template>
<script setup>
import moment from "moment";
import QuizWinner from "../../../components/QuizWinner.vue";
import {ParticipantData} from "../../../composibles/participantData.js";
import {QuizData} from "../../../composibles/quizData.js";
import CompletedTime from "../../../components/Quiz/Participant/Results/CompletedTime.vue";
import Leaderboard from "../../../components/Quiz/Global/Leaderboard.vue";
import {useQuizStore} from "../../../stores/quiz.js";
import Summary from "../../../components/Quiz/Participant/Results/Summary.vue";
import RoundRankings from "../../../components/Quiz/Participant/Results/RoundRankings.vue";
import {computed} from "vue";

const quizStore = useQuizStore();

const {quiz, getQuizThemeColours} = QuizData();
const {participant, isParticipant} = ParticipantData();

const getQuizStartedAtDate = computed(() => {
    const roundStartDate = participant.value.rounds[0].startDate;
    return new Date(roundStartDate);
})

const getQuizEndedAtDate = computed(() => {
    let endedAtDate;
    participant.value.rounds.map((round) => {
        let roundStartDate = round.startDate;
        let roundDuration = quiz.value.rounds.find(r => r.roundId === round.id).duration;
        endedAtDate = moment(roundStartDate).add(roundDuration, 'minutes');
    });
    return endedAtDate.toDate();
});

const getQuizCompletedDate = computed(() => {
    return new Date(participant.value.rounds[participant.value.rounds.length - 1].completedDate);
})

const getOverallAnswerCount = computed(() => {
    return participant.value.overall.correctAnswerCount;
})

const getOverallQuestionsAnswered = computed(() => {
    return participant.value.overall.questionCount;
})

const getOverallTime = computed(() => {
    return participant.value.overall.totalTime;
})

const getOverallPosition  = computed(() => {
    return participant.value.overall.position;
});

const getQuizParticipantCount = computed(() => {
    return participant.value.overall.totalParticipantCount;
});

const getRoundPositions = computed(() => {
    return participant.value.overall.roundPositions;
});
</script>
