<template>
    <div class="font-normal flex items-center gap-2">
         <span class="relative flex h-2 w-2">
         <span class="absolute inline-flex h-full w-full rounded-full opacity-75"
               :class="getStatusIndicatorColour(status) + (status === 'in-progress' ? ' animate-ping' : '')"
         ></span>
          <span class="relative inline-flex rounded-full h-2 w-2"  :class="getStatusIndicatorColour(status)"></span>
        </span>
        {{ getStatusIndicatorName(status) }}
    </div>
</template>
<script setup>

const props = defineProps(['status']);

const getStatusIndicatorName = (status) => {
    let label = '';

    switch (status) {
        case 'in-progress' :
            label += 'In progress';
            break;
        case 'approved' :
            label += 'Approved';
            break;
        case 'scheduled' :
            label += 'Scheduled';
            break;
        case 'closed' :
            label += 'Closed';
            break;
        case 'pending_approval':
            label += 'Pending Approval';
            break;
        case 'draft' :
            label += 'Draft';
            break;
        case 'completed' :
            label += 'Ended';
            break;
    }
    return label;
}

const getStatusIndicatorColour = (status) => {
    let colour = '';

    switch (status) {
        case 'in-progress' :
        case 'approved' :
            colour += 'bg-green-200';
            break;
        case 'scheduled' :
            colour += 'bg-tertiary';
            break;
        case 'closed' :
        case 'pending_approval':
            colour += 'bg-red-200';
            break;
        case 'draft' :
            colour += 'bg-secondary';
            break;
        case 'completed' :
            colour += 'bg-primary';
            break;
        default :
            colour += 'bg-grey-100';
            break;
    }

    return colour;
}
</script>
