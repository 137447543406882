<template>
    <VueFinalModal class="modal flex justify-center items-center" content-class="w-full max-w-3xl">
        <div class="panel">
            <div class="flex justify-between relative border-b border-grey-200 -mx-[2rem] px-[2rem] pb-[.5rem]">
                <h1 class="mb-2 font-bold" v-if="header">{{header}}</h1>
                <button type="button" @click="close"><i class="far fa-times-circle"></i></button>
            </div>
            <div class="mt-6">
                <slot></slot>
            </div>
        </div>
    </VueFinalModal>
</template>
<script setup>
import { VueFinalModal } from 'vue-final-modal'
import 'vue-final-modal/style.css';

const emit = defineEmits(['closed']);

const props = defineProps({
    header: {
        type: String,
        default: '',
    },
});

const close = () => {
    emit('closed');
}
</script>
