<template>
    <div class="panel mb-4">
        <h4 class="tag text-center">Questions Answered</h4>
        <HorizontalChart
            :data="chartData()"
            :options="chartOptions()">
        </HorizontalChart>
    </div>
</template>

<script setup>
import {QuizData} from "../../../composibles/quizData.js";
import {RoundData} from "../../../composibles/roundData.js";
import HorizontalChart from "../../Global/HorizontalChart.vue";

const {quiz, currentRound, getQuizThemeColours} = QuizData();
const {getRoundParticipantsNames, getRoundParticipants} = RoundData();

const chartData = () => {
    const colors = [getQuizThemeColours.value.primary, getQuizThemeColours.value.secondary];
    return {
        labels: getRoundParticipantsNames.value,
        legend: {
            labels: {
                // This more specific font property overrides the global property
                fontColor: 'black'
            }
        },
        datasets: [{
            label: 'Questions Answered',
            data: getAnswerCount(),
            backgroundColor: function (context) {
                return colors[context.dataIndex % colors.length];
            },
            borderRadius: Number.MAX_VALUE,
        }]
    }
}

const chartOptions = () => {
    return {
        responsive: true,
        maintainAspectRatio: true,
        indexAxis: 'y',
        scales: {
            x: {
                ticks: {
                    fontColor: getQuizThemeColours.value.primary,
                    precision: 0,
                    beginAtZero: true,
                    fontStyle: 'bold',
                    max: currentRound.value.questionCount
                }
            },
            y: {
                grid: {
                    display:false
                },
                ticks: {
                    fontColor: getQuizThemeColours.value.primary,
                    fontStyle: 'bold',
                    max: getRoundParticipants.value.length
                }
            }
        }
    }
}

const chartStyles  = () => {
    return {
        width: `100%`,
        height: `50vh`,
        position: 'relative',
    }
}

const getAnswerCount = () => {
    return getRoundParticipants.value.map(a => Object.keys(a.answered_questions).length);
}
</script>
