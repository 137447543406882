import {useUserStore} from "../stores/user";

export function useUserData() {
    const {getUser} = useUserStore();

    function userHasRole(roles) {
        if (!Array.isArray(roles)) {
            roles = [roles]; // Ensure roles is an array
        }
        const userRoles = getUser?.account.roles.map(role => role.name);
        return roles.some(role => userRoles.includes(role));
    }

    function userHasConnectedStripeAccount() {
        return getUser.stripe_account?.id !== null;
    }

    // expose managed state as return value
    return {userHasRole,  userHasConnectedStripeAccount}
}
