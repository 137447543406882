<template>
    <div v-if="data.isLoading" class="flex justify-center">
        <FadeLoader
            :loading="data.isLoading"
            color="#E8E7EB"
        ></FadeLoader>
    </div>
    <div v-else>
        <div class="md:flex gap-4">
            <div class="lg:w-4/12 flex-grow mb-3" v-if="data.completedEndDate && data.totalTime">
                <CompletedTime
                    title="Round completed in"
                    :start-date="getRoundStartDate"
                    :end-date="getRoundEndDate"
                    :completed-date="data.completedEndDate"
                    :completed-time="data.totalTime"
                    :primary-colour="getQuizThemeColours.primary"
                    :secondary-colour="getQuizThemeColours.accent"
                />
            </div>
            <div class="lg:w-4/12 mb-3">
                <Summary
                    title="Round Summary"
                    :correct-answer-count="data.correctAnswerCount"
                    :questions-answered-count="data.questionsAnsweredCount"
                    :completed-time="data.totalTime"
                    :position="getPosition"
                />
            </div>
        </div>
        <div class="text-center">
            <ShareButton
                :text="getShareText"
            />
        </div>
        <ScrollToAnswersButton @clicked="scrollToAnswers"/>
        <div v-if="!quiz.automaticStart">
            <div v-if="getUsedPowerUps.length > 0">
                <UsedPowerUps
                    :used-power-ups="getUsedPowerUps"
                />
            </div>
        </div>
        <div v-else>
            <Leaderboard/>
            <Notify/>
            <div ref="answersScrollContainerRef">
                <Answers :answers="data.answers"/>
            </div>
            <UpcomingRound/>
        </div>
    </div>
</template>
<script setup>
import api from "../../../api/axios-api";
import {computed, onMounted, ref} from "vue";
import {RoundData} from "../../../composibles/roundData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {QuizData} from "../../../composibles/quizData.js";
import CompletedTime from "../../../components/Quiz/Participant/Results/CompletedTime.vue";
import Summary from "../../../components/Quiz/Participant/Results/Summary.vue";
import ShareButton from "../../../components/Global/ShareButton.vue";
import UsedPowerUps from "../../../components/Quiz/Participant/UsedPowerUps.vue";
import Answers from "../../../components/Quiz/Round/Answers.vue";
import UpcomingRound from "../../../components/Quiz/Round/UpcomingRound.vue";
import Leaderboard from "../../../components/Quiz/Global/Leaderboard.vue";
import {useToast} from "vue-toast-notification";
import Notify from "../../../components/Quiz/Participant/Results/Notify.vue";
import ScrollToAnswersButton from "../../../components/Global/ScrollToAnswersButton.vue";

const {quiz, currentRound, getQuizThemeColours} = QuizData();
const {hasRoundStarted, hasRoundEnded, getRoundStartDate, getRoundEndDate} = RoundData();
const {getRoundParticipant, participant} = ParticipantData();

const $toast = useToast();

const data = ref({
    completedEndDate: '',
    correctAnswerCount: 0,
    questionsAnsweredCount: 0,
    totalTime: '',
    isLoading: true,
    answers: []
});

const answersScrollContainerRef = ref(null);
const scrollToAnswers = () => {
    if (answersScrollContainerRef.value !== null) {
        const topPosition = answersScrollContainerRef.value.offsetTop;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
}

const getPosition = computed(() => {
    if (hasRoundStarted.value && hasRoundEnded.value) {
        return getRoundParticipant.value?.position;
    } else {
        return false;
    }
});

const getShareText = computed(() => {
    return [
        document.title,
        '✅' + ' ' + data.value.correctAnswerCount + '/' + data.value.questionsAnsweredCount,
        '⏱' + ' ' + data.value.totalTime
    ].join('\n');
});

const getUsedPowerUps = computed(() => {
    if (currentRound.value.powerUps.length <= 0) return [];

    const availableParticipantPowerUps = getRoundParticipant.value?.availablePowerUps;

    if (availableParticipantPowerUps.length === 0) {
        return currentRound.value.powerUps;
    } else if (availableParticipantPowerUps.length === currentRound.value.powerUps.length) {
        return [];
    } else {
        return currentRound.value.powerUps.filter(p => availableParticipantPowerUps.find((ap) => {
            return ap.id !== p.id
        }));
    }
});

const getTrend = () => {
    if (hasRoundStarted.value && hasRoundEnded.value) {
        return getRoundParticipant.value?.trend;
    } else {
        return false;
    }
}


const loadResults = async () => {
    try {
        const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/participant/' + participant.value.id + '/results');
        if (response.data.results.correct_answer_count) {
            data.value.correctAnswerCount = response.data.results.correct_answer_count;
        }
        if (response.data.results.questions_answered) {
            data.value.questionsAnsweredCount = response.data.results.questions_answered;
        }
        if (response.data.results.total_time) {
            data.value.totalTime = response.data.results.total_time;
        }
        if (response.data.results.end_date) {
            data.value.completedEndDate = new Date(response.data.results.end_date);
        }
        data.value.isLoading = false;
    } catch (err) {
        $toast.open({
            message: err.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });
    }
}

const loadAnswers = async () => {
    if (!quiz.value.automaticStart) return;
    try {
        const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/answers', {
            params: {
                participant_id: participant.value.id
            }
        });
        data.value.answers = response.data.answers;

    } catch (error) {
        $toast.open({
            message: err.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });
    }
}

onMounted(async () => {
    await loadResults();
    await loadAnswers();
});
</script>
