/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import router from "./router/router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {vue3Spinner} from 'vue3-spinner'

import '../css/app.css';
import "./bootstrap";

import Vapor from 'laravel-vapor';

window.Vapor = Vapor;
window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

//
// window.app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY})
//
// window.app.use(Particles, {
//     init: async engine => {
//         await loadConfettiPreset(engine);
//         await loadFull(engine);
//     },
// });

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
// app.use(VueToast);
// app.use(OneSignalVue);

import App from './layouts/App.vue';
import * as Sentry from "@sentry/vue";
const vfm = createVfm()

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {QuillEditor} from "@vueup/vue-quill";
import {createVfm} from "vue-final-modal";

const pinia = createPinia()
const app = createApp(App)

if (import.meta.env.VITE_APP_ENV === 'production') {
    console.log('Init sentry');
    Sentry.init({
        app,
        dsn: "https://4319e6e8dcdf40fc97011c351223767d@o4504553847062528.ingest.us.sentry.io/4505436233728000",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/quickasyoucan\.com\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.component('QuillEditor', QuillEditor)
app.mixin({
    methods: {
        asset: window.Vapor.asset
    },
});

app.use(ToastPlugin);
app.use(vfm);
app.use(vue3Spinner);
app.use(pinia);
app.use(router);
app.use(AOS);
app.mount('#app')
