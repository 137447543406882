<template>
    <div>
        <Header/>
        <div class="text-center mt-40 panel mx-auto max-w-[34rem]">
            <p v-if="isLoggedOut">
                Successfully logged out.
            </p>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from "../../components/Quiz/Global/Header.vue";
import Footer from "../../layouts/Footer.vue";

export default {
    components: {Footer, Header},
    data() {
        return {
            isLoggedOut: false
        }
    },
    beforeMount() {
        // this.$store.dispatch('logout').then(r => this.isLoggedOut = true);
    }
}
</script>
