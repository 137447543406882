<template>
    <Form ref="formRef" @submit.prevent="handleSubmit" class="mt-4" :error-message="errorMessage">
        <div class="flex gap-2 items-center justify-between">
            <TextInput
                label=""
                v-model="data.message"
                :value="data.message"
                name="message"
                placeholder="Type your message..."
                class="flex-grow !mb-0"
                input-classes="w-full block py-2.5 px-2 text-md font-medium font-sans border border-grey-200 p-3 rounded-lg"
                :errors="errors"
            />
            <button type="submit"
                    class="btn btn-primary text-white"
                    :disabled="data.isLoading || data.message.length === 0" :class="data.isLoading ? 'btn--is-loading' : ''">
                <span class="loader" v-if="data.isLoading"></span> Send
            </button>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-2 mt-4">
            <button v-for="(prompt) in data.prompts" class="border border-grey-200 p-3 text-center rounded-lg hover:bg-grey-100 transition-all"
                    type="button"
                    @click="handlePrompt(prompt)">
                {{ prompt.emoji }}
                <span class="block text-xs mt-2 font-bold">{{prompt.prompt}}</span>
            </button>
        </div>
    </Form>
</template>
<script setup>
import api from "../../../api/axios-api";
import Form from "../../Form/Form.vue";
import TextInput from "../../Form/TextInput.vue";
import {ref} from "vue";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useFormData} from "../../../composibles/formData.js";

const {quiz} = QuizData();
const {participant} = ParticipantData();

const {errors, errorMessage, setFormErrors} = useFormData();

const props = defineProps({
    isAdmin: {
        default: false,
        type: Boolean
    }
})

const data = ref({
    id: 'QuizLiveChatForm',
    isLoading: false,
    message: '',
    prompts: [
        {
            "emoji": "😊",
            "prompt": "Feeling excited!"
        },
        {
            "emoji": "🤔",
            "prompt": "Let's do this!"
        },
        {
            "emoji": "👋",
            "prompt": "Hello, everyone!"
        },
        {
            "emoji": "🎯",
            "prompt": "I\'m ready!"
        },
        {
            "emoji": "🚀",
            "prompt": "Blast off to victory!"
        },
        {
            "emoji": "🔥",
            "prompt": "Let's rock!"
        }
    ]
});

const formRef = ref(null);

const handlePrompt = (prompt) => {
    data.value.message = prompt.emoji + ' ' + prompt.prompt;
    formRef.value.onsubmit = this.formSubmit;
    formRef.value.submit();
}

const handleSubmit = async (e) => {
    e.preventDefault();
    data.value.isLoading = true;

    let requestApiRoute;

    if (props.isAdmin) {
        requestApiRoute = `/quiz/${quiz.value.id}/admin/message`;
    } else {
        requestApiRoute = `/quiz/${quiz.value.id}/participant/${participant.value.id}/message`;
    }

    try {
        await api.post(requestApiRoute, {
            message: data.value.message,
        });
        data.value.message = '';
    } catch (error) {
        setFormErrors(error);
    } finally {
        data.value.isLoading = false;
    }
}
</script>
