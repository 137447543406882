// Define an enum-like object for payment plans
export const PAYMENT_PLANS = {
    FREE: {
        name: "free",
        title: "Free",
        benefits: [
            'Benefit 1',
            'Benefit 2',
            'Benefit 3',
            'Benefit 4'
        ]
    },
    PREMIUM: {
        name: "premium",
        title: "Premium",
        benefits: [
            'Benefit 1',
            'Benefit 2',
            'Benefit 3',
            'Benefit 4'
        ]
    },
    BUSINESS: {
        name: "business",
        title: "Business",
        benefits: [
            'Benefit 1',
            'Benefit 2',
            'Benefit 3',
            'Benefit 4'
        ]
    },
};

export const allPlans = Object.values(PAYMENT_PLANS).map(plan => plan.name);


