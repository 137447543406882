<template>
    <div>
        <div v-if="isQuizOpen && !isQuizClosed">
            <Info v-if="getRoundToDisplay"
                   :round="getRoundToDisplay"
                   @play="handlePlayNowBtn"
            />

<!--            <LeaderboardTable/>-->
        </div>
        <div v-if="quiz?.image?.url" :style="{ backgroundImage: `url('${quiz.image.url}')` }"
             class="rounded-tl-lg rounded-tr-lg h-60 bg-cover bg-no-repeat bg-bottom"></div>
        <div class="panel quiz-description break-words" v-if="quiz.description" v-html="quiz.description"></div>
    </div>
</template>
<script setup>
import {QuizData} from "../../../composibles/quizData.js";
import {RoundData} from "../../../composibles/roundData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import Info from "../../../components/Quiz/Round/Info.vue";
import {useRouter} from "vue-router";
import {computed} from "vue";
import {useUserStore} from "../../../stores/user.js";
import {useParticipantStore} from "../../../stores/participant.js";

const router = useRouter();

const userStore = useUserStore();
const participantStore = useParticipantStore();
const {quiz, currentRound, nextRound, isQuizOpen, isQuizClosed, requiresPayment} = QuizData();
const {hasCurrentRound} = RoundData();
const {isParticipant, maybeParticipateInRound, maybeSendHeartbeatResponse, subscribeToParticipantChannelEvent} = ParticipantData();

const getRoundToDisplay = computed(() => {
    if (hasCurrentRound.value) {
        return currentRound.value;
    } else {
        return nextRound.value;
    }
});

const handlePlayNowBtn = async() => {
    const quizId = quiz.value.id;
    const quizSlug = quiz.value.slug

    if (!isParticipant.value) {
        if (userStore.getUser) {
            if (requiresPayment()) {
                if (quiz.value.isFundraiser) {
                    await router.push({path: `/quiz/${quizId}/donate`});
                } else {
                    await router.push({path: `/quiz/${quizId}/payment`});
                }
            } else {
                await participantStore.participate({
                    quizId: quizId,
                    shouldJoinPresenceChannel: !quiz.value.automaticStart
                });
                await maybeParticipateInRound();
                await maybeSendHeartbeatResponse();
                await subscribeToParticipantChannelEvent();
            }
        } else {
            await router.push({path: `/quiz/${quizId}/login`, query: {redirect: `/quiz/${quizSlug}`}});
        }
    } else {
        await maybeParticipateInRound();
        await maybeSendHeartbeatResponse();
    }
}
</script>
