<template>
    <div>
        <div class="panel panel-sm">
            <div class="text-center mt-4 mb-6 border-b border-grey-200 pb-6">
                <div class="border-grey-200 px-4">
                    <div
                        class="rounded-full bg-grey-200 mb-2 w-8 h-8 mx-auto flex items-center justify-center text-xs text-grey-500">
                        <i class="fas fa-user"></i>
                    </div>
                    <p class="text-sm mb-0">Created by: {{ quiz.createdBy }}</p>

                    <p v-if="quiz.isFundraiser && quiz.charityId" class="text-sm mt-4">
                        Fundraising for: <router-link :to="{name: 'charity', params: {slug: quiz.charityId}}">{{quiz.charityName}}</router-link>
                    </p>
                </div>
            </div>

            <p class="tag text-center mb-4 mt-2">Quiz overview</p>
            <div class="mb-6">
                <div v-for="(round, index) in quiz.rounds">
                    <div class="panel px-2 py-3  border font-sans mb-2" :class="getClasses(round)">
                        <div class="flex">
                            <div class="w-2/12">
                                <div
                                    class="w-[2rem] h-[2rem] font-bold border rounded-full flex items-center justify-center"
                                    :class="round.status === 'active' ? 'border-green-200 bg-green-200 text-white' : 'border-grey-200'"
                                >{{ index + 1 }}
                                </div>
                            </div>
                            <div class="flex items-center ">
                                <h4 class="mb-0.5 font-bold text-sm">{{ round.title }}</h4>
                                <div class="flex text-tiny" v-if="round.startDate.length || round.endDate.length">
                                    <template v-if="round.status === 'active' && round?.endDate !== false">
                                        Closes: {{ formattedDateAndTime(round.endDate) }}
                                    </template>
                                    <template v-else-if="round.status === 'scheduled' && round?.startDate !== false">
                                        Opens: {{ formattedDateAndTime(round.startDate) }}
                                    </template>
                                    <template v-else-if="round.status === 'closed' && round?.endDate !== false">
                                        Ended: {{ formattedDateAndTime(round.endDate) }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

import {useHelpers} from "../../../composibles/helpers.js";

const {formattedDateAndTime} = useHelpers();

const props = defineProps({
    quiz: {
        type: Object,
        default: () => {}
    },
});

const getClasses = (round) => {
    let classes = '';
    if (round.status === 'active') {
        classes += ' border-green-200 bg-green-200/10'
    } else if (round.status === 'closed') {
        classes += ' bg-grey-200/50 border-grey-200 text-grey-400 panel--no-shadow'
    } else {
        classes += ' border-grey-200 panel--no-shadow'
    }
    return classes;
}
</script>
