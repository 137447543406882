<template>
    <div class="min-h-full flex flex-col bg-cover">
        <Header class="!mb-0" data-aos="fade-down"
                       data-aos-duration="1000"
                       data-aos-delay="100"/>
        <main id="main" ref="main" class="mt-12">
            <div class="container mb-4">
                <QuizListings :quizzes="quizzes"/>
            </div>
        </main>
        <Footer/>
    </div>
</template>

<script setup>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
import QuizListings from "../components/Quiz/QuizListings.vue";
import {useQuizListings} from "../composibles/quizListings.js";
const {isLoading, quizzes} = useQuizListings();
</script>

