import axios from 'axios';

const api = axios.create({
    baseURL: '/api/v1/',
    headers: {
        'Accept': 'application/json',
    },
});
api.interceptors.request.use(config => {
    const authToken = localStorage.getItem('authToken');
    const quizToken = localStorage.getItem('accessToken');
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    if (quizToken) {
        config.headers['X-Quiz-Token'] = quizToken;
    }
    return config;
});

export default api;
