<template>
    <div class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            ></FadeLoader>
        </div>
        <div v-else class="quiz-screen h-full">
            <div class="login-screen h-full">
                <router-link
                    class="nav-link block my-6"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">
                    <img v-if="data.customLogoSrc.length > 0" alt="" :src="data.customLogoSrc" class="h-11 mx-auto">
                    <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-32 mx-auto">
                </router-link>
                <div class="mx-auto max-w-[34rem] px-8">
                    <div class="panel">
                        <div class="text-center mb-6">
                            <h1 class="mb-2 text-lg">{{quiz.title}}</h1>
                            <p class="text-xs text-grey-500">Hosted by: {{quiz.createdBy}}</p>
                        </div>
                        <div>
                            <PaymentForm
                                :quiz-id="quiz.id"
                                :payment-intent-id="data.paymentIntentId"
                                :payment-data="getPaymentData()"
                                :redirect-status="data.redirectStatus"
                                :return-url="data.returnUrl"
                                @success="handleCompletedPayment"
                                @failed="handleFailedPayment"
                            />
                            <p v-if="data.redirectMessage">{{ redirectMessage }}</p>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script setup>
import router from "../../../router/router";
import PaymentForm from "../../../components/Quiz/Payment/PaymentForm.vue";
import { onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ScreenData} from "../../../composibles/screenData.js";
import {QuizData} from "../../../composibles/quizData.js";
import Footer from "../../../layouts/Footer.vue";
import {useUserStore} from "../../../stores/user.js";
import {useParticipantStore} from "../../../stores/participant.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {QuizDonationData} from "../../../composibles/donationData.js";

const route = useRoute();

const userStore = useUserStore();
const participantStore = useParticipantStore();

const {setQuizData, quiz} = QuizData();
const {setIsLoading, isScreenLoading} = ScreenData();
const {maybeParticipateInRound, maybeSendHeartbeatResponse, subscribeToParticipantChannelEvent} = ParticipantData();
const {getDonation, donationData} = QuizDonationData();

const props = defineProps({
    quizData: Object
})

const data = ref({
    quizSlug: null,
    paymentIntentId: null,
    paymentStatusInterval: null,
    redirectStatus: null,
    customLogoSrc: '',
    returnUrl: null,
    redirectMessage: ''
});

const getPaymentData = () => {
    return {
        quizId: quiz.value.id,
        accountId: quiz.value.stripeAccountId,
        userId: userStore.getUser.id,
        isDonation: quiz.value.isFundraiser,
        giftAid: donationData.value.donationIsGiftAid ? 1 : 0,
        amount: quiz.value.isFundraiser ? donationData.value.donationAmount : quiz.value.entryFee
    }
}

const setState = async () => {
    setIsLoading(true);
    await setQuizData(props.quizData);
    await getDonation();
    data.value.returnUrl = `${window.location.origin}/quiz/${quiz.value.id}/payment/return`;
    setIsLoading(false);
}

const handleCompletedPayment = async() => {
    data.value.redirectMessage = 'Redirecting back to you quiz..';

    let quizSlug = quiz.value.slug;

    await participantStore.participate({
        quizId: route.params.quizId,
        shouldJoinPresenceChannel: !quiz.value.automaticStart
    });

    await maybeParticipateInRound();
    await maybeSendHeartbeatResponse();
    await subscribeToParticipantChannelEvent();

    await router.push({name: 'play-quiz', params: {quizSlug: quizSlug}});
}
const handleFailedPayment = () => {
    router.replace({name: 'quiz-payment-screen', params: {id: quiz.value.id}});
}

onMounted(async () => {
    data.value.paymentIntentId = route.query?.payment_intent;
    data.value.redirectStatus = route.query?.redirect_status;
    await setState();
});

</script>
