<template>
    <div class="relative mb-2.5">
        <div id="chat" ref="chat" class="h-[400px] overflow-y-scroll border p-4 sm:p-6 rounded border-grey-200">
            <TransitionGroup name="list" tag="ul">
                <li v-for="(message, index) in quizStore.getChatMessages" class="mb-6 last:mb-0" :key="message.id">
                    <span class="flex gap-1 items-end" :class="isParticipantMessage(message.participant_id) ? 'justify-end' : ''">
                        <ProfileIcon :name="message.name" class="bg-secondary"/>
                        <span class="max-w-[90%] sm:max-w-[50%]">
                            <span class="text-grey-300 block mb-2 text-tiny font-bold">{{message.name}}</span>
                            <span class="rounded-md px-2 py-1.5 inline-block text-sm"
                                  :class="getMessageClass(message)">
                                {{message.message}}
                            </span>
                        </span>
                    </span>
                </li>
            </TransitionGroup>
            <div id="anchor"></div>
        </div>
        <div class="text-center absolute -bottom-4 left-0 right-0">
            <button @click="scrollToBottom" v-if="hasUnreadMessages" class="btn btn-primary btn--with-icon items-center py-2 px-4">
                Unread Messages <span class="rounded tag bg-neutral text-primary px-1.5 py-0.5 mb-0 ml-2" v-if="getUnreadMessageCount > 0">{{getUnreadMessageCount}}</span>
            </button>
        </div>
    </div>
</template>
<script setup>
import {onBeforeUnmount, onMounted, ref, computed, watch} from "vue";
import {useQuizStore} from "../../../stores/quiz";
import {ParticipantData} from "../../../composibles/participantData";
import {QuizData} from "../../../composibles/quizData";
import ProfileIcon from "../Participant/ProfileIcon.vue";

const quizStore = useQuizStore();

const {quiz} = QuizData();

const {participant} = ParticipantData();

const chat = ref(null);

const data = ref({
    isLoading: false,
    isScrolledToBottom: false,
    hasUserScrolled: false,
    messageCount: 0,
    messageScrollTimeout: null,
    unreadMessageCount: 0,
    prevScrollPos: 0,
});

const hasUnreadMessages = computed(() => {
    return data.value.hasUserScrolled && data.value.unreadMessageCount > 0;
});

const getUnreadMessageCount = computed(( ) => {
    return data.value.unreadMessageCount;
});

const getMessageClass = (message) => {
    if (message.is_system_message) {
        return 'bg-secondary text-white'
    } else if (isParticipantMessage(message.participant_id)) {
        return 'bg-primary text-white'
    } else {
        return 'bg-grey-200';
    }
}
const isParticipantMessage = (participant_id) => {
    return participant.value.id === participant_id;
};

const loadMessages = async () => {
    console.log(quiz);

   await quizStore.loadChatMessages({
        quizId: quiz.value.id
    });

    data.messageCount = quizStore.getChatMessages
}

const scrollToBottom = () => {
    let chatRef = chat.value;

    if (chatRef !== null) {
        const chatConversationElement = chatRef.querySelector('ul');
        chatRef.scrollTo({ top: chatConversationElement.clientHeight });
        chatRef.style.scrollBehavior = 'smooth';

        const messages = chatRef.querySelectorAll('li');
        const lastMessage = messages[messages.length - 1];

        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' })
        }

        data.value.isScrolledToBottom = true;
        data.value.hasUserScrolled = false;
        data.value.unreadMessageCount = 0;
    }
}

onMounted(async () => {
    data.value.isLoading = true;

    console.log('mointing');

    await loadMessages();

    console.log('done');

    await new Promise((resolve) => {
        data.value.messageScrollTimeout = setTimeout(() => {
            scrollToBottom();
            resolve();
        }, 1000);
    });

    data.value.isLoading = false;
});

onBeforeUnmount(() => {
    if (data.value.messageScrollTimeout) {
        clearTimeout(data.value.messageScrollTimeout);
    }
});

watch(() => (quizStore.getChatMessages), (newValue) => {
    if (data.value.prevScrollPos > 0 && chat.value.scrollTop < data.value.prevScrollPos) {
        data.value.hasUserScrolled = true;
        data.value.unreadMessageCount++;
    }

    scrollToBottom();

    data.value.prevScrollPos = chat.value.scrollTop;
});
</script>
