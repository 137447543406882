import { routes } from './routes';
import api from "../api/axios-api";
import {createWebHistory, createRouter} from "vue-router";
import {useUserStore} from "../stores/user";
import AOS from "aos";
import 'aos/dist/aos.css';

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async (to, from, next) => {
    AOS.init(); // Initialize AOS

    const {storeUser} = useUserStore();

    const requiresAuth              = to.matched.some(record => record.meta.requiresAuth);
    const requiresGuest             = to.matched.some(record => record.meta.requiresGuest);
    const requiresNewQuizParticipant= to.matched.some(record => record.meta.requiresNewQuizParticipant);
    const isAccountRoute            = to.matched.some(record => record.name === 'account');

    const user = await api.get('/me');
    await storeUser(user.data);

    const pageTitle = to.meta.title;
    if (pageTitle) {
        document.title = pageTitle;
    }

    let quizIdentifier = '';
    let quizId = null;
    let quizSlug = null;
    let quiz = null;
    let quizParticipant = null;
    let quizParticipantId = null;

    if (to.params.quizId) {
        quizIdentifier = Number(to.params.quizId);
    } else if (to.params.quizSlug) {
        quizIdentifier = to.params.quizSlug;
    }

    if (quizIdentifier) {
        try {
            const quizResponse = await api.get(`/quiz/${quizIdentifier}`);
            quiz = quizResponse.data.quiz;

            if (!quiz?.id) {
                return next({ name: 'home'});
            }

            document.title = quiz.title;
            quizSlug = quiz.slug;
            quizId = quiz.id;

            // Add the quiz as a prop
            to.meta.quizData = quiz;

            if (!isAccountRoute) {
                const participantResponse = await api.get(`/quiz/${quizId}/participant`);
                quizParticipant = participantResponse.data;
                quizParticipantId = quizParticipant?.participant_id ?? null;
                to.meta.quizParticipantData = quizParticipant;
            }

        } catch (err) {
            return next({ name: 'home'});
        }
    }

    if (requiresNewQuizParticipant && quizId && quizParticipantId) {
        return next({ name: 'play-quiz', params: { quizSlug }});
    }

    if (requiresAuth) {
        if (!user.data.id) {
            if (to.name === 'logout') {
                return next({ name: 'home'});
            }
            return next({ name: 'login', query: { redirect: to.fullPath } });
        }

        try {
            const requiresQuizAdmin = to.matched.some(record => record.meta.requiresQuizAdmin);
            const requiresQuizManualStart = to.matched.some(record => record.meta.requiresQuizManualStart);
            const requiresAccountPlan = to.matched.some((record) => record.meta.requiresAccountPlan);
            const requiresPaymentPending = to.matched.some(record => record.meta.requiresPaymentPending);

            if (requiresAccountPlan) {
                const accountPlan = user.data?.account.roles[0].name;
                if (!accountPlan || !['super_admin', 'admin', 'basic', 'premium', 'business'].includes(accountPlan)) {
                    return next({ name: 'account/upgrade-plan' });
                }
            }


            if (requiresQuizAdmin) {
                if (user.data.quiz_ids.length === 0 || !user.data.quiz_ids.includes(quizId)) {
                    return next({ name: 'Unauthorized' });
                }
            }

            if (requiresQuizManualStart) {
                if (quiz && !quiz.automatic_start) {
                    return next();
                } else {
                    return next({ name: 'play-quiz', params: { quizSlug }});
                }
            }

            if (requiresPaymentPending) {
                if (user.data.paid_quizzes.includes(quizId)) {
                    return next({ name: 'play-quiz', params: { quizSlug }});
                }
            }

            return next();

        } catch (error) {
            localStorage.removeItem('authToken');
            return next({ name: 'login', query: { redirect: to.fullPath } });
        }
    }

    if (requiresGuest) {
        if (!user.data.id) {
            return next();
        }

        try {
            if (to.name === 'login' || to.name === 'register') {
                return next({ name: 'account', params: {id: user.data.account.id} });
            } else if (to.name === 'quiz-login-screen' || to.name === 'quiz-register-screen') {
                return next({ name: 'play-quiz', params: { quizSlug }});
            }
            return next();
        } catch (error) {
            localStorage.removeItem('authToken');
            return next({ name: 'login', query: { redirect: to.fullPath } });
        }
    }

    return next();
});

export default router
