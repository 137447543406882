<template>
    <form role="search"
          @submit="handleFormSubmit"
          @keyup="handleFormSubmit">
        <label class="relative">
            <input type="search"
                   :name="name"
                   :id="id"
                   :placeholder="placeholder"
                   :aria-label="ariaLabel"
                   class="w-full block mb-4 border border-grey-200 p-3 rounded-lg focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
<!--            <img :src="asset('images/search.svg')" alt="" class="absolute w-4 top-1/2 -translate-y-1/2 right-3">-->
        </label>

<!--        <button>{{this.btnText}}</button>-->
    </form>
</template>
<script setup>

const emit = defineEmits(['search']);

const props = defineProps({
    name: {
        type: String,
        default: 's'
    },
    id: {
        type: String,
        default: 'search'
    },
    placeholder: {
        type: String,
        default: 'Search the site...'
    },
    btnText: {
        type: String,
        default: 'Search'
    },
    ariaLabel: {
        type: String,
        default: 'Search through site content'
    }
});

const handleFormSubmit = (e) => {
    e.preventDefault();
    emit('search', e);
}
</script>
