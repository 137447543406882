<template>

    <div class="panel">
        <div class="flex justify-between mb-8">
            <h1 class="text-xl">My quizzes</h1>

            <div id="step1">
                <router-link :to="{ name: 'account/my-quizzes/create-quiz' }" class="btn btn-primary"
                             v-if="data.quizzes.length > 0">
                    <i class="fas fa-plus"></i> Create quiz
                </router-link>
            </div>
        </div>

        <div v-if="data.isLoading" class="flex flex-col min-h-[36rem] justify-center items-center">
            <FadeLoader
                :loading="data.isLoading"
                color="#E8E7EB"
            />
        </div>
        <div v-else-if="data.quizzes.length > 0">
            <Form>
                <ul>
                    <li class="items-center mb-2 bg-grey-100 rounded px-5 py-3.5 font-bold text-sm flex flex-wrap md:flex-nowrap items-center mb-2"
                        v-for="(quiz, quiz_index) in data.quizzes.filter(x => x.id !== null)">

                        <div class="w-full md:w-5/12 mb-2 md:mb-0">
                            <router-link :to="{ name: 'account/my-quizzes/view-quiz', params: { quizId: quiz.id }}"
                                         class='font-bold text-lg md:text-base'>{{ quiz.title }}
                            </router-link>
                        </div>

                        <div class="w-6/12 md:w-2/12 ">
                            <StatusTag :status="quiz.status"/>
                        </div>

                        <div class="w-6/12 flex-1 justify-end md:w-5/12 flex gap-0.25">
                            <router-link :to="{ name: 'account/my-quizzes/edit-quiz', params: {quizId: quiz.id}}"
                                         title="Edit quiz">
                                <button type="button"
                                        class="ml-2 bg-primary text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                    <i class="fas fa-cog" aria-label="Edit quiz"></i>
                                </button>
                            </router-link>

                            <button type="button"
                                    @click="duplicateQuiz(quiz.id)"
                                    title="Duplicate quiz"
                                    class="ml-2 bg-primary text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                <i class="far fa-copy" aria-label="Duplicate Quiz"></i>
                            </button>

                            <button type="button" @click="deleteQuiz(quiz.id, quiz_index)" title="Delete quiz"
                                    class="ml-2 bg-red-200 text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                <i class="far fa-trash-alt" aria-label="Delete quiz"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </Form>

            <Pagination v-if="data.pagination.total > 0" :links="data.pagination"/>
        </div>
        <div v-else>
            <div class="bg-secondary/10 border border-secondary px-6 py-12 rounded-2xl text-center">
                <h2 class="font-bold text-lg">Welcome to Your Quizzes Page!</h2>

                <p class="mb-6">It looks like you haven't created any quizzes yet. Don't worry—getting started is easy
                    and fun!</p>

                <router-link :to="{ name: 'account/my-quizzes/create-quiz' }" class="btn btn--large btn-primary">
                    <i class="fas fa-plus"></i> Create quiz
                </router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import api from "../../api/axios-api";
import Form from "../Form/Form.vue";
import Pagination from "../Global/Pagination.vue";
import StatusTag from "../Quiz/Global/StatusTag.vue";
import {ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

import 'v-onboarding/dist/style.css'
import {useToast} from "vue-toast-notification";

const data = ref({
    id: 'MyQuizzes',
    quizzes: [],
    pagination: [],
    isLoading: false,
});

const router = useRouter();
const route = useRoute();

const $toast = useToast();

const getUsersQuizzes = async () => {
    try {
        data.value.isLoading = true;

        const response = await api.get('/admin/quizzes', {
            params: {
                paged: route.params.page ?? 1,
            },
        })

        if (response.data.quizzes) {
            const quizzes = response.data.quizzes.data;

            let quizData = [];

            quizzes.forEach(quiz => {
                const quizObj = {
                    id: quiz.id,
                    title: quiz.title,
                    status: quiz.status,
                    createdAt: quiz.created_at,
                    url: quiz.url,
                    type: quiz.type
                }

                quizData.push(quizObj);
            });

            data.value.quizzes = quizData;

            if (response.data.quizzes.links) {
                data.value.pagination = response.data.quizzes.links;
            }
        }
    } catch (error) {
        $toast.open({
            message: error.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });
        console.error(error);
    } finally {
        data.value.isLoading = false;
    }
}

const deleteQuiz = async (quizId, index) => {
    if (confirm("Do you really want to delete?")) {
        try {
            const response = await api.delete('/quiz/' + quizId)
            if (response.data.success) {
                data.value.quizzes.splice(index, 1);
            }
        } catch (error) {
            $toast.open({
                message: error.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });
            console.error(error);
        }
    }
}

const duplicateQuiz = async (quizId) => {
    try {
        const response = await api.post('/quiz/' + quizId + '/duplicate')

        if (response.data.success && response.data.quiz) {
            data.value.quizzes.unshift({
                id: response.data.quiz.id,
                title: response.data.quiz.title,
                status: response.data.quiz.status,
                url: response.data.quiz.url,
                type: response.data.quiz.type
            });
        }
    } catch (error) {
        $toast.open({
            message: error.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });
    }
}

watch(
    () => route.path,
    (newRoundParticipants, oldRoundParticipants) => {
        getUsersQuizzes();
    },
    {
        immediate: true
    }
);

</script>
