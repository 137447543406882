<template>
    <Form @submit.prevent="handleSubmit"  :error-message="errorMessage">
        <h1 class="text-xl mb-1 font-bold">Tell us about your charity</h1>

        <div class="flex space-x-8">
            <div class="mt-6 mb-8 w-8/12">
                <TextInput
                    label="Charity Name"
                    v-model="data.charity_name"
                    name="name"
                    placeholder="Enter name"
                    :errors="errors"
                />
                <TextInput
                    label="Charity Email Address"
                    v-model="data.charity_email"
                    name="name"
                    placeholder="Enter email address"
                    :errors="errors"
                />
                <p class="text-sm  -mt-4 mb-4">
                    This must match the website address registered on the charity commission.
                </p>
                <TextInput
                    label="Charity Number"
                    v-model="data.charity_number"
                    name="charity_number"
                    placeholder="Enter number"
                    :errors="errors"
                />
                <TextInput
                    label="Website URL"
                    v-model="data.website_url"
                    name="website_url"
                    placeholder=""
                    :errors="errors"
                />

                <RichTextArea
                    label="Description"
                    name="description"
                    v-model="data.description"
                />
            </div>
            <div class="w-4/12">
                <FileUploader
                    name="logo"
                    label="Logo"
                    v-model="data.logo"
                    :file="data.logo"
                />

                <FileUploader
                    name="banner_image"
                    label="Banner Image"
                    v-model="data.banner"
                    :file="data.banner"
                />
            </div>
        </div>
        <div class="flex gap-4 items-center">
            <LoadingButton type="submit" :is-loading="isLoading" class="btn--large btn-primary">
                Save
            </LoadingButton>
        </div>
    </Form>
</template>

<script setup>
import {useUserStore} from "../../stores/user";
import Form from "../Form/Form.vue";
import TextInput from "../Form/TextInput.vue";
import {useRoute} from "vue-router";
import LoadingButton from "../Global/LoadingButton.vue";
import RichTextArea from "../Form/RichTextArea.vue";
import FileUploader from "../Form/FileUploader.vue";
import {CharityForm} from "../../composibles/charityForm.js";

const userStore = useUserStore();

const route = useRoute();
const emit = defineEmits(['success']);
const {data, handleSubmit, isLoading, errors, errorMessage}  = CharityForm();

</script>
