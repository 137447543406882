<template>
    <AnswerForm/>
</template>
<script setup>
import AnswerForm from "../../../components/Quiz/Participant/AnswerForm.vue";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {QuizData} from "../../../composibles/quizData.js";
import {useToast} from "vue-toast-notification";

const {quiz} = QuizData();

const $toast = useToast();

const data = ref({
    inactivityTimer: null,
    showActivityAlert: false,
    activityAlertInterval: null,
    toastInstance: null
});

onMounted(() => {
    if (!quiz.value.automaticStart) {
        calculateInactivityCountdownSeconds();
        initializeUserActivityEventListeners();
    }
})

onBeforeUnmount(() => {
    if (!quiz.value.automaticStart) {
        removeUserActivityEventListeners();

        data.value.showActivityAlert = false;

        if (data.value.inactivityTimer) {
            clearTimeout(data.value.inactivityTimer);
            data.value.inactivityTimer = null;
        }

        if (data.value.activityAlertInterval) {
            clearInterval(data.value.activityAlertInterval);
            data.value.activityAlertInterval = null;
        }

        if (data.value.toastInstance) {
            data.value.toastInstance.dismiss();
            data.value.toastInstance = null;
        }
    }
});

const calculateInactivityCountdownSeconds = () => {
    data.value.inactivityCountdownSeconds = 10000;
}
const getEventTypes = () => {
    return [
        'click',
        'mousemove',
        'mousedown',
        'keydown',
        'touchstart',
        'touchmove',
        'touchend'
    ]
}

const initializeUserActivityEventListeners = () => {
    const eventTypes = getEventTypes();
    for (let i = 0; i < eventTypes.length; i++) {
        // window.addEventListener(eventTypes[i], this.handleUserActivity);
    }
}

const removeUserActivityEventListeners = () => {
    const eventTypes = getEventTypes();
    for (let i = 0; i < eventTypes.length; i++) {
        window.removeEventListener(eventTypes[i], handleUserActivity);
    }
}
const handleUserActivity = () => {
    clearTimeout(data.value.inactivityTimer);

    if (!data.value.showActivityAlert) {
        let countdownDuration = 50000;

        data.value.inactivityTimer = setTimeout(() => {
            data.value.showActivityAlert = true;

            data.value.toastInstance = $toast.open({
                message: `
    <div class="inactive-users-alert">
      <h2 class="text-lg mb-2">Are you still playing?</h2>
      <p class="mb-2">If you don't confirm your activity within <span id="countdown">${countdownDuration / 1000}</span> seconds, you will be kicked from the round.</p>
      <button class="btn btn-outline">Yes I'm still playing</button>
    </div>
  `,
                type: 'warning',
                duration: 0,
                onClick: handleActivityAlertClose,
                onDismiss: handleActivityAlertClose
            });

            // Start the countdown timer
            const countdownElement = document.getElementById('countdown');
            let countdown = countdownDuration / 1000;

            data.value.activityAlertInterval = setInterval(async () => {
                countdown--;
                countdownElement.textContent = countdown;

                if (countdown <= 0) {
                    clearInterval(data.value.activityAlertInterval);

                    // Kick participant from current round
                    // await this.$store.dispatch('deleteRoundParticipant', {
                    //     quizId: this.getQuizId,
                    //     roundId: this.currentRound.id,
                    //     participantId: this.participant.id
                    // }).then(() => {
                    //     // Perform necessary actions when the countdown reaches zero (e.g., kick the user from the quiz round)
                    //     console.log("User has been kicked from the quiz round");
                    // });
                }
            }, 1000);

        },  this.inactivityCountdownSeconds);
    }
}

const handleActivityAlertClose = () => {
    data.value.showActivityAlert = false;
    removeUserActivityEventListeners();
    clearInterval(data.value.activityAlertInterval);
}
</script>
