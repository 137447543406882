<template>
    <div>
        <div class="footer mt-24">
            <div class="container">
                <div class="flex flex-wrap justify-between">
                    <p class="version text-xs mb-4">QuickAsYouCan - v{{ configStore.getVersion }}
                        <span v-if="configStore.getTestingStage !== null"
                              class="tag rounded ml-1 text-tiny inline-block px-1 py-0.5 mb-0"
                              :class="getTestingStageTagClass">
                            {{ configStore.getTestingStage }}
                </span>
                    </p>
                    <p class="text-tiny text-grey-500 mb-4">Registered trademarks: The name Quick As You Can and associated
                        logos, colours and
                        designs, are registered trademarks of <a href="https://realbranding.agency" target="_blank" class="underline">Real Branding Ltd</a></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed} from "vue";
import {useConfigStore} from "../stores/config.js";

const configStore = useConfigStore();

const  getTestingStageTagClass = computed( () => {
    if (configStore.getTestingStage === 'alpha') {
        return 'bg-grey-200 text-grey-300';
    } else if (configStore.getTestingStage === 'beta') {
        return 'bg-tertiary';
    }
});

</script>
