<template>
    <div class="panel panel--centered bg-secondary text-white h-full">
        <h4 class="text-md font-bold mb-7 tag">{{ title }}</h4>

            <div class="flex justify-center">
                <RadialProgress :diameter="200"
                                     :completed-steps="progress"
                                     :total-steps="data.totalSteps"
                                     :inner-stroke-width="3"
                                     :stroke-width="8"
                                     :inner-stroke-color="getSecondaryColour"
                                     :start-color="getPrimaryColour"
                                     :stop-color="getPrimaryColour"
                                     :is-clockwise="false"
                ><div class="timer flex flex-wrap justify-center relative">
                    <div class="countdown-inner">
                        <div class="digit">{{completedTime}}</div>
                    </div>
                </div>
                </RadialProgress>
            </div>
    </div>
</template>
<script setup>
import RadialProgress from "vue3-radial-progress";

import {computed, ref} from "vue";

const props = defineProps({
    title: String,
    startDate: Date | Boolean,
    endDate: Date | Boolean,
    completedDate: Date,
    completedTime: String,
    primaryColour: {
        type: String,
        default: '#B981F8'
    },
    secondaryColour: {
        type: String,
        default: '#FFDC22'
    }
});

const data = ref({
    totalSteps: 100
});

const progress = computed(() => {
    let start   = props.startDate,
        end     = props.endDate,
        today   = props.completedDate;

    //use Math.abs to avoid sign
    let q = Math.abs(today - start);
    let d = Math.abs(end - start);

    let progress = (q / d) * 100;

    return Math.round(progress);
});

const getPrimaryColour = computed(() => {
    return props.primaryColour;
});

const getSecondaryColour = computed(() => {
    const defaultBgColour = '#E8E7EB';
    return getContrast(props.secondaryColour, '#E8E7EB') < 1 ? defaultBgColour : props.secondaryColour;
});

const getContrast = (hexcolor1, hexcolor2) => {
    const r1 = parseInt(hexcolor1.substring(1,3),16);
    const g1 = parseInt(hexcolor1.substring(3,5),16);
    const b1 = parseInt(hexcolor1.substring(5,7),16);
    const r2 = parseInt(hexcolor2.substring(1,3),16);
    const g2 = parseInt(hexcolor2.substring(3,5),16);
    const b2 = parseInt(hexcolor2.substring(5,7),16);

    // Calculate the relative luminance of the colors
    const l1 = 0.2126 * Math.pow(r1/255, 2.2) +
        0.7152 * Math.pow(g1/255, 2.2) +
        0.0722 * Math.pow(b1/255, 2.2);
    const l2 = 0.2126 * Math.pow(r2/255, 2.2) +
        0.7152 * Math.pow(g2/255, 2.2) +
        0.0722 * Math.pow(b2/255, 2.2);

    // Calculate the contrast ratio between the colors
    const ratio = (Math.max(l1,l2) + 0.05) / (Math.min(l1,l2) + 0.05);

    return ratio.toFixed(2);
}
</script>
