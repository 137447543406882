<template>
    <div class="panel panel--with-bg bg-primary" v-if="nextRound">
        <FadeLoader :loading="true" color="#ffffff" class="left-5 top-4 scale-75 origin-left"></FadeLoader>
        <div class="flex flex-wrap justify-between">
            <div>
                <h4 class="tag mb-1 text-white">Next Round...</h4>
                <p class="text-white text-[1.5rem] font-bold">{{nextRound.title}}</p>
            </div>

            <div v-if="nextRound.startDate" class="pr-40">
                <h5 class="tag text-white mb-1">Starting in:</h5>
                <span class="inline-block text-white">
                     <CountdownTimer
                         :end-date="nextRound.startDate"
                         :circle="false"
                         :center="false"
                         :title="false"
                         format="dd\d hh\h mm\m ss\s"
                         type="blank"/>
                </span>
            </div>
        </div>
    </div>
</template>
<script setup>
import {RoundData} from "../../../composibles/roundData.js";
import CountdownTimer from "../Global/CountdownTimer.vue";
const {nextRound } = RoundData();
</script>
