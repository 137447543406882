<template>
    <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
        <input type="radio"
               :name="name"
               :class="mergedClasses"
               v-model="inputValue"
               :checked="checked"
               :value="value"
        ><span class="text-gray-900 font-bold mb-1 block">{{ label }}</span>
    </Label>
</template>
<script setup>
import {computed, ref, watch} from "vue";
import Label from "./Label.vue";

const props = defineProps({
    name: '',
    label: '',
    value: '',
    checked: false,
    classes: '',
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const emit = defineEmits(['update:modelValue']);

const defaultClasses = computed(() => {
    return {
        'mb-4': true,
        'border': true,
        'border-indigo-200': true,
        'p-3': true,
        'rounded-lg': true,
        'focus:ring': true,
        'focus:ring-indigo-200': true,
        'focus:ring-opacity-500': true,
        'mr-2': true
    };
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const mergedClasses = computed(()  => {
    return {...defaultClasses.value, ...props.classes};
});

const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

</script>
