import api from "../api/axios-api";
import {defineStore} from "pinia";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null
    }),
    getters: {
        getUser: (state) => state.user
    },
    actions: {
        setUser(payload) {
            this.user = payload;
        },
        updateUserEmail(email) {
            this.user.email = email;
        },
        updateUserName(name) {
            this.user.name = name
        },
        $reset() {
            console.log('resetting store');
            this.user = null;
        },
        storeUser( user) {
            console.log('init store', user);
            if (user?.id) {
                this.setUser(user);
            }
        },
        async logout() {
            await api.post('/logout');
            localStorage.removeItem('authToken');
            this.$reset();
        }
    }
});
