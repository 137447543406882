import HomeScreen from "../screens/HomeScreen.vue";
import AboutScreen from "../screens/AboutScreen.vue";
import LoginScreen from "../screens/Account/LoginScreen.vue";
import LogoutScreen from "../screens/Account/LogoutScreen.vue";
import RegisterScreen from "../screens/Account/RegisterScreen.vue";
import ForgotPasswordScreen from "../screens/Account/ForgotPasswordScreen.vue";
import ResetPasswordScreen from "../screens/Account/ResetPasswordScreen.vue";
import MyAccountScreen from "../screens/Account/MyAccountScreen.vue";
import MyQuizzes from "../components/Account/MyQuizzes.vue";
import EditProfile from "../components/Account/EditProfile.vue";
import UpgradePlan from "../components/Account/UpgradePlan.vue";
import Reports from "../components/Account/Reports.vue";
import QuizStats from "../components/Account/QuizStats.vue";
import ViewQuiz from "../components/Account/Quizzes/ViewQuiz.vue";
import CreateQuiz from "../components/Account/Quizzes/CreateQuiz.vue";
import AddRound from "../components/Account/Quizzes/AddRound.vue";
import AddQuestion from "../components/Account/Quizzes/AddQuestion.vue";
import ViewQuestions from "../components/Account/Quizzes/ViewQuestions.vue";
import AcceptPayments from "../components/Account/AcceptPayments.vue";
import QuizAdminRoundLaunchScreen from "../screens/Quiz/Admin/QuizAdminRoundLaunchScreen.vue";
import QuizScreen from "../screens/Quiz/QuizScreen.vue";
import QuizPaymentScreen from "../screens/Quiz/Payment/QuizPaymentScreen.vue";
import QuizSpectatorScreen from "../screens/Quiz/Spectator/QuizSpectatorScreen.vue";
import QuizLoginScreen from "../screens/Quiz/Account/QuizLoginScreen.vue";
import QuizForgotPasswordScreen from "../screens/Quiz/Account/QuizForgotPasswordScreen.vue";
import QuizResetPasswordScreen from "../screens/Quiz/Account/QuizResetPasswordScreen.vue";
import QuizRegisterScreen from "../screens/Quiz/Account/QuizRegisterScreen.vue";
import ErrorScreen from "../screens/ErrorScreen.vue";
import MyPlan from "../components/Account/MyPlan.vue";
import AccountDonations from "../components/Account/Donations.vue";
import AccountFundraisers from "../components/Account/Fundraisers.vue";

import {allPlans, PAYMENT_PLANS} from "../utils/constants.js";
import CharityScreen from "../screens/CharityScreen.vue";
import CharityOnBoardingScreen from "../screens/Account/CharityOnBoardingScreen.vue";
import EditCharity from "../components/Account/EditCharity.vue";
import QuizDonationScreen from "../screens/Quiz/Payment/QuizDonationScreen.vue";
import QuizzesScreen from "../screens/QuizzesScreen.vue";

const accountMeta = {
    requiresAccountPlan: allPlans.filter(plan => plan !== PAYMENT_PLANS.FREE.name),
};

export const routes = [
    {
        path: '/',
        component: HomeScreen,
        name: 'home',
        meta: {
            title: 'Quick As You Can',
        }
    },
    {
        path: '/about',
        component: AboutScreen,
        name: 'about',
        meta: {
            title: 'About Us - Quick As You Can',
        }
    },
    {
        path: '/quizzes',
        component: QuizzesScreen,
        name: 'quizzes',
        meta: {
            title: 'Quizzes - Quick As You Can',
        }
    },
    {
        path: '/charity/:slug',
        component: CharityScreen,
        name: 'charity',
    },
    {
        path: '/login',
        component: LoginScreen,
        name: 'login',
        meta: {
            requiresGuest: 1,
            title: 'Login',
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutScreen,
        meta: {
            requiresAuth: 1,
            title: 'Logout',
        },
    },
    {
        path: '/register',
        component: RegisterScreen,
        name: 'register',
        meta: {
            requiresGuest: 1,
            title: 'Register',
        },
    },
    {
        path: '/charity/onboard',
        component: CharityOnBoardingScreen,
        name: 'charity-onboard',
        meta: {
            requiresAuth: 1,
            title: 'Charity information',
        },
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordScreen,
        name: 'forgot-password',
        meta: {
            requiresGuest: 1,
            title: 'Forgot Password',
        },
    },
    {
        path: '/reset-password/:token/:email',
        name: 'reset-password',
        component: ResetPasswordScreen,
        meta: {
            requiresGuest: 1,
            title: 'Reset Password',
        },
    },
    {
        path: '/account/:id',
        component: MyAccountScreen,
        name: 'account',
        meta: {
            requiresAuth: 1,
            title: 'My Account',
        },
        props: route => ({
            quizData: route.meta.quizData,
        }),
        children: [
            {
                path: 'my-quizzes',
                name: 'account/my-quizzes',
                component: MyQuizzes,
                meta: {
                    ...accountMeta,
                    title: 'My Quizzes',
                }
            },
            {
                path: 'my-quizzes/page/:page',
                name: 'account/my-quizzes/paged',
                component: MyQuizzes,
                meta: {
                    ...accountMeta,
                    title: 'My Quizzes',
                }
            },
            {
                path: 'dashboard',
                name: 'account/dashboard',
                // component: MyDashboard,
                meta: {
                    title: 'My Dashboard',
                }
            },
            {
                path: 'edit-profile',
                name: 'account/edit-profile',
                component: EditProfile,
                meta: {
                    title: 'Edit Profile',
                }
            },
            {
                path: 'upgrade-plan',
                name: 'account/upgrade-plan',
                component: UpgradePlan,
                meta: {
                    title: 'Upgrade Plan',
                }
            },
            {
                path: 'reports',
                name: 'account/reports',
                component: Reports,
                meta: {
                    ...accountMeta,
                    title: 'Reports',
                },
            },
            {
                path: 'stats',
                name: 'account/stats',
                component: QuizStats,
                meta: {
                    title: 'Stats',
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId',
                name: 'account/my-quizzes/view-quiz',
                component: ViewQuiz,
                props: {editing: false},
                meta: {
                    ...accountMeta,
                    title: 'View Quiz',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/edit',
                name: 'account/my-quizzes/edit-quiz',
                component: ViewQuiz,
                props: {editing: true},
                meta: {
                    ...accountMeta,
                    title: 'Edit Quiz',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/edit/info',
                name: 'account/my-quizzes/edit-quiz/info',
                component: CreateQuiz,
                props: {editing: true},
                meta: {
                    ...accountMeta,
                    title: 'Edit Quiz',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/add-round',
                name: 'account/my-quizzes/add-round',
                component: AddRound,
                meta: {
                    ...accountMeta,
                    title: 'Add Round',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/round/:roundId/edit',
                name: 'account/my-quizzes/edit-round',
                component: AddRound,
                props: {editing: true},
                meta: {
                    ...accountMeta,
                    title: 'Edit Round',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/round/:roundId/add-question',
                name: 'account/my-quizzes/add-question',
                component: AddQuestion,
                meta: {
                    ...accountMeta,
                    title: 'Add Question',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/round/:roundId/question/:questionId/edit',
                name: 'account/my-quizzes/edit-question',
                component: AddQuestion,
                props: {editing: true},
                meta: {
                    ...accountMeta,
                    title: 'Edit Question',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/quiz/:quizId/round/:roundId/view-questions',
                name: 'account/my-quizzes/view-questions',
                component: ViewQuestions,
                meta: {
                    ...accountMeta,
                    title: 'View Questions',
                    requiresQuizAdmin: 1,
                },
            },
            {
                path: 'my-quizzes/create-quiz',
                name: 'account/my-quizzes/create-quiz',
                component: CreateQuiz,
                meta: {
                    ...accountMeta,
                    title: 'Create Quiz',
                },
            },
            {
                path: 'my-quizzes/accept-payments',
                name: 'account/accept-payments',
                component: AcceptPayments,
                meta: {
                    ...accountMeta,
                    title: 'Accept Payments',
                },
            },
            {
                path: 'my-plan',
                name: 'account/my-plan',
                component: MyPlan,
                meta: {
                    title: 'My Plan',
                }
            },
            {
                path: 'donations',
                name: 'account/donations',
                component: AccountDonations,
                meta: {
                    title: 'Donations',
                }
            },
            {
                path: 'fundraisers',
                name: 'account/fundraisers',
                component: AccountFundraisers,
                meta: {
                    title: 'Fundraisers',
                }
            },
            {
                path: 'edit-charity',
                name: 'account/edit-charity',
                component: EditCharity,
                meta: {
                    title: 'Edit Charity',
                }
            },
        ],
    },
    {
        path: '/quiz/:quizId/launch',
        component: QuizAdminRoundLaunchScreen,
        name: 'launch-quiz',
        meta: {
            requiresAuth: 1,
            requiresQuizAdmin: 1,
            requiresQuizManualStart: 1,
            title: 'Launch Quiz',
        },
        props: route => ({
            isAdmin: true,
            quizData: route.meta.quizData
        })
    },
    {
        path: '/quiz/:quizSlug/preview',
        component: QuizScreen,
        name: 'preview-quiz',
        meta: {
            requiresAuth: 1,
            title: 'Preview Quiz',
        },
        props: route => ({
            isAdmin: true,
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizSlug',
        component: QuizScreen,
        name: 'play-quiz',
        props: route => ({
            isAdmin: false,
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/viewer',
        component: QuizSpectatorScreen,
        name: 'spectate-quiz',
        props: route => ({
            isAdmin: false,
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/donate',
        component: QuizDonationScreen,
        name: 'quiz-donation-screen',
        meta: {
            requiresAuth: 1,
            requiresPaymentPending: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/payment',
        component: QuizPaymentScreen,
        name: 'quiz-payment-screen',
        meta: {
            requiresAuth: 1,
            requiresPaymentPending: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/payment/return',
        component: QuizPaymentScreen,
        name: 'quiz-payment-return-screen',
        meta: {
            requiresAuth: 1,
            requiresPaymentPending: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/login',
        component: QuizLoginScreen,
        name: 'quiz-login-screen',
        meta: {
            requiresGuest: 1,
            requiresNewQuizParticipant: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/forgot-password',
        component: QuizForgotPasswordScreen,
        name: 'quiz-forgot-password-screen',
        meta: {
            requiresGuest: 1,
            requiresNewQuizParticipant: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/reset-password/:token/:email',
        component: QuizResetPasswordScreen,
        name: 'quiz-reset-password-screen',
        meta: {
            requiresGuest: 1,
            requiresNewQuizParticipant: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/quiz/:quizId/register',
        component: QuizRegisterScreen,
        name: 'quiz-register-screen',
        meta: {
            requiresGuest: 1,
            requiresNewQuizParticipant: 1
        },
        props: route => ({
            quizData: route.meta.quizData,
            quizParticipantData: route.meta.quizParticipantData
        })
    },
    {
        path: '/unauthorized',
        component: ErrorScreen,
        name: 'unauthorized',
        props: {
            status: 401,
            title: 'Unauthorised Access'
        },
        meta: { statusCode: 401 }
    },
    {
        path: "/:catchAll(.*)",
        component: ErrorScreen,
        name: '404-screen',
        props: {
            status: 404,
            title: 'Not Found'
        },
        meta: { statusCode: 404 }
    }
]
