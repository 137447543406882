<template>
   <div>
       <div class="flex-wrap lg:flex-nowrap flex gap-4">
           <div class="lg:w-4/12 flex-grow mb-3">
               <QuizWinner v-if="quizStore.getRoundWinner"
                                      :name="quizStore.getRoundWinner.name"
                                      title="Round winner is"
               />
           </div>
           <div class="w-full  lg:w-4/12 flex-grow mb-3" v-if="data.completedEndDate && data.totalTime && isDesktop">
               <CompletedTime
                   title="You completed in"
                   :start-date="getRoundStartDate"
                   :end-date="getRoundEndDate"
                   :completed-date="data.completedEndDate"
                   :completed-time="data.totalTime"
                   :primary-colour="getQuizThemeColours.primary"
                   :secondary-colour="getQuizThemeColours.accent"
               />
           </div>
           <div class=" w-full lg:w-4/12 sm:mb-3" v-if="data.completedEndDate && data.totalTime">
               <Summary
                   title="Your Round Summary"
                   :correct-answer-count="data.correctAnswerCount"
                   :questions-answered-count="data.questionsAnsweredCount"
                   :completed-time="data.totalTime"
                   :position="getPosition"
               />
           </div>
       </div>

       <div v-if="isRoundWaitingToStart" class="mb-4">
           <UpcomingRound/>
       </div>

       <div class="mb-4">
           <ScrollToAnswersButton @clicked="scrollToAnswers"/>
           <Leaderboard/>
       </div>

       <div ref="answersScrollContainerRef">
           <Answers :answers="data.answers"/>
       </div>
   </div>
</template>
<script setup>
import api from "../../../api/axios-api";
import QuizWinner from "../../../components/QuizWinner.vue";
import Summary from "../../../components/Quiz/Participant/Results/Summary.vue";
import CompletedTime from "../../../components/Quiz/Participant/Results/CompletedTime.vue";
import UpcomingRound from "../../../components/Quiz/Round/UpcomingRound.vue";
import Answers from "../../../components/Quiz/Round/Answers.vue";
import {computed, onMounted, ref} from "vue";
import {RoundData} from "../../../composibles/roundData.js";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {ScreenData} from "../../../composibles/screenData.js";
import Leaderboard from "../../../components/Quiz/Global/Leaderboard.vue";
import {useQuizStore} from "../../../stores/quiz.js";
import ScrollToAnswersButton from "../../../components/Global/ScrollToAnswersButton.vue";

const quizStore = useQuizStore();

const {isDesktop} = ScreenData();
const {quiz, currentRound, getQuizThemeColours} = QuizData();
const {getRoundStartDate, getRoundEndDate, hasRoundStarted, hasRoundEnded, isRoundWaitingToStart} = RoundData();
const {participant} = ParticipantData();

const data = ref({
    answers: [],
    completedEndDate: '',
    correctAnswerCount: 0,
    questionsAnsweredCount: 0,
    totalTime: '',
});

const answersScrollContainerRef = ref(null);
const scrollToAnswers = () => {
    if (answersScrollContainerRef.value !== null) {
        const topPosition = answersScrollContainerRef.value.offsetTop;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
}

const getPosition = computed( () => {
    if (hasRoundStarted.value && hasRoundEnded.value && participant.value.id !== null) {
        return participant.value.rounds.find(r => r.roundId === currentRound.value.id).position;
    } else {
        return false;
    }
});

const getTrend = computed(() => {
    if (hasRoundStarted.value && hasRoundEnded.value && participant.value.id !== null) {
        return participant.value.rounds.find(r => r.roundId === currentRound.value.id).trend;
    } else {
        return false;
    }
});

const loadResults = async ()  => {
    try {
        const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/participant/' + participant.value.id + '/results');

        console.log(response.data);

        if (response.data.results.correct_answer_count) {
            data.value.correctAnswerCount = response.data.results.correct_answer_count;
        }
        if (response.data.results.questions_answered) {
            data.value.questionsAnsweredCount = response.data.results.questions_answered;
        }
        if (response.data.results.total_time) {
            data.value.totalTime = response.data.results.total_time;
        }
        if (response.data.results.end_date) {
            data.value.completedEndDate = new Date(response.data.results.end_date);
        }
    } catch (error) {
        console.error(error);
    }
}

const loadAnswers = async () => {
    try {
        const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/answers', {
            params: {
                participant_id: participant.value.id
            }
        });
        data.value.answers = response.data.answers;
    } catch (error) {
        console.error(error);
    }
}

onMounted(async () => {
    await loadResults();
    await loadAnswers();
})
</script>
