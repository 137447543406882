import axios from "axios";
import Pusher from "pusher-js";
import Echo from 'laravel-echo';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = baseUrl;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import {authorizePublicBroadcasting} from "./auth/publicBroadcastingAuth";
import {authorizeRegularBroadcasting} from "./auth/regularBroadcastingAuth";
import {authorizeParticipantBroadcasting} from "./auth/participantBroadcastingAuth";

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    encrypted: true,
    forceTLS: false,
    authorizer: (channel, options) => {
        return {
            authorize: async (socketId, callback) => {
                if (channel.name.startsWith('presence-streaming-channel')) {
                    await authorizePublicBroadcasting(socketId, channel, callback);
                } else if (channel.name.startsWith('presence-quiz-participant-streaming-channel'))   {
                    await authorizeParticipantBroadcasting(socketId, channel, callback);
                } else {
                    await authorizeRegularBroadcasting(socketId, channel, callback);
                }
            }
        };
    },
});

