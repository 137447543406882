<template>
    <div class="h-full bg-primary">
        <template>
            <div v-if="isLoading" class="flex flex-col h-screen justify-center items-center">
                <fade-loader
                    :loading="isLoading"
                    color="#E8E7EB"
                ></fade-loader>
            </div>
            <div v-else class="quiz-screen h-full">
                <div class="flex gap-4 p-6">
                    <div class="w-full w-4/12">
                        <div v-if="isQuizOpen && !getAutomaticStart && shouldShowBroadcastVideo">
                            <quiz-broadcast-video-component
                                :quiz-id="getQuizId"
                            />
                        </div>
                        <div class="panel panel-sm">
                            <quiz-countdown-timer-component
                                :start-date="getRoundStartDate"
                                :end-date="getRoundEndDate"
                                :primary-colour="getQuizPrimaryColour"
                                :secondary-colour="getQuizSecondaryColour"
                            />
                            <quiz-scoreboard-component/>
                        </div>
                    </div>
                    <div class="w-full w-8/12">
                        <quiz-admin-answered-questions-graph-component/>
                        <quiz-admin-completed-questions-component/>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>

import QuizScreen from "../QuizScreen.vue";

export default {
    extends: QuizScreen
}
</script>
