<template>
    <div class="panel sticky top-4">
        <div class="text-center mb-8">
            <div class="rounded-full bg-grey-200 w-20 h-20 mx-auto mb-4 flex items-center justify-center text-xl text-grey-500">
                <i class="fas fa-user"></i>
            </div>

            <h3 class="text-lg mb-1 font-bold">{{userStore.getUser.name}}</h3>
            <p class="text-grey-500">{{userStore.getUser.email}}</p>

            <span v-if="userStore.getUser?.account?.roles?.[0]?.name" class="tag inline-flex bg-primary text-white rounded px-1.5 py-1 items-center text-tiny text-center mb-0">
                {{ getRoleLabelFromName(userStore.getUser?.account?.roles?.[0]?.name) }}
            </span>
        </div>
        <ul>
            <li v-if="userHasRole(['premium', 'business'])">
                <router-link :to="{ name: 'account/dashboard' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                            >
                    <img alt="" :src="asset('images/graph.svg')" class="w-4 mr-2"><span>Dashboard</span>
                </router-link>
            </li>
            <li v-if="!userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/my-quizzes' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                >
                    <img alt="" :src="asset('images/question-mark.svg')" class="w-4 mr-2"><span>My Quizzes</span>
                </router-link>
            </li>
            <li v-if="userHasRole(['premium', 'business']) && userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/donations' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                >
                    <img alt="" :src="asset('images/question-mark.svg')" class="w-4 mr-2"><span>Donations</span>
                </router-link>
            </li>
            <li v-if="userHasRole(['premium', 'business']) && userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/fundraisers' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                >
                    <img alt="" :src="asset('images/question-mark.svg')" class="w-4 mr-2"><span>Fundraisers</span>
                </router-link>
            </li>
            <li v-if="userHasRole(['super_admin']) || (userHasRole(['admin', 'business']) && userStore.getUser.account.is_charity)">
                <router-link :to="{ name: 'account/accept-payments' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                >
                    <img alt="" :src="asset('images/question-mark.svg')" class="w-4 mr-2"><span>Accept Payments</span>
                </router-link>
            </li>
            <li v-if="userHasRole(['premium', 'business'])  && userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/reports' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary">
                    <img alt="" :src="asset('images/graph.svg')" class="w-4 mr-2"><span>Reports</span>
                </router-link>
            </li>
            <li v-if="!userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/stats'}"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary">
                    <img alt="" :src="asset('images/graph.svg')" class="w-4 mr-2"><span>My Stats</span>
                </router-link>
            </li>
            <li v-if="userStore.getUser.account.is_charity">
                <router-link :to="{ name: 'account/edit-charity' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary"
                >
                    <img alt="" :src="asset('images/question-mark.svg')" class="w-4 mr-2"><span>Edit Charity</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'account/edit-profile' } "
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary">
                    <img alt="" :src="asset('images/tick-mark.svg')" class="w-4 mr-2"><span>Edit profile</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'account/my-plan' }"
                             class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                             exact-active-class="bg-secondary">
                    <img alt="" :src="asset('images/tick-mark.svg')" class="w-4 mr-2"><span>Plans</span>
                </router-link>
            </li>
        </ul>

        <div class="text-center">
            <button class="text-md mt-4 text-grey-500" @click="handleLogout"><i class="fas fa-sign-out-alt"></i> Logout</button>
        </div>
    </div>
</template>

<script setup>
import {useUserData} from "../../composibles/userData";
import {useUserStore} from "../../stores/user";
import {useRouter} from "vue-router";

const router = useRouter();
const {userHasRole} = useUserData();
const userStore = useUserStore();

const handleLogout = async(e) => {
    e.preventDefault();
    await userStore.logout();
    await router.push('/');
}

const getRoleLabelFromName = (name) => {
    return name.replace('_', ' ');
}

</script>
