import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useScreenStore} from "../stores/screen.js";

export function ScreenData() {
    const screenStore = useScreenStore();

    const isDesktop = ref(true);
    const timerInterval = ref(null);

    const checkScreenSize = () => {
        isDesktop.value = window.innerWidth >= 1024 // only render if screen width is 1024px or greater
    }

    const setIsLoading = (value) => {
        screenStore.setIsLoading(value);
    }

    const isScreenLoading = computed(() => {
       return screenStore.getIsLoading;
    });

    onMounted(() => {
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        screenStore.updateTime();
        timerInterval.value = setInterval(() => {
            screenStore.updateTime();
        }, 1000);
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', checkScreenSize);
        clearInterval(timerInterval.value);
    });

    return {
        setIsLoading,
        isScreenLoading,
        checkScreenSize,
        isDesktop,
    }
}

