import {defineStore} from "pinia";

export const useNoticesStore = defineStore('notices', {
    state: () => ({
        notice: {
            message: '',
            type: '',
        }
    }),
    getters: {
        getNotice: (state) => state.notice
    },
    actions: {
        setNotice(payload) {
            let {message, type} = payload;

            this.notice = {
                message: message,
                type: type
            }
        },
        clearNotice() {
            this.notice = {
                message: '',
                type: '',
            };
        },
    },
});
