<template>
    <div class="login-screen h-full">
        <router-link
            class="nav-link block mb-8 pt-8"
            data-toggle="collapse"
            :to="{ name: 'home' }">
            <img alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-32 mx-auto">
        </router-link>
        <div class="mx-auto max-w-[34rem] px-8">
            <div class="panel">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>
