<template>
    <div class="mx-auto max-w-[34rem] px-8">
        <div class="panel">
            <form-component @on-submit="formSubmit" :id="id" :errors="errors">
                <h1 class="text-xl mb-4"><i class="fas fa-lock text-primary"></i> {{ quiz.title }}: Private Access</h1>
                <p class="text-lg text-grey-300">This quiz is private and requires a valid access code to enter.</p>
                <div class="mt-8">
                    <label for="name" class="block relative mb-4 text-lg font-medium font-sans">
                        <span class="block text-gray-00 mb-2">Access Code</span>
                        <input type="password"
                               class="w-full block py-5 px-4 text-lg font-medium font-sans border border-grey-200 p-3 rounded-lg"
                               name="access_code" id="access_code"
                               v-model="form.access_code"
                               required
                               placeholder="Enter Code">
                    </label>
                    <button type="submit" class="btn btn-primary">
                        Enter
                    </button>
                </div>
            </form-component>
        </div>
    </div>
</template>
<script>

import api from "../../../api/axios-api";


export default {
    data() {
        return {
            id: 'QuizAccessForm',
            form: {
                access_code: '',
            }
        }
    },
    methods: {
        async formSubmit(e) {
            e.preventDefault();
            api.post('/quiz/' + this.getQuizId + '/access', {
                form: this.form
            }).then((response) => {
                const token = response.data.token;
                localStorage.setItem('accessToken', token);
                this.$store.commit('setUserCanAccess', true);
            }).catch(error => {
               this.setFormErrors(error);
            });
        },
    }
}
</script>
