<template>
    <div class="flex gap-4 w-full h-full">
        <div class="panel panel-sm w-full bg-white flex  flex-col">
            <div>
                <div class="flex justify-between">
                    <div class="border border-grey-200 rounded-sm w-16 h-16 flex items-center px-1 mb-2">
                        <img alt="" v-if="quiz?.charity?.logo?.url" :src="quiz?.charity?.logo?.url" class="w-32">
                        <img alt="Quick As You Can" v-else src="../../../img/logo.svg" class="w-32">
                    </div>
                    <div>
                        <span class="tag rounded mr-1 inline-block text-tiny px-1.5 py-1 self-start"
                               :class="quiz.automatic_start ? 'bg-tertiary' : 'bg-secondary text-white'">
                        <template v-if="quiz.automatic_start">
                            Play Anytime
                        </template>
                        <template v-else>
                            Live Tournament
                        </template>
                    </span>
                        <span v-if="quiz.is_fundraiser"
                              class="tag bg-neutral text-primary rounded mr-1 inline-block text-tiny px-1.5 py-1 self-start">
                        Fundraiser Quiz!
                    </span>
                    </div>
                </div>
                <h3 class="text-[1.5rem] mb-2 font-bold">{{ quiz.title }}</h3>
                <p class="mb-2 text-sm text-grey-500">Created by: {{ quiz.created_by }}</p>
                <p class="mb-2 text-sm text-grey-500" v-if="quiz.total_raised">Total raised:
                    £{{ quiz.total_raised }}</p>

                <p v-if="getActiveRound(quiz.rounds)" class="mb-2 text-sm text-grey-500">
                    Active round: {{ getActiveRound(quiz.rounds).title }}
                </p>
            </div>
            <div class="flex mt-auto justify-between items-center pt-8">
                <StatusTag :status="quiz.status"/>
                <router-link class="btn btn-primary" :to="{name: 'play-quiz', params: {quizSlug: quiz.slug}}">View
                </router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import StatusTag from "./Global/StatusTag.vue";

const props = defineProps({
    quiz: null,
});

const getActiveRound = (rounds) => {
    const activeRound = rounds.find((r) => r.status === 'active');
    if (activeRound) {
        return activeRound;
    }
}

</script>

