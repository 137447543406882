<template>
    <div class="table-scroll-wrapper overflow-auto mb-8 h-96 pr-8" v-if="participantAnswers.length > 0">
        <table style="border-spacing: 0 5px;"
               class="w-full text-left rounded-md bg-white shadow-sm border-separate">
            <thead
                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 bg-white">
                <th scope="col" class="p-2.5 pt-0">Name</th>
                <th scope="col" class="p-2.5 pt-0 text-right">Time taken</th>
            </thead>
            <tbody>
            <tr v-for="(answer, index) in participantAnswers" @click="handleAnswerSelected(answer)"
                :class="answer.answer_id === answerId ? 'bg-neutral' : ''" class="cursor-pointer">
                <td class="p-2.5 text-sm rounded-bl rounded-tl border-l border-t border-b border-grey-200 w-9/12">
                    <span class="block w-full font-bold">{{ answer.name }}</span>
                </td>
                <td class="p-2.5 text-sm text-right font-bold font-sans  rounded-br rounded-tr border-r border-t border-b border-grey-200">
                    <span>{{ answer.time_taken }}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div v-else>
        No participants found.
    </div>
</template>
<script setup>

const emit = defineEmits(['answerSelected']);

const props = defineProps({
    participantAnswers: Array,
    answerId: Number
});

const handleAnswerSelected = (answer) => {
    emit('answerSelected', answer)
}
</script>
