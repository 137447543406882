<template>
    <Form @submit.prevent="handleSubmit" :error-message="errorMessage">
        <h1 class="text-xl mb-1 font-bold">Sign in</h1>
        <p class="text-md">New to Quick as you can?
            <router-link :to="{ name: getRegisterRouteName}">
                <span data-bg-colour="white" class="underline text-secondary font-bold">Register for free</span>
            </router-link>
        </p>
        <div class="mt-6 mb-8">
            <TextInput
                label="Email Address"
                v-model="data.email"
                name="email"
                type="email"
                placeholder="Enter email address"
                :errors="errors"
            />

            <TextInput
                label="Password"
                v-model="data.password"
                name="password"
                type="password"
                placeholder="Enter your password"
                :errors="errors"
            />

<!--            <checkbox-component-->
<!--                label="Remember me"-->
<!--                v-model="remember_me"-->
<!--                name="remember_me"-->
<!--            />-->

            <p class="text-lg mt-2 text-sm text-grey-300 underline">
                <router-link :to="{ name: 'forgot-password' }">
                    Forgot your password?
                </router-link>
            </p>
        </div>

        <div class="flex flex-col items-center">
            <LoadingButton type="submit" :is-loading="data.isLoading" class="btn--large btn-primary">
                Sign In
            </LoadingButton>
        </div>
    </Form>
</template>

<script setup>
import api from "../../api/axios-api";
import {computed, ref} from "vue";
import {useNoticesStore} from "../../stores/notices";
import Form from "../Form/Form.vue";
import TextInput from "../Form/TextInput.vue";
import LoadingButton from "../Global/LoadingButton.vue";
import {useRoute} from "vue-router";
import {useUserStore} from "../../stores/user.js";
import {useFormData} from "../../composibles/formData.js";

const data = ref({
    id: 'LoginForm',
    email: '',
    password: '',
    remember_me: false,
    isLoading: false,
});

const {setNotice} = useNoticesStore();
const userStore = useUserStore();
const route = useRoute();
const {setFormErrors, errors, errorMessage} = useFormData();

const emit = defineEmits(['success']);

const getRegisterRouteName = computed(() => {
    if (route.name === 'quiz-login-screen') {
        return 'quiz-register-screen';
    }
    return 'register'
});

const handleSubmit = async () => {
    data.value.isLoading = true;

    try {
        const response = await api.post('/login', {
            email: data.value.email,
            password: data.value.password,
            remember_me: data.value.remember_me
        });
        if (response.data.access_token) {
            localStorage.setItem('authToken', response.data.access_token);
            emit('success');
        }
    } catch (error) {
        console.log(error);
        setFormErrors(error);
    } finally {
        data.value.isLoading = false;
    }
}
</script>
