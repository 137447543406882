import {computed, ref} from "vue";
import api from "../api/axios-api.js";
import {QuizData} from "./quizData.js";
import {useToast} from "vue-toast-notification";
import {useRoute} from "vue-router";

export function QuizDonationData() {
    const route = useRoute();

    const isLoading = ref(false);

    const donationData = ref({
        donationAmount: null,
        donationIsGiftAid: null,
        customLogoSrc: ''
    });

    const getDonation = async () => {
        try {
            isLoading.value = true;

            const response = await api.get('/donate', {
                params: {
                    quizId: route.params.quizId,
                },
            });
            if (response.data.payment.amount) {
                donationData.value.donationAmount = response.data.payment.amount;
            }
            if (response.data.payment.gift_aid) {
                donationData.value.donationIsGiftAid = !!response.data.payment.gift_aid;
            }
        } catch (err) {

        } finally {
            isLoading.value = false;
        }
    }

    return {
        getDonation,
        isLoading,
        donationData
    }
}

