<template>
    <div>
        <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
            <div class="w-full text-center text-lg font-medium font-sans border border-grey-300 rounded-lg"
                 @dragenter="dragEnter"
                 @dragleave="dragLeave"
                 :class="getClasses">
                <div
                    class="flex flex-col relative items-center justify-center py-12 px-4 cursor-pointer min-h-[100px] rounded-lg border-4 border-dashed border-transparent"
                    :class="data.isDragged ? 'border-grey-300' : ''">
                    <template v-if="getPreviewUrl.length === 0">
                        <span class="block mb-6">
                            <i class="fas fa-images text-2xl" :class="data.isDragged ? 'text-grey-500' : 'text-primary'"></i>
                        </span>
                        <p class="text-base text-grey-500" v-if="data.isDragged">Drop here</p>
                        <p class="text-base" v-else>Drag &amp; drop files here or <strong style="cursor: pointer"><u>browse</u></strong>
                        </p>
                    </template>
                    <div v-else class="file-preview">
                        <button type="button" @click="deleteFile"
                                class="absolute z-10 m-4 top-0 right-0 bg-red-200 text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                            <i class="far fa-trash-alt" aria-label="Delete image"></i></button>
                        <img alt="" :src="getPreviewUrl">
                    </div>

                    <input @change="handleFileUpload"  type="file" :id="name" :name="name"
                           class="z-0 absolute inset-0 opacity-0 cursor-pointer">
                </div>
            </div>
        </Label>
    </div>
</template>
<script setup>
import api from "../../api/axios-api";
import {ref, defineProps, computed, watch} from "vue";
import {useConfigStore} from "../../stores/config";
import Label from "./Label.vue";

const {getEnv} = useConfigStore();

const emit = defineEmits(['input', 'error']);

const props = defineProps({
    name: String,
    label: String,
    file: String | Object,
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const data = ref({
    classes: '',
    uploadProgress: 0,
    isDragged: false,
    localFile: props.file ? { ...props.file } : null, // Create a local copy of the prop
    previewUrl: ''
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const getPreviewUrl = computed(() => {
    if (data.value.localFile?.url?.length > 0) {
        return data.value.localFile.url;
    } else {
        return data.value.previewUrl;
    }
});

const getClasses = computed(() => {
    let classes = data.value.classes;
    if (data.value.isDragged) {
        classes += ' bg-grey-100';
    }
    return classes;
})

watch(() => props.file, (newValue) => {
    if (newValue) {
        data.value.localFile = { ...newValue }; // Update localFile when the prop changes
    } else {
        data.value.localFile = null; // Reset localFile if the prop becomes null
    }
});

const handleFileUpload = (e) => {
    e.preventDefault();
    setPreviewImage(e.target.files[0]);
    emit('input', e);
}


const setPreviewImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        data.value.previewUrl = e.target.result;
    };
    reader.readAsDataURL(file);
}


const dragEnter = () => {
    data.value.isDragged = true;
}

const dragLeave = () => {
    data.value.isDragged = false;
}

const deleteFile = async () => {
    if (confirm('Are you sure you want to delete this image?')) {
        try {
            if (props.file?.id) {
                const response = await api.delete(`/upload/${props.file.id}`);
                if (response.status === 200) {
                    data.value.previewUrl = '';
                }
            } else {
                emit('input', null);
            }

            data.value.localFile = null; // Clear local file
        } catch (error) {
            if (error.response) {
                let data = error.response.data;
                let message = data.message;
                emit('error', message);
            }
        }
    }
}

</script>
