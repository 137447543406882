// regularBroadcastingAuth.js
import api from "../api/axios-api";

export async function authorizeParticipantBroadcasting(socketId, channel, callback) {
    const channelNameParts = channel.name.split('.');
    const quizId = channelNameParts[1];

    try {
        const response = await api.post('/quiz-participant-broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
            quiz_id: quizId
        });
        callback(false, response.data);
    } catch (error) {
        callback(true, error);
    }
}
