<template>
    <div class="progress h-2 rounded-lg mb-6 md:mb-8 px-0.5 flex items-center" :style="{backgroundColor: getBackgroundColour}">
        <div class="progress-inner w-0 h-1 rounded-lg transition-[width] duration-200 ease-in-out" :style="{width: progress + '%', backgroundColor: getSecondaryColour}"></div>
    </div>
</template>
<script setup>

import {computed} from "vue";

const props = defineProps({
    progress: Number,
    primaryColour: {
        type: String,
        default: '#140B33'
    },
    secondaryColour: {
        type: String,
        default: '#B981F8'
    },
    backgroundColor: {
        type: String,
        default: '#E8E7EB'
    }
});

const getSecondaryColour = computed(() => {
    return getContrast(props.secondaryColour, props.backgroundColor) < 2 ? props.primaryColour : props.secondaryColour;
});

const getBackgroundColour  = computed(() => {
    return props.backgroundColor;
});

const getContrast = (hexcolor1, hexcolor2) => {
    const r1 = parseInt(hexcolor1.substring(1,3),16);
    const g1 = parseInt(hexcolor1.substring(3,5),16);
    const b1 = parseInt(hexcolor1.substring(5,7),16);
    const r2 = parseInt(hexcolor2.substring(1,3),16);
    const g2 = parseInt(hexcolor2.substring(3,5),16);
    const b2 = parseInt(hexcolor2.substring(5,7),16);

    // Calculate the relative luminance of the colors
    const l1 = 0.2126 * Math.pow(r1/255, 2.2) +
        0.7152 * Math.pow(g1/255, 2.2) +
        0.0722 * Math.pow(b1/255, 2.2);
    const l2 = 0.2126 * Math.pow(r2/255, 2.2) +
        0.7152 * Math.pow(g2/255, 2.2) +
        0.0722 * Math.pow(b2/255, 2.2);

    // Calculate the contrast ratio between the colors
    const ratio = (Math.max(l1,l2) + 0.05) / (Math.min(l1,l2) + 0.05);

    return ratio.toFixed(2);
}

</script>
