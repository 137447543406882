<template>
    <div v-if="getScoreboardOrderedByAnswerCount.length"
    class="flex flex-wrap flex-col">
        <p class="tag text-center">Questions Answered</p>

        <TransitionGroup name="list" tag="ul" class="mb-4">
            <li v-for="(player, index) in getScoreboardOrderedByAnswerCount" :key="index"
                class="border p-2.5 rounded text-sm font-bold font-sans mb-2.5 flex justify-between items-center"
                :class="player?.current ? 'bg-neutral border-secondary' : 'border-grey-200'">
                <span v-if="player?.currentPos && player?.name">
                    <span style="width:25px;"
                          class="inline-block align-bottom overflow-x-hidden rounded border py-0.5 text-xs mr-4 text-center"
                          :class="player?.current ? 'bg-secondary border-secondary text-white' : 'border-grey-200 bg-grey-200'">
                    {{player.currentPos}}
                </span> {{player.name}}</span> <span class="mr-4">{{player.answer_count > 0 ? player.answer_count : 0}}</span>
            </li>
        </TransitionGroup>

        <ParticipantCount
            :participant-count="getScoreboardOrderedByAnswerCount.length"
        />
    </div>
</template>
<script setup>
import ParticipantCount from "./ParticipantCount.vue";
import {computed} from "vue";
import {RoundData} from "../../../composibles/roundData.js";
import {ParticipantData} from "../../../composibles/participantData.js";

const {getRoundParticipants} = RoundData();
const {participant} = ParticipantData();

const getScoreboardOrderedByAnswerCount = computed(() => {
    let currentParticipantId = participant.value?.id;
    let currentParticipantIndex = null;

    let scoreBoard = getRoundParticipants.value;

    console.log(getRoundParticipants.value);

    if (scoreBoard.length === 0) {
        return [];
    }

    if (scoreBoard.length > 1) {
        scoreBoard = scoreBoard.sort((a, b) => {
            if (Object.values(a.answered_questions).length > Object.values(b.answered_questions).length)
                return -1;
            if (Object.values(a.answered_questions).length < Object.values(b.answered_questions).length)
                return 1;
            return 0;
        });
    }

    scoreBoard = scoreBoard.map((a, index) => {
        let name = a.name;

        if (currentParticipantId === a.participant_id) {
            currentParticipantIndex = index;
            name = 'You';
        }

        return {
            name: name,
            currentPos: index + 1,
            answer_count: Object.values(a.answered_questions).length,
            current: currentParticipantId === a.participant_id
        }
    });

    if (currentParticipantId !== null) {
        let playerScore = scoreBoard[currentParticipantIndex];
        scoreBoard.splice(currentParticipantIndex, 1);
        scoreBoard.unshift(playerScore);
    }

    return scoreBoard.slice(0, 4);
});
</script>
