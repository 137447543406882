import api from "../api/axios-api";
import {defineStore} from "pinia";

export function prepareRound(round, index, autoStart) {
    const startDate = round.start_date ? new Date(round.start_date) : false;
    const endDate = round.end_date ? new Date(round.end_date) : false;
    const loadedDate = round.admin_loaded_at ? new Date(round.admin_loaded_at) : false;

    if (isNaN(startDate) || isNaN(endDate)) {
        throw new Error('Invalid start or end date');
    }

    return {
        id: round.id,
        title: round.title,
        status: round.status,
        description: round.description,
        duration: round.duration_min,
        questionCount: round.question_count,
        questionIds: round.question_ids,
        powerUps: round.power_ups,
        powerUpAction: round.power_up_action,
        order: autoStart ? index + 1 : round.order,
        startDate: startDate,
        endDate: endDate,
        loadedDate: loadedDate
    };
}

const defaultState = {
    quiz: {
        id: null,
        title: '',
        automaticStart: null,
        createdBy: '',
        description: '',
        allowTeams: null,
        payToEnter: false,
        entryFee: false,
        charityId: null,
        charityName: null,
        isClosed: null,
        isFundraiser: null,
        imageUrl: '',
        isOpen: null,
        winner: false,
        roundWinner: false,
        rounds: [],
        teams: [],
        meta: {},
        publishedDate: null,
        userCanAccess: null,
        userPrefersVideoMuted: true,
        scheduledStartDate: null,
        stripeAccountId: null,
        totalRaised: null,
        primaryColour: null,
        secondaryColour: null,
        accentColour: null,
        logo: null
    },
    requiresAuthentication: false,
    chatMessages: [],
    participants: [],
}

export const useQuizStore = defineStore('quiz', {
    state: () => (defaultState),
    getters: {
        getAutomaticStart: (state) => state.quiz.automaticStart,
        getRequiresAuthentication: (state) => state.requiresAuthentication,
        getParticipants: (state) => state.participants,
        getQuiz: (state) => state.quiz,
        getQuizId: (state) => state.quiz.id,
        getTeams: (state) => state.quiz.teams,
        getRounds(state) {
            return state.quiz.rounds.sort((a, b) => a.order - b.order);
        },
        getCurrentRound(state) {
            return this.getRounds.filter((round) => round.status === 'active').sort((a, b) => (a.endDate < b.endDate ? 1 : -1))[0]
        },
        getPreviouslyClosedRound(state) {
            return this.getRounds.filter((round) => round.status === 'closed').sort((a, b) => (a.endDate < b.endDate ? 1 : -1))[0]
        },
        getScheduledRounds(state) {
            return this.getRounds.filter((round) => round.status === 'scheduled')
        },
        getNextRound(state) {
            return this.getRounds.filter((round) => round.status === 'scheduled')[0]
        },
        getQuizWinner: (state) => state.quiz.winner,
        getRoundWinner: (state) => state.quiz.roundWinner,
        getUserCanAccess: (state) => state.quiz.userCanAccess,
        getUserPrefersMutedVideo: (state) => state.quiz.userPrefersVideoMuted,
        getChatMessages: (state) => state.chatMessages,
        getStripeAccountId: (state) => state.quiz.stripeAccountId
    },
    actions: {
        resetQuizState() {
            this.quiz = defaultState;
        },
        setEndDate(end_date) {
            this.endDate = Vue.prototype.formatDate(end_date);
        },
        setStartDate(start_date) {
            this.startDate = Vue.prototype.formatDate(start_date);
        },
        setRounds(rounds) {
            this.quiz.rounds = rounds;
        },
        setRoundWinner(roundWinner) {
            this.quiz.roundWinner = roundWinner;
        },
        setQuizWinner(winner) {
            this.quiz.winner = winner;
        },
        setUserCanAccess(context) {
            this.quiz.userCanAccess = context;
        },
        setUserPrefersMutedVideo(context) {
            this.quiz.userPrefersVideoMuted = context;
        },
        setChatMessages(context) {
            this.chatMessages = context;
        },
        addNewChatMessage(newMessage) {
            const chatMessageIndex = this.chatMessages.findIndex(message => message.id === newMessage.id);
            if (chatMessageIndex < 0) {
                this.chatMessages.push(newMessage);
            }
        },
        addNewParticipantToRound(participantRound) {
            const newRound = {
                answered_questions: participantRound.round.answered_questions,
                round_id: participantRound.round.round_id,
            };

            const participantIndex = this.participants.findIndex(participant => participant.participant_id === participantRound.participant_id);

            if (participantIndex === -1) {
                this.participants.push({
                    name: participantRound.name,
                    participant_id: participantRound.participant_id,
                    rounds: [newRound]
                });
            } else {
                this.participants[participantIndex].rounds.push(newRound);
            }
        },
        deletedParticipantFromRound( payload) {
            const {participantId, roundId} = payload;

            const participantIndex = this.participants.findIndex(participant => participant.participant_id === participantId);

            if (participantIndex >= 0) {
                let roundIndex = this.participants[participantIndex].rounds.findIndex(round => round.round_id == roundId);
                if (roundIndex >= 0) {
                    this.participants[participantIndex].rounds.splice(roundIndex, 1);
                }
            }
        },
        updateParticipantStatus(payload) {
            const { participantId, status } = payload;

            const participantIndex = this.participants.findIndex(participant => participant.participant_id === participantId);
            if (participantIndex >= 0) {
               this.participants[participantIndex] = {
                    ...this.participants[participantIndex],
                    status: status
                }
            }
        },
        updateAnswerCount(payload) {
            const {participant, roundId} = payload;

            let participantIndex = this.participants.findIndex(par=> par.participant_id === participant.participant_id);

            if (participantIndex > -1) {
                let quizParticipant = this.participants[participantIndex];
                let roundIndex = quizParticipant.rounds.findIndex(round => round.round_id == roundId);

                // Push the new answer object to answered_questions
                this.participants[participantIndex].rounds[roundIndex].answered_questions = {
                    ...quizParticipant.rounds[roundIndex].answered_questions,
                    [participant.question_id]: participant.answer_id
                };
            }
        },
        insertTeams (teams) {
            this.teams = teams
        },
        addNewTeam(newTeam) {
            if (this.teams.findIndex(team => team.id === newTeam.id) === -1) {
                this.teams.push(newTeam);
            }
        },
        setQuiz (data) {
            const preparedRounds = data.rounds.map((round, index) => {
                return prepareRound(round, index, data.automatic_start);
            });

            let quizData = {
                id: data.id,
                title: data.title,
                createdBy: data.created_by,
                automaticStart: data.automatic_start,
                isPrivate: data.is_private,
                description: data.description,
                allowTeams: data.allow_teams,
                allowGuests: data.allow_guests,
                isFundraiser: data.is_fundraiser,
                charityName: data.charity_name,
                charityId: data.charity_id,
                image: data.image,
                payToEnter: data.pay_to_enter,
                publishedDate: data.created_at ? new Date(data.created_at) : null,
                scheduledStartDate: data.scheduled_start_date ? new Date(data.scheduled_start_date) : null,
                totalRaised: data.total_raised,
                entryFee: data.entry_fee,
                isOpen: null,
                isClosed: null,
                rounds: preparedRounds,
                slug: data.slug,
                meta: data.meta,
                userCanAccess: data.is_private ? data.user_can_access : true,
                stripeAccountId: data.stripe_account_id,
                primaryColour: data?.charity?.primary_colour,
                secondaryColour: data?.charity?.secondary_colour,
                accentColour: data?.charity?.accent_colour,
                logo: data?.charity?.logo?.url
            }

            this.quiz = { ...this.quiz, ...quizData};
        },
        setParticipants (data) {
            this.participants = data;
        },
        setRequiresAuthentication(data) {
            this.requiresAuthentication = data;
        },
        setRound (payload) {
            return new Promise((resolve, reject) => {
                try {
                    const { round } = payload;
                    let roundIndex = this.quiz.rounds.map(r => r.id).indexOf(round.id);
                    if (roundIndex !== -1) {
                        this.quiz.rounds[roundIndex] = round;
                        resolve();
                    } else {
                        reject(new Error('Round not found'));
                    }
                } catch (error) {
                    reject(error);
                    console.error(error);
                }
            });
        },
        loadQuiz( payload) {
            const {quiz} = payload;
            this.setQuiz(quiz);
        },
        async loadChatMessages(payload) {
            if (!payload.quizId) return;
            try {
                const response = await api.get(`/quiz/${payload.quizId}/messages`);
                if (response.data.success) {
                    this.setChatMessages(response.data.messages);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async loadParticipants(payload) {
            if (!payload.quizId) return;
            try {
                const response = await api.get(`/quiz/${payload.quizId}/participants`);
                if (response.data.participants) {
                   this.setParticipants(response.data.participants);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    }
});
