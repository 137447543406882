<template>
    <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
        <textarea :id="name"
                  :name="name"
                  v-model="inputValue"
                  rows="8"
                  class="w-full block py-5 px-4 text-lg font-medium font-sans border border-grey-300 p-3 rounded-lg"
        />
    </Label>
</template>
<script setup>
import Label from "./Label.vue";
import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: '',
    label: '',
    modelValue: '',
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
    inputValue.value = newValue;
});
</script>
