import {prepareRound, useQuizStore} from "../stores/quiz.js";
import {useParticipantStore} from "../stores/participant.js";
import {QuizData} from "./quizData.js";
import {ParticipantData} from "./participantData.js";
import {useToast} from "vue-toast-notification";
import {ref} from "vue";
import api from "../api/axios-api.js";
import {useFormData} from "./formData.js";
import {useUserStore} from "../stores/user.js";
import {useRoute} from "vue-router";
import router from "../router/router.js";

export function CharityForm() {
    const {setFormErrors, errors, errorMessage} = useFormData();

    const userStore = useUserStore();
    const route = useRoute();
    const isLoading = ref(false);


    const data = ref({
        charity_name: userStore.getUser?.account?.charity?.name,
        charity_number: userStore.getUser?.account?.charity?.charity_number,
        website_url: userStore.getUser?.account?.charity?.website,
        description: userStore.getUser?.account?.charity?.description,
        logo: userStore.getUser?.account?.charity?.logo ?? null,
        banner: userStore.getUser?.account?.charity?.banner_image ?? null,
        primary_colour: userStore.getUser?.account?.charity?.primary_colour ?? '#140B33',
        secondary_colour: userStore.getUser?.account?.charity?.secondary_colour ??'#B981F8',
        accent_colour: userStore.getUser?.account?.charity?.accent_colour ?? '#FFDF22',
        sign_in_screen_bg_colour: '',
        sign_in_screen_bg_image: '',
    });

    const handleSubmit = async (e, isUpdate) => {
        e.preventDefault();

        isLoading.value = true;

        const formData = new FormData(e.target);
        let url;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        };

        if (isUpdate) {
            url = `/charity/${userStore.getUser?.account?.charity.id}`
            formData.append('_method', 'PUT');
        } else {
            url = '/charity';
        }

        formData.append('account_id', route.params.id);

        try {
            const response = await api.post(url, formData, {
                headers: headers
            });
            if (response.data.success) {
                await router.push({name: 'account', params: {'id': userStore.getUser?.account?.id}})
            }
        } catch (error) {
            setFormErrors(error);
        } finally {
            isLoading.value = false;
        }
    };

    return {
        data,
        errors,
        errorMessage,
        handleSubmit,
        isLoading
    }
}

