<template>
    <div v-if="endDate !== false" :class="getClass">
        <p v-if="(type !== 'small' && type !== 'micro') && title !== false && title.length > 0" class="tag text-center">{{ title }}</p>

        <template v-if="type === 'micro'">
            <img alt="" :src="asset('images/clock.svg')" class="w-3.5">
        </template>

        <template v-if="circle">
            <div class="flex" :class="center ? 'justify-center' : '' + title.length > 0 ? ' mt-8' : '' ">
                <RadialProgress :diameter="getDiameter"
                                 :completed-steps="progress"
                                 :total-steps="data.totalSteps"
                                 :inner-stroke-width="3"
                                 :stroke-width="6"
                                 :is-clockwise="false"
                                 :inner-stroke-color="getSecondaryColour"
                                 :start-color="getPrimaryColour"
                                 :stop-color="getPrimaryColour"
                ><div class="countdown-outer timer flex flex-wrap relative"
                         :class="center ? 'mx-auto justify-center ' : ''">
                        <div class="countdown-inner">
                            {{formattedTime}}
                        </div>
                    </div>
                </RadialProgress>
            </div>
        </template>
        <template v-else>
            <div class="countdown-outer timer flex flex-wrap relative" :class="center ? 'mx-auto justify-center ' : ''">
                <div class="countdown-inner">
                    {{formattedTime}}
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import RadialProgress from "vue3-radial-progress";
import {computed, ref, watch} from "vue";
import {useScreenStore} from "../../../stores/screen.js";

const screenStore = useScreenStore();

const emit = defineEmits(['timerEnded']);

const props = defineProps({
    center: {
        type: Boolean,
        default: true,
    },
    title: {
        type: String,
        default: 'Time remaining'
    },
    classes: {
        type: String,
        default: ''
    },
    circle: {
        type: Boolean,
        default: true
    },
    startDate: {
        type: [Boolean, Date],
        default: false
    },
    endDate: {
        type: [Boolean, Date],
        default: false
    },
    type: {
        type: String,
        default: 'large'
    },
    format: {
        type: String,
        default: 'hh:mm:ss'
    },
    negative: {
        type: Boolean,
        default: false
    },
    primaryColour: {
        type: String,
        default: '#140B33'
    },
    secondaryColour: {
        type: String,
        default: '#B981F8'
    },
});

const data = ref({
    totalSteps: 100
});

const getClass = computed(() => {
    let classes = props.classes;

    if (props.type === 'blank') {
        return classes;
    } else if (props.type === 'micro') {
        return classes + ' countdown-micro';
    } else if (props.type === 'small') {
        return classes + ' countdown-small'
    } else {
        return classes + ' countdown';
    }
});

const getDiameter = computed(() => {
    if (props.type === 'small') {
        return 90;
    } else {
        return 180;
    }
});


const formattedTime = computed(() => {
    let formatted = props.format;
    let time = props.endDate - screenStore.getTime;

    // Handle negative time
    if (time < 0 && !props.negative) {
        return '00:00:00'; // Return zero time if negative values are not allowed
    }

    // Calculate time values
    let sec = Math.floor(time / 1000) % 60;
    let min = Math.floor(time / (1000 * 60)) % 60;
    let hour = Math.floor(time / (1000 * 60 * 60)) % 24;
    let day = Math.floor(time / (1000 * 60 * 60 * 24));

    // Format time string
    formatted = formatted.replace(/\\/g, '');
    formatted = formatted.replace(/dd/g, day > 0 ? pad(day, 2) : '');
    formatted = formatted.replace(/hh/g, hour > 0 ? pad(hour, 2) : '');
    formatted = formatted.replace(/mm/g, pad(min, 2));
    formatted = formatted.replace(/ss/g, pad(sec, 2));
    formatted = formatted.replace(/negative/g, time < 0 ? '-' : '');

    // Remove optional placeholders
    if (day <= 0) {
        formatted = formatted.replace(/d/g, '');
    }
    if (hour <= 0) {
        formatted = formatted.replace(/h/g, '');
    }
    if (min <= 0) {
        formatted = formatted.replace(/m/g, '');
    }
    if (sec <= 0) {
        formatted = formatted.replace(/s/g, '');
    }

    // Clean up trailing colons and unnecessary separators
    formatted = formatted.replace(/(^|:)(0:)+/g, '$1'); // Remove leading zeroes
    formatted = formatted.replace(/:{2,}/g, ':'); // Remove consecutive colons
    formatted = formatted.replace(/^:|:$/g, ''); // Remove leading and trailing colons

    return formatted;
});

const progress = computed(() => {
    let start = props.startDate,
        end = props.endDate,
        today = screenStore.getTime;
    if (end - today < 0) {
        return 100;
    }
    let q = Math.abs(today - start);
    let d = Math.abs(end - start);
    return (q / d) * 100;
});

const getPrimaryColour = computed(() => {
    return props.primaryColour;
});

const getSecondaryColour = computed(() => {
    const defaultBgColour = '#E8E7EB';
    return getContrast(props.secondaryColour, '#E8E7EB') < 1 ? defaultBgColour : props.secondaryColour;
});

const getContrast = (hexcolor1, hexcolor2) => {
    const r1 = parseInt(hexcolor1.substring(1,3),16);
    const g1 = parseInt(hexcolor1.substring(3,5),16);
    const b1 = parseInt(hexcolor1.substring(5,7),16);
    const r2 = parseInt(hexcolor2.substring(1,3),16);
    const g2 = parseInt(hexcolor2.substring(3,5),16);
    const b2 = parseInt(hexcolor2.substring(5,7),16);

    // Calculate the relative luminance of the colors
    const l1 = 0.2126 * Math.pow(r1/255, 2.2) +
        0.7152 * Math.pow(g1/255, 2.2) +
        0.0722 * Math.pow(b1/255, 2.2);
    const l2 = 0.2126 * Math.pow(r2/255, 2.2) +
        0.7152 * Math.pow(g2/255, 2.2) +
        0.0722 * Math.pow(b2/255, 2.2);

    // Calculate the contrast ratio between the colors
    const ratio = (Math.max(l1,l2) + 0.05) / (Math.min(l1,l2) + 0.05);

    return ratio.toFixed(2);
}

const pad = (num, size) => {
    let s = num + '';
    while (s.length < size) {
        s = '0' + s;
    }
    return s;
}

watch(() => screenStore.getTime, async (newTime, oldTime) => {
    if (props.endDate !== false && props.endDate <= newTime) {
        emit('timerEnded');
        // this.visibility = 'hidden';
    }
}, {
    immediate: true
});

</script>
