<template>
    <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
        <input :type="type"
               :id="name"
               :name="name"
               v-model="inputValue"
               :placeholder="placeholder"
               :readonly="readonly"
               :class="[{'border-red-200': hasErrors}, inputClasses]"
        >
        <slot></slot>
    </Label>
</template>
<script setup>

import Label from "./Label.vue";
import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: '',
    label: '',
    placeholder: '',
    modelValue: String,
    value: '',
    type: {
        type: String,
        default: 'text'
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    inputClasses: {
        type: String,
        default: 'w-full block py-4 px-3.5 text-md font-medium font-sans border border-grey-300 rounded-lg'
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
   return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
    inputValue.value = newValue;
});
</script>
