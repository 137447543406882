import {ref} from "vue";

export function useFormData() {
    const errorMessage = ref("");
    const errors = ref({});

    const setFormErrors = (error) => {
        if (error.response?.data?.message) {
            errorMessage.value = error.response.data.message;
        } else {
            errorMessage.value = "An error occurred. Please try again.";
        }

        if (error.response?.data?.errors) {
            errors.value = error.response.data.errors;
        }
    }

    return {
        setFormErrors,
        errors,
        errorMessage
    }
}
