<template>
    <div class="min-h-full flex flex-col bg-cover">
        <Header class="!mb-0" data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="100"/>
        <main id="main" ref="main" class="mt-12">
            <div class="container mb-4">
                <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
                    <FadeLoader
                        :loading="isScreenLoading"
                        color="#E8E7EB"
                    />
                </div>
                <div v-else-if="charity">
                    <div class="panel quiz-description break-words mb-4">
                        <div class="flex">
                            <div class="w-2/12">
                                <img v-if="charity.logo !== null" :alt="charity.name"
                                     :src="charity.logo?.url" class="h-[150px] w-[150px] object-contain mb-4 rounded-md border-2 border-grey-200"
                                >
                                <p v-if="charity.website"><a target="_blank" class="inline-block text-primary text-center" :href="charity.website">Visit Website</a></p>
                            </div>
                            <div class="w-10/12">
                                <h1 class="text-xl mb-4">{{charity.name}}</h1>
                                <div v-if="charity.description" v-html="charity.description"></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w-9/12">
                            <div v-if="charity.quizzes" class="py-4">
                                <h2 class="text-lg mb-4 font-bold">Fundraiser Quizzes</h2>
                                <div class="flex flex-wrap w-full -mx-2">
                                    <div class="px-2 w-full lg:w-6/12" v-for="quiz in charity.quizzes" :key="quiz.id">
                                        <QuizCard :quiz="quiz"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-3/12">
                            <div v-if="charity.donors" class="py-4">
                                <div class="panel w-full bg-primary text-white mb-8">
                                    <h2 class="text-lg mb-4 font-bold">Top Donors</h2>
                                    <div v-for="donor in charity.donors" class="flex justify-between pb-1 mb-1">
                                        <span>{{donor.name}}</span>
                                        <span>£{{donor.total_donation}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer/>
    </div>
</template>

<script setup>
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import api from "../api/axios-api.js";
import {ScreenData} from "../composibles/screenData.js";
import StatusTag from "../components/Quiz/Global/StatusTag.vue";
import QuizCard from "../components/Quiz/QuizCard.vue";

const route = useRoute();
const {isDesktop, setIsLoading, isScreenLoading} = ScreenData();
const charity = ref(null);

const fetchCharity = async (id) => {
    try {
        setIsLoading(true);
        const response = await api.get(`/charity/${id}`);
        if (response.data?.charity) {
            charity.value = response.data.charity;
        }
    } catch (err) {

    } finally {
        setIsLoading(false);
    }
}

onMounted(async () => {
    if (route.params.slug) {
        await fetchCharity(route.params.slug);
    }
});

</script>

