<template>
    <div v-if="results.length > 0" class="max-h-[568px] overflow-y-auto -mx-8 border-t border-grey-200">
        <div v-for="(result, index) in results" class="py-5 px-8 border-b border-grey-200" ref="results">
            <div class="flex justify-between items-center">
                <div class="w-8/12">
                    <div class="flex gap-1 mb-2">
                        <span class="tag bg-primary text-white rounded px-1.5 py-1 items-center text-tiny text-center mb-0">{{result.category}}</span>
                        <span class="tag  rounded px-1.5 py-1 items-center text-tiny text-center mb-0"
                            :class="getDifficultyTagColour(result.difficulty)"
                        >{{result.difficulty}}</span>
                    </div>
                    {{result.question}}
                    <button class="block mb-2 mt-4 text-sm" @click="showAnswers(index)">
                        <template v-if="!isShowingAnswer(index)">
                            <i class="fas fa-plus  text-secondary mr-0.5"></i> Show answers
                        </template>
                        <template v-else>
                            <i class="fas fa-minus  text-secondary mr-0.5"></i>
                            Hide answers
                        </template>
                    </button>
                </div>
                <button class="btn btn-primary" @click="addOrRemoveAnswerFromRound(result.question, index)">
                    <template v-if="isQuestionAddedToRound(result.question)">
                        <i class="fas fa-minus mr-1"></i>
                        Remove
                    </template>
                    <template v-else>
                        <i class="fas fa-plus mr-1"></i>
                        Add
                    </template>
                </button>
            </div>
            <Transition name="collapse">
                <ul class="answers w-8/12 mt-2" v-show="isShowingAnswer(index)">
                    <li class="flex gap-2 border border-grey-200 rounded-md px-3 py-2 mb-1 items-center"><i class="fas fa-check-circle mr-1 text-green-200"></i>{{result.correctAnswer.answer}}</li>
                    <li class="flex gap-2 border border-grey-200 rounded-md px-3 py-2 mb-1 items-center" v-for="answer in result.incorrectAnswers">
                        <i class="fas fa-times-circle mr-1 text-red-200"></i>{{formatAnswer(answer.answer)}}
                    </li>
                </ul>
            </Transition>
        </div>
    </div>
    <p v-else>No questions found.</p>
</template>

<script setup>
import api from "../../api/axios-api";
import {useRoute} from "vue-router";
import {ref} from "vue";
import {useEditRoundQuestions} from "../../stores/quiz-edit-round-questions.js";

const route = useRoute();

const quizEditRound = useEditRoundQuestions();

const props = defineProps({
    results: {
        type: Array,
        default: []
    }
})

const data = ref({
    showingAnswerIndex: null,
});

const showAnswers = (index) => {
    data.value.showingAnswerIndex = index === data.value.showingAnswerIndex ? null : index;
}

const isShowingAnswer =  (index) => {
    return data.value.showingAnswerIndex === index;
}
const formatAnswer = (answer) => {
    return answer;
}

const getDifficultyTagColour = (difficulty) => {
    if (difficulty === 'easy') {
        return 'bg-green-100 text-green-200';
    } else if (difficulty === 'medium') {
        return "bg-orange-100 text-orange-200";
    } else if (difficulty === 'hard') {
        return "bg-red-100 text-red-200"
    }
}

const addOrRemoveAnswerFromRound =  async (question, index) => {
    try {
        if (isQuestionAddedToRound(question)) {
            const addedQuestion = isQuestionAddedToRound(question);
            await quizEditRound.deleteQuestion(route.params.quizId, route.params.roundId, addedQuestion.id);
        } else {
            const question = props.results[index];
            const answers = [...question.incorrectAnswers];
            answers.unshift(question.correctAnswer);

            let response = await api.post('/quiz/' + route.params.quizId + '/round/' + route.params.roundId + '/question', {
                question: question.question,
                fact: '',
                upload_id: null,
                answers: answers,
                correct_answer_index: 0
            })

            if (response?.data?.question) {
                quizEditRound.addQuestion(response.data.question);
            }
        }
    } catch (error) {
        console.error(error);
    }
}

const isQuestionAddedToRound = (question) =>  {
    return quizEditRound.getQuestions.find((q) => {
        return q.question === question;
    })
}

</script>

<style scoped>

</style>
