import { useQuizStore} from "../stores/quiz";
import {computed} from "vue";
import {QuizThemeData} from "./quizThemeData.js";
import {useUserStore} from "../stores/user.js";


export function QuizData(){
    const quizStore = useQuizStore();
    const userStore = useUserStore();

    const {setBrandedStyles} = QuizThemeData();

    const quiz = computed(() => {
       return quizStore.quiz;
    });

    const currentRound = computed(() => {
        return quizStore.getCurrentRound;
    })

    const nextRound = computed(() => {
        return quizStore.getNextRound;
    })

    const previousRound = computed(() => {
        return quizStore.getPreviouslyClosedRound;
    })

    const userCanAccess = computed(() => {
        return quizStore.getUserCanAccess
    })

    const allowGuests = computed(() => {
        return quiz.value.allowGuests;
    });

    const canUserAccessQuiz = computed(() => {
        if (!quiz.value.isPrivate) {
            return true;
        }
        return userCanAccess;
    });

    const getQuizThemeColours = computed(() => {
        return {
            primary: quiz.value.primaryColour ? quiz.value.primaryColour : '#140B33',
            secondary: quiz.value.secondaryColour ? quiz.value.secondaryColour : '#B981F8',
            accent: quiz.value.accentColour ? quiz.value.accentColour : '#FFDF22'
        }
    })

    const isQuizOpen = computed(() => {
        return Boolean(previousRound.value || currentRound.value || (nextRound.value && previousRound.value));
    });

    const isQuizClosed = computed(() => {
        return !currentRound.value && !nextRound.value;
    });

    const isQuizPrivate = computed(() => {
        return !!quiz.value.isPrivate;
    });

    const requiresPayment = () => {
        return (quiz.value.payToEnter || quiz.value.isFundraiser) && !userStore.getUser?.paid_quizzes.includes(quiz.value.id);
    };

    const setQuizData = (quiz) => {
        quizStore.loadQuiz({
            quiz: quiz
        });

        setBrandedStyles( {
            primary: quiz?.charity?.primary_colour,
            secondary: quiz?.charity?.secondary_colour,
            accent: quiz?.charity?.accent_colour
        });
    }

    const resetQuizStore = () => {
        quizStore.$reset();
    }

    return {
        setQuizData,
        quiz,
        getQuizThemeColours,
        requiresPayment,
        resetQuizStore,
        currentRound,
        nextRound,
        previousRound,
        allowGuests,
        canUserAccessQuiz,
        isQuizOpen,
        isQuizClosed,
        isQuizPrivate
    }
}

