import {prepareRound, useQuizStore} from "../stores/quiz.js";
import {useParticipantStore} from "../stores/participant.js";
import {QuizData} from "./quizData.js";
import {ParticipantData} from "./participantData.js";
import {useToast} from "vue-toast-notification";

export function EventData() {

    const quizStore = useQuizStore();
    const participantStore = useParticipantStore();
    const $toast = useToast();

    const {quiz, nextRound} = QuizData();
    const {participant, isParticipant} = ParticipantData();

    const subscribeToQuizChannelEvents = async () => {
        Echo.channel(`quiz.${quiz.value.id}`)
            .listen('.QuizRoundParticipantAnswerEvent', (data) => {
                if (data.participant) {

                    console.log(data);

                    quizStore.updateAnswerCount({
                        participant: data.participant,
                        roundId: data.roundId
                    })
                }
            })
            .listen('.QuizRoundNewParticipantEvent', (payload) => {
                const {participant, roundId} = payload;

                if (participant) {
                    quizStore.addNewParticipantToRound(participant)
                }
            })
            .listen('.QuizRoundDeletedParticipantEvent', (payload) => {
                const {participantId, roundId} = payload;

                if (participantId) {
                    quizStore.deletedParticipantFromRound({
                        participantId: participantId,
                        roundId: roundId
                    });
                }
            })
            .listen('.QuizRoundAdminPowerUpEvent', (data) => {
                // showPowerUpModal(data.data);
            })
            .listen('.QuizRoundHeartBeatEvent', async (data) => {
                if (data.participant) {
                    // updateParticipantRoundLoadedStatus(data);
                }
            })
            .listen('.QuizAdminEvent', async (data) => {
                try {
                    // Scroll to the top of the body
                    window.scrollTo(0, 0);

                    const round = prepareRound(data.round);

                    if (data.action === 'loadRound') {
                        if (!nextRound?.value.id) {

                        } else if (data.round.id === nextRound.value.id) {
                            // Set previous active round as closed
                            let previousActiveRound = quiz.value.rounds.find((r) => r.status === 'active' && r.id !== round.id);

                            if (previousActiveRound) {
                                previousActiveRound.status = 'closed';

                                await quizStore.setRound({
                                    round: previousActiveRound
                                });
                            } else {
                                console.error('Unable to close previous round.');
                            }

                            if (isParticipant.value && data.data.participant_ids.includes(participant.value.id)) {
                                console.log('Setting participant round', data);

                                await new Promise((resolve) => {
                                    participantStore.setParticipantRound({
                                        round_id: nextRound.value.id,
                                        participant_id: participant.value.id,
                                        start_time: data.round.start_time,
                                    });
                                    resolve();
                                });
                            } else {
                                console.error('An error occurred whilst inserting participant into round', data.participant_id)
                            }
                        } else {
                            console.error(`Round id: ${data.round.id} sent from admin does not match next round id: ${nextRound.id}`);
                        }
                    }

                    console.log(round);

                    // Set new round state
                    await quizStore.setRound({
                        round: round,
                    });

                    // Refresh participants
                    if (data.action === 'loadRound' || data.action === 'startRound') {
                        await quizStore.loadParticipants({
                            quizId: quiz.value.id
                        });
                    }
                } catch (err) {
                    $toast.open({
                        message: err.message,
                        type: 'error',
                        duration: 100000,
                        dismissible: false
                    });
                    console.error(err);
                }
            })
            .listen('.QuizChatEvent', async (data) => {
                if (data?.message.length > 0) {
                    quizStore.addNewChatMessage(data);
                }
            })
            .listen('.QuizParticipantPresenceEvent', async (data) => {
                if (data.participantId) {
                    // quizStore.updateParticipantStatus(data);
                }
            })
    }

    const leaveChannels = () => {
        Echo.leaveChannel(`quiz.${quiz.value.id}.participant.${participant.id}`);

        if (!quiz.value.automaticStart) {
            Echo.leaveChannel(`quiz.${quiz.value.id}`);
        }
    }

    return {
        subscribeToQuizChannelEvents,
        leaveChannels
    }
}

