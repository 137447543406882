<template>
    <Label :label="label" :has-error="hasError" :error-message="errorMessage">
        <input type="datetime-local"
               :id="name"
               v-model="formattedInputValue"
               class="block w-full py-5 px-4 text-lg font-medium font-sans border border-grey-300 p-3 rounded-lg"
               :class="[{'border-red-200': hasErrors}]"
        >
        <input :name="name" type="hidden" v-model="inputValue"/>
    </Label>
</template>

<script setup>
import moment from "moment-timezone"; // Import moment-timezone to handle timezones correctly
import {ref, watch, computed} from "vue";
import Label from "./Label.vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: String,
    label: String,
    modelValue: String,
    timezone: {
        type: String,
        default: 'UTC' // Default timezone if not provided
    },
    hasError: {
        type: Boolean,
        default: false
    },
    errorMessage: {
        type: String,
        default: ''
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const inputValue = ref(props.modelValue);

const formattedInputValue = computed({
    get() {
        if (inputValue.value) {
            const datetime = moment(inputValue.value).tz(props.timezone);
            return datetime.format('YYYY-MM-DDTHH:mm');
        }
        return null;
    },
    set(value) {
        const datetime = moment(value).tz(props.timezone);
        inputValue.value = datetime.toISOString();
    }
});

// Watch for changes in the inputValue and emit the update
watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

// Watch for changes in the modelValue prop to update the inputValue ref
watch(() => props.modelValue, (newValue) => {
    inputValue.value = newValue;
});

</script>
