import { onMounted, ref} from "vue";
import api from "../api/axios-api.js";

export function useQuizListings(){
    const quizzes= ref([]);
    const isLoading = ref(false);
    const pagination = ref(null);

    onMounted(async () => {
        try {
            isLoading.value = true;
            const response = await api.get('/quiz');
            if (response.data.quizzes) {
                const quizzesData = response.data.quizzes;
                if (quizzesData.length === 0) return;
                quizzes.value = quizzesData;
                if (response.data.quizzes.links) {
                    pagination.value = response.data.quizzes.links;
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    });

    return {
        quizzes,
        isLoading
    }
}

