<template>
    <div class="mb-4">
        <label :class="labelClasses" :for="name">
            <span class="inline-block" :class="getLabelSpanClasses()" v-html="label"></span>
            <slot></slot>
        </label>
        <span class="block text-red-200 text-sm mt-2">{{errorMessage}}</span>
    </div>
</template>
<script setup>

const props = defineProps({
    name: String,
    label: {
        type: String,
        default: '',
    },
    value: String,
    position: {
        type: String,
        default: 'top'
    },
    labelClasses: {
        type: String,
        default: 'relative font-bold font-sans flex flex-wrap items-center'
    },
    event: String,
    hasError: Boolean,
    errorMessage: String
})

const getLabelSpanClasses = () => {
    let classes = 'peer ';
    if (props.position === 'left') {
        classes += 'order-1 ml-2';
    } else if(props.position === 'right') {
        classes += 'order-2 mr-2';
    } else {
        classes += 'mb-1 w-full'
    }
    return classes;
}

</script>
