<template>
    <AccountGuestLayout>
        <LoginForm @success="handleSignIn"/>
    </AccountGuestLayout>
</template>
<script setup>
import router from "../../router/router";
import AccountGuestLayout from "../../layouts/AccountGuestLayout.vue";
import LoginForm from "../../components/Account/LoginForm.vue";
import {useRoute} from "vue-router";
import {useUserStore} from "../../stores/user.js";

const route = useRoute();

const userStore = useUserStore();

const handleSignIn = () => {
    if (route.query.redirect) {
        router.push(route.query.redirect);
    } else {
        router.go(0);
    }
}
</script>

