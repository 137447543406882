<template>
    <div>
        <QuestionLibrarySearch
            :categories="data.categories"
            :difficulty-levels="data.difficultyLevels"
            :errors="errors"
            :error-message="errorMessage"
            @search="handleSearchSubmit"
        />
        <QuestionLibraryResults
            :results="data.results"
        />
    </div>
</template>

<script setup>
import QuestionLibraryResults from "./QuestionLibraryResults.vue";
import {onMounted, ref} from "vue";
import QuestionLibrarySearch from "./QuestionLibrarySearch.vue";
import {useFormData} from "../../composibles/formData.js";
const QUESTION_BANK_API_ENDPOINT = 'https://opentdb.com'

const {errors, setFormErrors, errorMessage} = useFormData();

const data = ref({
    isLoading: true,
    categories: [
        {
            label: 'Any',
            value: null
        }
    ],
    difficultyLevels: [
        {
            label: 'Any',
            value: null
        },
        {
            label: 'Easy',
            value: 'easy'
        },
        {
            label: 'Medium',
            value: 'medium'
        },
        {
            label: 'Hard',
            value: 'hard'
        },
    ],
    form: {
        categoryId: null,
        difficulty: null,
    },
    results: [],
    resultsLimit: 50,
    resultsPerPage: 10,
});

const getCategories = async () => {
    try {
        const response = await fetch(`${QUESTION_BANK_API_ENDPOINT}/api_category.php`);
        const responseData = await response.json();

        if (responseData?.trivia_categories) {
            let categories = responseData.trivia_categories.map((cat) => {
                return {
                    label: cat.name,
                    value: cat.id
                };
            });

            if (categories.length > 0) {
                data.value.categories = [
                    ...data.value.categories,
                    ...categories
                ];
            }
        }
    } catch (error) {
        setFormErrors(error);
    }
}

const fetchResults = async(formData) => {
    try {
        let endpoint = `${QUESTION_BANK_API_ENDPOINT}/api.php?type=multiple&encode=url3986&amount=${data.value.resultsLimit}`;

        if (formData.difficulty) {
            endpoint += `&difficulty=${formData.difficulty}`
        }

        if (formData.categoryId) {
            endpoint += `&category=${formData.categoryId}`
        }

        const response = await fetch(endpoint);
        const responseData = await response.json();

        if (responseData?.results) {
            let formattedData = responseData.results.map(obj => {
                return Object.fromEntries(
                    Object.entries(obj).map(([key, value]) => {
                        return [key, decodeURIComponent(value)];
                    })
                );
            });

            return formattedData.map((question) => {
                const answers = Array.isArray(question.incorrect_answers)
                    ? question.incorrect_answers.map((answer, index) => ({
                        id: null,
                        answer: answer.trim(),
                    }))
                    : question.incorrect_answers.split(/\s*,\s*/).map((answer, index) => ({
                        id: null,
                        answer: answer.trim(),
                    }));

                return {
                    category: question.category,
                    correctAnswer:  {
                        id: null,
                        answer: question.correct_answer,
                    },
                    difficulty: question.difficulty,
                    incorrectAnswers: answers,
                    question: question.question,
                };
            });
        }
    } catch (error) {
        throw error;
    }
}

const handleSearchSubmit = async(form) => {
    try {
        data.value.isLoading = true;
        data.value.results = await fetchResults(form);
    } catch (error) {
        setFormErrors(error);
    } finally {
        data.value.isLoading = false;
    }
}

onMounted(async () => {
    await getCategories();
});
</script>

<style scoped>

</style>
