import moment from "moment";

export function useHelpers() {
    const pluralize = (count, noun, suffix = 's') => {
        return `${count} ${noun}${count !== 1 ? suffix : ''}`
    }

    const formatDate = (date, format = '') => {
        if (typeof date === 'undefined') return;
        date = new Date(date.replace(/-/g, "/"));
        date = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            )
        );

        if (format.length > 0) {

        }

        return date;
    }

    const getNameInitial = (name) => {
        let nameInitials = '';

        if (!name) {
            return 'N/A';
        }

        const nameParts = name.split(" ");

        for (let i = 0; i < Math.min(nameParts.length, 2); i++) {
            const part = nameParts[i];
            if (part) {
                nameInitials += part[0].toUpperCase();
            }
        }

        return nameInitials;
    }

    const formattedDateAndTime = (dateTimeString, format = 'DD/MM/YY [at] h:mm A') => {
        return moment(dateTimeString).format(format);
    }

    return {
        pluralize,
        formatDate,
        getNameInitial,
        formattedDateAndTime
    }
}

