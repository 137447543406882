<template>
    <div>
        <div class="sm:mb-5 panel panel-xs border border-2 border-secondary" :class="data.isVisible ? 'visible' : 'hidden'">
            <div class="relative pb-[75%]">
                <div class="tag absolute top-2 sm:top-4 left-2 sm:left-4 z-10 rounded px-1.5 py-1 bg-red-200 flex items-center text-white text-tiny" v-if="data.isVisible">
                <span class="relative flex h-2 w-2 sm:mr-1">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-2 w-2 bg-white"></span>
                </span>
                    <span class="hidden sm:visible">Live</span>
                </div>
                <video class="absolute w-full h-full inset bg-primary rounded-md" ref="viewerRef" playsinline controls muted></video>
            </div>
            <p v-if="data.error">
                {{data.error}}
            </p>
            <div class="absolute bottom-[14px] right-[10px] sm:relative sm:right-0 sm:bottom-0 flex items-center text-sm gap-3 justify-end" v-if="data.isVisible">
                <button @click="toggleMute" class="flex items-center sm:pt-3 px-2 text-sm sm:text-lg h-[30px] sm:h-auto rounded-full sm:text-primary sm:rounded-none sm:bg-transparent" :title="prefersMuted ? 'Unmute microphone' : 'Mute microphone'">
                    <span v-if="!quizStore.getUserPrefersMutedVideo"><i class="fas fa-volume-up"></i></span>
                    <span v-else><i class="fas fa-volume-mute"></i></span>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import Peer from "simple-peer";
import api from "../../../api/axios-api";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useQuizStore} from "../../../stores/quiz.js";
import * as stream from "node:stream";

const quizStore = useQuizStore();

const props = defineProps(['quizId']);

const data = ref({
    clientId: null,
    streamingPresenceChannel: null,
    destroyPeerTimeOut: null,
    isLoading: false,
    broadcasterPeer: null,
    broadcasterId: null,
    isVisible: false,
    error: false,
});

const viewerRef = ref(null);


const joinBroadcast = () => {
    initializeStreamingChannel();
    initializeSignalOfferChannel(); // a private channel where the viewer listens to incoming signalling offer
}

const initializeStreamingChannel = () => {
    if (data.value.streamingPresenceChannel === null) {
        data.value.streamingPresenceChannel = Echo.join(
            `streaming-channel.${props.quizId}`
        ).leaving(() => {
            console.log('leaving');
        }).subscribed((user) => {

        });
    }
    data.value.clientId = Echo.socketId();
}

const createViewerPeer = (incomingOffer, broadcaster) => {
    console.log('creating viewer peer for', broadcaster);

    const peer = new Peer({
        initiator: false,
        trickle: false,
        debug: true
    });

    // Add Transceivers
    peer.addTransceiver("video", { direction: "recvonly" });
    peer.addTransceiver("audio", { direction: "recvonly" });

    // Initialize Peer events for connection to remote peer
    handlePeerEvents(
        peer,
        incomingOffer,
        broadcaster,
        removeBroadcastVideo
    );

    data.value.broadcasterPeer = peer;

    console.log('broadcasting peer created viewer peer for',  data.value.broadcasterPeer);
}

const handlePeerEvents = (peer, incomingOffer, broadcaster, cleanupCallback) => {
    peer.on("signal", async (stream) => {
        try {
            data.value.isLoading = true;
            await api.post(`/quiz/${props.quizId}/stream-answer`, {
                broadcaster,
                client_id: data.value.clientId,
                answer: stream,
            });
        } catch (err) {
            data.value.error = err.message;
        } finally {
            data.value.isLoading = false;
        }
    });
    peer.on("stream", (stream) => {
        if (!viewerRef.value) {
            return;
        }

        viewerRef.value.srcObject = stream;
        // Wait for video metadata to load before playing
        viewerRef.value.addEventListener("loadedmetadata", () => {
            if (viewerRef.value.readyState >= 2) {
                viewerRef.value.play();
                if (!quizStore.getUserPrefersMutedVideo) {
                    viewerRef.value.muted = false;
                }
            } else {
                // Handle metadata loading error
                console.log("Error loading video metadata");
            }
        });

        // display remote stream
        data.value.isVisible = true;
    });
    peer.on("close", () => {
        console.log('Closing peer connection');
        data.value.destroyPeerTimeOut = setTimeout(() => {
            peer.destroy();
            cleanupCallback(false);
        }, 1000);
    });
    peer.on("error", (err) => {
        console.error(err);
    });
    const updatedOffer = {
        ...incomingOffer,
        sdp: `${incomingOffer.sdp}\n`,
    };
    peer.signal(updatedOffer);
}

const initializeSignalOfferChannel = () => {
    Echo.channel(`stream-signal-channel.${props.quizId}`).listen(
        "StreamOffer",
        ({ data: streamData }) => {

            if (data.value.clientId === streamData.receiver && data.value.broadcasterId === null) {
                data.value.isLoading = true;
                data.value.broadcasterId = streamData.broadcaster;
                createViewerPeer(streamData.offer, streamData.broadcaster);
            }
        }
    ).subscribed((v) => {
        console.log('viewer subscribed to participant channel');
    })
    .error((er) => {
        console.error(er);
    })
}

const removeBroadcastVideo = (leaveBroadCastChannel = true) => {
    if (data.value.broadcasterPeer) {
        data.value.broadcasterPeer.destroy(); // Disconnect the Peer connection
        data.value.broadcasterPeer = null;
    }

    if (data.value.broadcasterId !== null) {
        data.value.broadcasterId = null;
    }

    if (viewerRef.value !== null && viewerRef.value.srcObject !== null) {
        const tracks = viewerRef.value.srcObject.getTracks();
        tracks.forEach((track) => {
            track.stop();
        });
        viewerRef.value.srcObject = null;
    }

    data.value.isVisible = false;

    if (leaveBroadCastChannel) {
        console.log('leaving channel');
        Echo.leave(`stream-signal-channel.${props.quizId}`);
        Echo.leave(`streaming-channel.${props.quizId}`);
        data.value.streamingPresenceChannel = null;
    }
}

const toggleMute = () => {
    const videoElement =  viewerRef;

    if (videoElement) {
        videoElement.muted = !quizStore.getUserPrefersMutedVideo;
        quizStore.setUserPrefersMutedVideo(videoElement.muted);
    }
}


onMounted(() => {
    joinBroadcast();
});

onBeforeUnmount(() => {
    removeBroadcastVideo();
    if (data.value.destroyPeerTimeOut) {
        clearTimeout(data.value.destroyPeerTimeOut);
    }
})

</script>
