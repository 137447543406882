<template>
    <div v-if="participant.id !== null" class="panel panel-sm py-2 flex items-center justify-between mb-4">
        <p class="mb-0 leading-tight">
            <span class="text-xs">Playing as:<br></span>
            <span class="text-md font-bold">{{participant.name}}</span>
        </p>
        <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.76 20.1">
            <g>
                <path data-bg-colour="white" class="text-tertiary fill-current" d="m11.54,2.29c-.79-.85-1.89-1.32-3.11-1.32s-2.33.47-3.11,1.31c-.79.86-1.18,2.02-1.09,3.27.18,2.48,2.07,4.49,4.2,4.49s4.02-2.01,4.2-4.49c.09-1.24-.3-2.4-1.1-3.27Z"/>
                <path data-bg-colour="white" class="text-tertiary fill-current" d="m15.57,19.13H1.3c-.38,0-.74-.16-.98-.45-.26-.31-.37-.75-.29-1.18.34-1.9,1.41-3.49,3.09-4.61,1.49-.99,3.38-1.54,5.32-1.54s3.83.55,5.32,1.54c1.68,1.12,2.75,2.71,3.09,4.61.08.43-.03.86-.29,1.18-.24.29-.6.46-.98.45Z"/>
            </g>
            <g>
                <path data-bg-colour="white" class="text-secondary fill-current" d="m22.87,1.46c-.87-.94-2.09-1.46-3.44-1.46s-2.58.52-3.45,1.45c-.88.95-1.31,2.23-1.21,3.62.2,2.74,2.29,4.97,4.65,4.97s4.45-2.23,4.65-4.97c.1-1.38-.33-2.66-1.21-3.62Z"/>
                <path data-bg-colour="white" class="text-secondary fill-current" d="m27.33,20.1h-15.79c-.42,0-.82-.18-1.09-.5-.29-.35-.41-.82-.32-1.31.38-2.1,1.56-3.87,3.42-5.1,1.65-1.1,3.74-1.71,5.89-1.71s4.24.61,5.89,1.71c1.86,1.24,3.04,3,3.42,5.1.09.48-.03.96-.32,1.31-.27.32-.67.51-1.09.5Z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        participant: Object
    },
    name: "QuizParticipantInfo"
}
</script>
