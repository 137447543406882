<template>
    <FadeLoader v-if="isLoading"
        :loading="isLoading"
        color="#E8E7EB"
    ></FadeLoader>
    <Form v-else @submit.prevent="handleDonationSubmit" :error-message="errorMessage">
        <Number
            label="Donation Amount"
            :min="1.00"
            :step="0.01"
            v-model="donationData.donationAmount"
            placeholder="0.00"
            required
            :errors="errors"
        />
        <label class="relative text-sm mb-2 font-sans flex">
            <input type="checkbox" class="mr-2 border border-grey-300 p-2 rounded-md" v-model="donationData.donationIsGiftAid">
            <span class="block">Donate with GiftAid</span>
        </label>
        <p class="text-xs">If you have paid UK income tax of at least the amount donated, we can claim back 25p of tax for every £1 that you give.
            Ticking the "Yes" box, confirms my agreement that I would like the XXXX to reclaim the tax on qualifying donations made by me today and on any future donations, until I notify them to the contrary. I am aware that, within any one tax year, should I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations to all charities in that tax year, I may be asked to pay the difference. I understand that XXX will reclaim 25p of tax on every £1 that I give to XXX.</p>
        <LoadingButton type="submit" class="mt-8 btn-primary btn--large">Go to payment</LoadingButton>
    </Form>
</template>

<script setup>
import Form from "../../Form/Form.vue";
import LoadingButton from "../../Global/LoadingButton.vue";
import Number from "../../Form/Number.vue";
import {onMounted} from "vue";
import api from "../../../api/axios-api.js";
import router from "../../../router/router.js";
import {QuizDonationData} from "../../../composibles/donationData.js";
import {useFormData} from "../../../composibles/formData.js";

const {getDonation, donationData, isLoading} = QuizDonationData();
const {errors, setFormErrors, errorMessage} = useFormData();

const props = defineProps({
   quizId: {
       type: [Number, null],
       default: null
   }
});

const handleDonationSubmit = async () => {
    try {
        const response = await api.post('/donate', {
            quizId: props.quizId,
            amount: donationData.value.donationAmount,
            giftAid: donationData.value.donationIsGiftAid
        });

        if (response.data.payment.amount) {
            const currentRoute = router.currentRoute.value;
            const query = {...currentRoute.query, donation: donationData.value.donationAmount, giftAid: donationData.value.donationIsGiftAid};
            await router.push({name: 'quiz-payment-screen', params: {quizId: props.quizId}});
        }
    } catch (err) {
        setFormErrors(err);
    } finally {

    }
};

onMounted(async () => {
    await getDonation();
});

</script>
