<template>
    <div>
        <div class="panel">
            <h1 class="text-xl">
                <template v-if="editing">Edit Question</template>
                <template v-else>Add Question</template>
            </h1>

            <Form @submit.prevent="handleSubmit" :errors="errors" :error-message="errorMessage">
                <TextInput
                    label="Question"
                    name="question"
                    v-model="question.question"
                    :value="question.question"
                    :errors="errors"
                />

                <FileUploader
                    label="Upload Image"
                    name="image"
                    v-model="question.image"
                    :file="question.imageFile"
                    :errors="errors"
                />

                <div class="mt-6 mb-6">
                    <h4 class="mt-4 font-bold">Answers</h4>
                    <div v-for="(answer, answer_index) in question.answers" :key="answer.id" class="flex items-center">
                        <Radio
                            :checked="question.correct_answer_index === answer_index"
                            name="correct_answer_index"
                            @change="updateCorrectAnswerIndex(answer_index)"
                            :value="answer_index"
                            :errors="errors"
                        />

                        <TextInput
                            :name="'answers[' + answer_index + '][answer]'"
                            v-model="answer.label"
                            :value="answer.label"
                            :errors="errors"
                        />

                        <input type="hidden"
                               :name="'answers[' + answer_index + '][id]'"
                               v-model="answer.id"
                               :value="answer.id"
                        />

                        <button type="button" v-if="question.answers.length > 2"
                                @click="removeAnswer(answer_index)"
                                class="ml-2 bg-red-200 text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                            <i class="far fa-trash-alt" aria-label="Delete image"></i>
                        </button>
                    </div>

                    <button @click="addAnswer" type="button" class="leading-tight text-sm">
                        <i class="fas fa-plus"></i> Add Answer
                    </button>
                </div>

                <TextArea
                    label="Question Fact"
                    v-model="question.fact"
                    :value="question.fact"
                    name="fact"
                    :errors="errors"
                />

                <button type="button"
                        class="btn btn-primary"
                        @click="redirectBackToQuestions">
                    Go back
                </button>

                <button type="submit" class="btn btn-tertiary">
                    <template v-if="editing">
                        Update
                    </template>
                    <template v-else>
                        Save
                    </template>
                </button>
            </Form>
        </div>
    </div>
</template>
<script setup>
import Radio from "../../Form/Radio.vue";
import TextInput from "../../Form/TextInput.vue";
import api from "../../../api/axios-api";
import FileUploader from "../../Form/FileUploader.vue";
import TextArea from "../../Form/TextArea.vue";
import Form from "../../Form/Form.vue";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useFormData} from "../../../composibles/formData.js";

const props = defineProps({
    editing: {
        type: Boolean,
        default: false
    },
});

const route = useRoute();
const router = useRouter();

const {errors, errorMessage, setFormErrors} = useFormData();

const question = ref({
    question: '',
    fact: '',
    image: '',
    imageFile: {
        id: null,
        url: ''
    },
    answers: [
        {
            id: null,
            label: '',
        },
        {
            id: null,
            label: '',
        },
    ],
    correct_answer_index: 0
})


const addAnswer = () => {
    question.value.answers.push({
        id: null,
        answer: '',
    });
}

const updateCorrectAnswerIndex = (index) => {
    question.value.correct_answer_index = index;
}

const loadQuestion = async () => {
    try {
        const questionResponse = await api.get('/quiz/' + route.params.quizId + '/round/' + route.params.roundId + '/question/' + route.params.questionId);

        if (questionResponse.data.question) {
            let correctAnswerIndex = 0;
            const answers = questionResponse.data.answers.map((a, index) => {
                if (a.is_correct) {
                    correctAnswerIndex = index;
                }
                return {
                    id: a.id,
                    label: a.answer
                }
            });

            question.value = {
                question: questionResponse.data.question,
                answers: answers,
                fact: questionResponse.data.fact,
                correct_answer_index: correctAnswerIndex,
                imageFile: questionResponse.data.upload
            }
        }
    } catch (error) {
        setFormErrors(error);
    }
}

const removeAnswer = (answer_index) => {
    if (confirm('Are you sure you want to delete this answer?')) {
        question.value.answers.splice(answer_index, 1);
    }
}

const handleSubmit = async(e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    let questionResponse, url;

    let headers = {
        'Accept': 'application/json',
        'Content-type': 'multipart/form-data',
    };

    if (props.editing && route.params.quizId) {
        url = `/quiz/${route.params.quizId}/round/${route.params.roundId}/question/${route.params.questionId}`
        formData.append('_method', 'PUT');
    } else {
        url = `/quiz/${route.params.quizId}/round/${route.params.roundId}/question`;
    }

    try {
        questionResponse = await api.post(url, formData, {
            headers: headers
        });

        if (questionResponse.data.success) {
            const quizId = route.params.quizId;
            const roundId = route.params.roundId;

            await router.push({
                name: 'account/my-quizzes/view-questions',
                params: {
                    quizId: quizId,
                    roundId: roundId
                }
            });
        }
    } catch (error) {
        setFormErrors(error);
    }
}

const redirectBackToQuestions = () =>  {
    if (confirm("Are you sure you want to go back? Any unsaved progress will be lost")) {
        const quizId = route.params.quizId;
        const roundId = route.params.roundId;

        router.push({
            name: 'account/my-quizzes/view-questions',
            params: {
                quizId: quizId,
                roundId: roundId
            }
        });
    }
}

onMounted(async () => {
    if (props.editing) {
        await loadQuestion();
    }
});


</script>
