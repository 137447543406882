<template>
    <div class="panel">
        <h1 class="text-xl mb-4">Create a quiz</h1>
        <Form @submit="handleSubmit" classes="w-full">
            <div class="border-b border-grey-200 mb-4">
                <Checkbox
                    label="Create Fundraising Quiz"
                    name="is_fundraiser"
                    v-model="quiz.is_fundraiser"
                />
                <p class="text-sm text-grey-400 -mt-4 mb-4">
                    Enable this option to create a quiz that helps raise funds for a charity. Your quiz will contribute to fundraising efforts and support charitable causes.
                </p>

                <div v-if="quiz.is_fundraiser">
                    <CustomSelect
                            label="Select charity"
                            :options="getApplicableCharities"
                            defaultItemLabel="Select a charity"
                            v-model="quiz.charity_id"
                            name="charity_id"
                    />
                </div>
            </div>

            <TextInput
                label="Title"
                name="title"
                placeholder="Enter your title"
                v-model="quiz.title"
                :errors="errors"
            />

            <RichTextArea
                label="Description"
                name="description"
                v-model="quiz.description"
            />

            <FileUploader
                name="image"
                label="Image"
                v-model="quiz.image"
                :file="quiz.image"
            />

            <TimeZoneSelect
                name="timezone"
                label="Timezone:"
                :timezones="timezones"
                v-model="quiz.timezone"
            />

            <h4 class="block text-lg text-secondary mt-8 mb-6">Settings</h4>

            <div class="flex align-top sm:gap-4">
                <div class="w-4/12">
                    <Checkbox
                        label="Allow Guests"
                        name="allow_guests"
                        v-model="quiz.allow_guests"
                        :disabled="quiz.is_fundraiser || quiz.pay_to_enter"
                        label-classes="border border-grey-300 p-4 rounded-lg inline-flex items-center w-full"
                    />

                    <Checkbox
                        label="Tournament"
                        name="tournament"
                        v-model="quiz.tournament"
                        label-classes="border border-grey-300 p-4 rounded-lg inline-flex items-center w-full"
                    />
                </div>
                <div class="w-4/12">
                    <Checkbox
                        label="Randomise Questions"
                        name="randomise_questions"
                        v-model="quiz.randomise_questions"
                        label-classes="border border-grey-300 p-4 rounded-lg inline-flex items-center w-full"
                    />
                    <Checkbox
                        label="Randomise Answers"
                        name="randomise_answers"
                        v-model="quiz.randomise_answers"
                        label-classes="border border-grey-300 p-4 rounded-lg inline-flex items-center w-full"
                    />
                    <!--                    <Checkbox-->
                    <!--                        label="Private Quiz"-->
                    <!--                        name="private"-->
                    <!--                        v-model="access_private"-->
                    <!--                    />-->
                </div>
                <div class="w-4/12" v-if="userHasRole('super_admin')">
                    <Checkbox
                        label="Pay to Enter"
                        name="pay_to_enter"
                        v-model="quiz.pay_to_enter"
                        :disabled="quiz.is_fundraiser || !isInputEditable() || !userHasConnectedStripeAccount()"
                        label-classes="border border-grey-300 p-4 rounded-lg inline-flex items-center w-full"
                        :errors="errors"
                    />
                    <TextInput
                        label="Entry fee"
                        name="entry_fee"
                        placeholder="0.00"
                        :errors="errors"
                        v-model="quiz.entry_fee"
                        :disabled="quiz.is_fundraiser || !isInputEditable() || ! userHasConnectedStripeAccount()"
                        v-if="quiz.pay_to_enter"
                    />
                </div>
            </div>

            <div>
                <h4 class="block text-lg text-secondary mt-8 mb-6">Scheduling</h4>
                <p class="text-sm text-grey-600 -mt-4 mb-4 text-grey-400">With Play Anytime, auto-start quizzes, you set the start and finish times when you create the first round.
                    With Live Tournaments, you get to choose when you want to manually start the quiz. This creates a countdown timer which players will see when the go to the page for your quiz.</p>
                <div class="mb-6">
                    <div class="flex">
                        <div class="w-6/12 px-2">
                            <div class="panel panel--no-shadow text-center pt-8 pb-12 px-12 cursor-pointer border border-secondary" @click="handleSchedulingClick(1)" :class="quiz.automatic_start ? '!bg-neutral' : ''">
                                <i class="fas fa-magic mb-4"></i>
                                <h2 class="text-lg mb-4">Play Anytime (auto-start)</h2>
                                <p class="text-sm">You set the start and finish times manually for each round.</p>
                            </div>
                        </div>
                        <div class="w-6/12 px-2">
                            <div class="panel panel--no-shadow text-center pt-8 pb-12 px-12 cursor-pointer border border-secondary" @click="handleSchedulingClick(0)" :class="!quiz.automatic_start ? '!bg-neutral' : ''">
                                <i class="fas fa-headset mb-4"></i>
                                <h2 class="text-lg mb-4">Live Tournament</h2>
                                <p class="text-sm">You manually choose when to start and finish each round.</p>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="automatic_start" v-model="quiz.automatic_start">
                </div>
                <div class="flex items-center align-top sm:gap-8" v-show="!quiz.automatic_start">
                    <DateTime
                        label="Live Tournament Scheduled For:"
                        name="scheduled_start_date"
                        v-model="quiz.scheduled_start_date"
                        :timezone="quiz.timezone"
                    />
                    <p class="text-xs">Choose a date and time for the scheduled commencement of the live event.
                        It's important to note that this selection doesn't initiate the event automatically.
                        To start the quiz, you must manually launch the quiz from your account and click on 'Start Round.'</p>
                </div>
            </div>

            <TextArea
                label="Custom How to play (optional)"
                name="how_to_play"
                v-model="quiz.meta.how_to_play"
            />

            <TextArea
                label="Custom Terms & Conditions (optional)"
                name="terms_and_conditions"
                v-model="quiz.meta.terms_and_conditions"
            />

            <TextArea
                label="Custom Privacy Statement (optional)"
                name="privacy_statement"
                v-model="quiz.meta.privacy_statement"
            />

            <button class="btn btn-tertiary" type="submit">
                Save
            </button>
        </Form>
    </div>
</template>
<script setup>

import {computed, onMounted, reactive, ref, watch} from "vue";
import api from "../../../api/axios-api.js";
import {useRouter, useRoute} from "vue-router";
import FileUploader from "../../Form/FileUploader.vue";
import RichTextArea from "../../Form/RichTextArea.vue";
import TextInput from "../../Form/TextInput.vue";
import TimeZoneSelect from "../../Form/TimeZoneSelect.vue";
import Checkbox from "../../Form/Checkbox.vue";
import DateTime from "../../Form/DateTime.vue";
import {useUserData} from "../../../composibles/userData.js";
import TextArea from "../../Form/TextArea.vue";
import Form from "../../Form/Form.vue";
import moment from "moment";
import 'moment-timezone';
import {useFormData} from "../../../composibles/formData.js";
import {useToast} from "vue-toast-notification";
import CustomSelect from "../../Form/CustomSelect.vue";

const {userHasConnectedStripeAccount, userHasRole} = useUserData();
const {errors, setFormErrors} = useFormData();

const props = defineProps({
    editing: {
        type: Boolean,
        default: false
    }
});

const route = useRoute();
const router = useRouter();
const $toast = useToast();

const timezones = computed(() => {
    return moment?.tz?.names();
});

const charities = ref({});

const quiz = reactive({
    allow_teams: 0,
    allow_guests: 1,
    access_private: 0,
    automatic_start: 1,
    autoplay_rounds: 1,
    is_fundraiser: 0,
    charity_id: null,
    description: '',
    title: '',
    image: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    is_private: 0,
    tournament: 1,
    pay_to_enter: 0,
    entry_fee: '',
    scheduled_start_date: '',
    randomise_questions: 1,
    randomise_answers: 1,
    status: 'draft',
    meta: {
        how_to_play: '',
        privacy_statement: '',
        terms_and_conditions: '',
        logo: null,
    },
});

// Watcher to react to changes in `allowGuests`
watch([() => quiz.is_fundraiser, () => quiz.pay_to_enter], ([newFundraiserValue, newPayToEnterValue]) => {
    if (newFundraiserValue || newPayToEnterValue) {
        quiz.allow_guests = 0;
    } else {
        quiz.allow_guests = 1;
    }
    // You can handle the newOtherValue here if needed
}, {
    immediate: true
});

const fetchApplicableCharities = async () => {
    try {
        const response = await api.get('/charities');
        if (response.data.charities) {
            charities.value = response.data.charities.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                    selected: false
                }
            });
        }
    } catch (err) {
        $toast.open({
            message: err.response.data.message,
            type: 'error',
            duration: 2000,
            position: 'top'
        });
    }
};

const getApplicableCharities = computed(() => {
    return charities.value;
})

const isInputEditable = () => {
    const editableStatuses = ['draft', 'publish'];
    return editableStatuses.includes(quiz.status);
}

const handleSchedulingClick = (value) => {
    quiz.automatic_start = value;
}

const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    let quizResponse, url;

    let headers = {
        'Accept': 'application/json',
        'Content-type': 'multipart/form-data',
    };

    if (props.editing && route.params.quizId) {
        url = `/quiz/${route.params.quizId}`
        formData.append('_method', 'PUT');
    } else {
        url = '/quiz/create';
    }

    formData.append('account_id', route.params.id);

    try {
        quizResponse = await api.post(url, formData, {
            headers: headers
        });

        if (quizResponse.data.success) {
            await router.push({
                name: 'account/my-quizzes/view-quiz',
                params: {quizId: quizResponse.data.quiz.id}
            });
        }
    } catch (error) {
        $toast.open({
            message: error.response.data.message,
            type: 'error',
            duration: 2000,
            position: 'top'
        });

        setFormErrors(error);
    }
}

const loadQuiz = async () => {
    const quizData = route.meta.quizData;
    if (quizData) {
        Object.assign(quiz, quizData);
        quiz.tournament = quizData.type === 'tournament';
    }
}

onMounted(async () => {
    if (props.editing) {
        await loadQuiz();
    }
    await fetchApplicableCharities();
})
</script>
