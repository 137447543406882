<template>
    <div>
        <div class="mb-3">
            <QuizWinner v-if="quizStore.getQuizWinner"
                                   :name="quizStore.getQuizWinner.name"
                                   title="Quiz winner is"
            />
        </div>

        <Leaderboard/>
    </div>
</template>

<script setup>
import {useQuizStore} from "../../../stores/quiz";
import Leaderboard from "../../../components/Quiz/Global/Leaderboard.vue";
import QuizWinner from "../../../components/QuizWinner.vue";

const quizStore = useQuizStore();

</script>
