import moment from "moment/moment";
import {computed, watch} from "vue";
import {useScreenStore} from "../stores/screen.js";
import {ParticipantData} from "./participantData.js";
import {QuizData} from "./quizData.js";
import {useQuizStore} from "../stores/quiz.js";

export function RoundData() {
    const screenStore = useScreenStore();
    const quizStore = useQuizStore();

    const {quiz, currentRound, nextRound} = QuizData();
    const {participant, isParticipatingInRound} = ParticipantData();

    const hasCurrentRound = computed(() => {
        return typeof currentRound.value !== 'undefined';
    })

    const hasRoundStarted = computed(() => {
        let startDate = currentRound.value?.startDate;
        if (startDate === false) return false;
        return (screenStore.getTime >= startDate);
    })

    const hasRoundEnded = computed(() => {
        let endDate = currentRound.value?.endDate;
        if (endDate === false) return false;
        return (endDate < screenStore.getTime);
    })

    const isRoundOpen = computed(() => {
        return currentRound.value?.status === 'active';
    })

    const isRoundClosed = computed(() => {
        return currentRound.value?.status === 'closed';
    })

    const isRoundWaitingToStart = computed(() => {
        return nextRound && (hasCurrentRound || hasRoundEnded)
    })

    const getRoundParticipantsNames = computed(() => {
        return getRoundParticipants ? getRoundParticipants.value.map(a => a.name) : [];
    })

    const getRoundWinner = computed(() => {
        return currentRound.value?.winner;
    })

    const getRoundParticipants = computed(() => {
        if (typeof currentRound.value === 'undefined') return;

        return quizStore.getParticipants.map(participant => {
            const filteredRound = participant.rounds.filter(round => round.round_id === currentRound.value.id);
            if (filteredRound.length > 0) {
                return {
                    participant_id: participant.participant_id,
                    name: participant.name,
                    answered_questions: filteredRound[0].answered_questions,
                    status: participant?.status ?? 'active'
                }
            }
        }).filter((participant) => {
            return typeof participant !== 'undefined' && participant.status === 'active'
        });
    });


    const getRoundLoadedTime = computed(() => {
        if (typeof currentRound.value === "undefined") return false;

        let loadedDate = false;

        if (quiz.value.automaticStart) {
            const participantRoundLoadedDate = participant.value.rounds.find(
                (r) => r.roundId === currentRound.value.id
            )?.loadedDate;

            loadedDate = participantRoundLoadedDate ?? false;
        } else {
            loadedDate = currentRound.value.loadedDate
        }

        return loadedDate !== false ? new Date(loadedDate) : false;
    });


    const getRoundStartDate = computed(() => {
        if (typeof currentRound.value === "undefined") return false;

        let startDate = false;

        if (quiz.value.automaticStart && isParticipatingInRound.value) {
            const participantRoundStartDate = participant.value.rounds.find(
                (r) => r.roundId === currentRound.value.id
            )?.startDate;

            startDate = participantRoundStartDate ?? false;
        } else {
            startDate = currentRound.value?.startDate;
        }

        console.log(currentRound.value.startDate);

        return startDate !== false ? new Date(startDate) : false;
    });


    const getRoundEndDate = computed(() => {
        if (typeof currentRound.value === "undefined") return false;

        let endDate = false;

        if (quiz.value.automaticStart && isParticipatingInRound.value) {
            const participantRoundEndDate = participant.value.rounds.find(
                (r) => r.roundId === currentRound.value.id
            )?.endDate;

            endDate = participantRoundEndDate ?? false;

        } else {
            if (currentRound.value.endDate !== false) {
                endDate = currentRound.value.endDate;
            } else {
                endDate = currentRound.value.startDate ? moment(currentRound.value.startDate).add(
                    currentRound.value.duration,
                    "minutes"
                ).toDate() : false;
            }
        }
        return endDate !== false ? new Date(endDate) : false;
    });

    const getRoundQuestionIds = computed(() => {
        return currentRound.value?.questionIds ?? [];
    });


    return {
        hasCurrentRound,
        hasRoundStarted,
        hasRoundEnded,
        isRoundOpen,
        isRoundClosed,
        isRoundWaitingToStart,
        getRoundParticipantsNames,
        getRoundWinner,
        getRoundStartDate,
        getRoundEndDate,
        getRoundLoadedTime,
        getRoundParticipants,
        getRoundQuestionIds
    }
}

