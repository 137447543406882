<template>
    <div>
        <nav v-if="links.length > 1" class="mt-8">
            <ul class="flex gap-2">
                <li v-for="(link) in links" class="" v-if="link.url">
                    <a @click="handlePaginateLinkClick($event, link.url)"
                       class="btn btn--tab btn--tab--with-icon btn-grey-200 w-full mb-4 flex items-end leading-none"
                       :href="link.url"
                       :class="link.active ? 'bg-primary text-white' : ''"
                       v-html="link.label"></a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script setup>
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
    links: {
        default: [],
        type: Array
    }
})

const  handlePaginateLinkClick = (event, url)  => {
    event.preventDefault();

    let paged = url.split('?page=');

    if (paged[1]) {
        router.push({
            name: 'account/my-quizzes/paged',
            params: {
                page: paged[1],
            },
        });
    }
}
</script>
