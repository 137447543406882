<template>
    <div class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            ></FadeLoader>
        </div>
        <div v-else class="quiz-screen h-full">
            <div class="login-screen h-full">
                <router-link
                    class="nav-link block my-6"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">
                    <img v-if="quizData.meta !== null && quizData.meta?.logo?.url && quizData.meta?.logo?.url.length > 0" alt="" :src="quizData.meta?.logo?.url" class="max-h-11 h-full mx-auto"/>
                    <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-48 mx-auto">
                </router-link>
                <div class="mx-auto max-w-[34rem] px-8">
                    <Notices/>
                    <div class="panel">
                        <RegisterForm
                            @success="handleRegister"
                            :quiz-privacy-statement="quiz.value?.meta.privacy_statement"
                            :quiz-terms-and-conditions-statement="quiz.value?.meta.privacy_statement"
                        />
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted} from "vue";
import {useRouter} from "vue-router";
import {useScreenStore} from "../../../stores/screen.js";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import Notices from "../../../components/Global/Notices.vue";
import Footer from "../../../layouts/Footer.vue";
import RegisterForm from "../../../components/Account/RegisterForm.vue";

const {setIsLoading, isScreenLoading} = useScreenStore();

const {setQuizData, quiz, requiresPayment} = QuizData();
const {setQuizParticipantData} = ParticipantData();

const router = useRouter();

const props = defineProps({
    quizData: Object,
    quizParticipantData: Object
});

const setState = async () => {
    setIsLoading(true);
    await setQuizData(props.quizData);
    await setQuizParticipantData(props.quizParticipantData);
    setIsLoading(false);
}

const handleRegister = () => {
    if (requiresPayment) {
        if (quiz.value.isFundraiser) {
            router.push({path: `/quiz/${props.quizData.id}/donate`});
        } else {
            router.push({path: `/quiz/${props.quizData.id}/payment`});
        }
    } else {
        router.push({name: 'play-quiz', params: {quizSlug: quiz.value.slug}});
    }
}

onMounted(async () => {
    await setState();
});
</script>
