<template>
    <div>
        <div v-if="paymentIntentId && (data.paymentStatus === 'processing' || data.paymentStatus === 'completed')" class="flex flex-col items-center">
            <div v-if="data.paymentStatus === 'processing'">
                Payment Processing ...
            </div>
            <div v-if="data.paymentStatus === 'completed'">
                Payment Succeeded
            </div>
        </div>
        <div v-else>
            <h3 data-bg-colour="white" class="text-xl text-center font-bold mb-4 text-primary">£{{ paymentData.amount }}</h3>
            <Form @submit="submitPayment($event)" :error-message="errorMessage">
                <StripeCardElement
                    ref="cardComponentRef"
                    :amount="amount"
                    :return-url="returnUrl"
                    :payment-data="paymentData"
                    :payment-intent-id="paymentIntentId"
                />
                <div class="mb-6">
                    <label class="relative text-sm mb-2 font-sans flex">
                        <input type="checkbox" class="mr-2 border border-grey-300 p-2 rounded-md"
                               v-model="privacy_consent">
                        <span class="block">I have read and agree to the <button class='underline' type='button'
                            @click="handleOptInModal('privacy')">privacy policy</button> and <button
                            class='underline' @click="handleOptInModal('terms')"
                            type='button'>terms and conditions</button></span>
                    </label>
                </div>
                <div class="flex flex-col items-center">
                    <button type="submit"
                            class="btn btn-secondary text-white" :disabled="data.isLoading"
                            :class="data.isLoading ? 'btn--is-loading' : ''">
                        <span class="loader" v-if="data.isLoading"></span> Pay
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script setup>
import api from "../../../api/axios-api";
import StripeCardElement from "../../Stripe/StripeCardElement.vue";
import {onBeforeUnmount, onMounted, ref} from "vue";
import Form from "../../Form/Form.vue";
const emit = defineEmits(['success', 'failed']);

const props = defineProps({
    quizId: Number,
    amount: {
        type: String,
        default: ""
    },
    paymentData: {
        type: Object,
        default: () => ({}),
    },
    privacyStatement: {
        type: String,
        default: '',
    },
    termsAndConditionsStatement: {
        type: String,
        default: ''
    },
    paymentIntentId: {
        type: String,
        default: null
    },
    returnUrl: {
        type: String,
        default: ''
    },
    redirectStatus: {
        type: String,
        default: null
    }
});

const cardComponentRef = ref(null);
const errorMessage = ref(null);

const data = ref({
    cardElementData: null,
    privacy_consent: false,
    isLoading: false,
    paymentStatusInterval: null,
    paymentStatus: 'pending'
})

const maybeHandlePaymentProcessing = () => {
    if (props.paymentIntentId) {
        data.value.isLoading = true;
        data.value.paymentStatus = 'processing';

        // Start the timeout to handle webhook not received
        startPaymentTimeout();

        data.value.paymentStatusInterval = setInterval(() => {
            checkPaymentStatus(props.paymentIntentId);
        }, 2000);
    }
}

const checkPaymentStatus = async (paymentId) => {
    try {
        const response = await api.get(`/check-payment-status/${paymentId}`);

        if (response.data.success) {
            if ( response.data.status === 'succeeded') {

                console.log('succesful');

                data.value.paymentStatus = 'completed';
                clearPaymentTimeout();
                clearInterval(data.value.paymentStatusInterval);
                emit('success');
            } else if (response.data.status === 'payment_failed') {
                data.value.paymentStatus = 'failed';
                clearPaymentTimeout();
                clearInterval(data.value.paymentStatusInterval);
                emit('failed');
            }
        }
    } catch (err) {
        clearPaymentTimeout();
        clearInterval(data.value.paymentStatusInterval);
        emit('failed');
    }
}

const submitPayment = async(event) => {
    event.preventDefault();
    data.value.isLoading = true;
    const cardComponent = cardComponentRef;
    await cardComponent.value.createPaymentMethod();
    const error = cardComponent.value.error;
    if (error.message) {
        errorMessage.value = error.message;
    }
    data.value.isLoading = false;
    cardComponent.value.resetError();
}

const startPaymentTimeout = () => {
    data.value.paymentTimeout = setTimeout(() => {
        // Timeout occurs, handle the case here
        data.value.paymentStatus = 'timeout';
        clearInterval(this.paymentStatusInterval);
        emit('timeout');
    }, 60000); // Set the timeout duration in milliseconds (e.g., 60000ms = 1 minute)
}

const clearPaymentTimeout = () => {
    clearTimeout(data.value.paymentTimeout);
}

onMounted(() => {
    maybeHandlePaymentProcessing();
});

onBeforeUnmount(() => {
    clearInterval(data.value.paymentStatusInterval);
});
</script>
