<template>
    <Label :label="label" :label-classes="labelClasses" position="left" :has-error="hasErrors" :error-message="getErrorMessage">
        <input type="checkbox" :id="name" :name="name" v-model="inputValue" :class="[{'border-red-200': hasErrors}, inputClasses]" :disabled="disabled">
    </Label>
</template>
<script setup>
import Label from "./Label.vue";
import {computed, ref, watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: '',
    label: '',
    labelClasses: '',
    inputClasses: {
        type: String,
        default: 'text-md font-medium font-sans border border-grey-300 p-2 rounded-md disabled:bg-grey-200'
    },
    modelValue: String,
    disabled: {
        type: Boolean,
        default: false
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const inputValue = ref(props.modelValue === 1 || props.modelValue === true);


watch(() => props.modelValue, (newValue) => {
    inputValue.value = !!newValue;
});

watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

const labelClasses = computed(() => {
    let inputClasses = props.labelClasses;
    if (props.disabled) {
        inputClasses += ' text-grey-300 bg-grey-200 border-grey-200 cursor-not-allowed';
    }
    return inputClasses;
});
</script>
