<script setup>

</script>

<template>
    <div class="panel">
        <h1 class="text-xl">Donations</h1>
        <p>Coming soon...</p>
    </div>
</template>

<style scoped>

</style>
