<template>
    <div id="results" v-if="data.showResults">
        <div class="panel mb-8">
            <p class="tag text-center mb-6">Leaderboard</p>
            <div class="mb-12 panel panel--no-shadow border border-grey-200 font-sans">
                <div ref="tabsRef" class="w-[calc(100%-2rem)] relative after:absolute after:right-0 after:top-0 after:bg-gradient-to-l after:from-white after:h-full after:w-1/6" v-if="!data.isLoading">
                    <div @scroll="handleTabsScroll" class="tabs flex flex-nowrap gap-2 mb-5 overflow-x-scroll">
                        <template v-if="getRoundResultsSortedByEndDate.length > 1">
                            <button v-for="(roundResults, index) in getRoundResultsSortedByEndDate"
                                    @click="showResultsTable(roundResults.id)"
                                    class="btn btn--tab flex-shrink-0"
                                    :class="(getActiveTable == roundResults.id) ? 'btn-tertiary' : 'btn-grey-200'"
                            >Round {{ getRoundResultsSortedByEndDate.length - index }}
                            </button>
                        </template>
                        <button @click="showResultsTable('overall')" v-if="data.results.total"
                                class="btn btn--tab flex-shrink-0"
                                :class="(getActiveTable === 'overall') ? 'btn-tertiary' : 'btn-grey-200'">
                            Overall
                        </button>
                    </div>
                </div>
                <LeaderboardTable
                    :results="getResultsTable?.results"
                    :title="getResultsTable.title"
                    :participantId="participant.id"
                    :is-loading="data.isLoading"
                />
            </div>

        </div>
    </div>
</template>
<script setup>
import api from "../../../api/axios-api";
import LeaderboardTable from "./LeaderboardTable.vue";
import {onMounted, ref, computed} from "vue";
import {QuizData} from "../../../composibles/quizData.js";
import {RoundData} from "../../../composibles/roundData.js";
import {useQuizStore} from "../../../stores/quiz.js";
import {useParticipantStore} from "../../../stores/participant.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useToast} from "vue-toast-notification";

const quizStore = useQuizStore();
const participantStore = useParticipantStore();

const {quiz, currentRound, isQuizClosed} = QuizData();
const {hasRoundStarted, hasRoundEnded, isRoundClosed} = RoundData();
const {participant} = ParticipantData();

const data = ref({
    showResults: false,
    results: {
        roundResults: [],
        total: {
            results: []
        }
    },
    isLoading: false,
    activeTable: 'overall',
    userClicked: false
});

const tabsRef = ref(null);
const $toast = useToast();

const getActiveTable = computed(() => {
    return data.value.activeTable;
});

const getResultsTable = computed(() => {
    if (data.value.activeTable === 'overall') {
        return data.value.results.total;
    } else {
        return data.value.results.roundResults.find((r) => r.id === data.value.activeTable);
    }
});

const getRoundResultsSortedByEndDate = computed(() => {
    return data.value.results.roundResults.sort((a, b) => {
        if (new Date(a.end_date) > new Date(b.end_date)) {
            return -1;
        } else {
            return 0;
        }
    });
})

const loadResults = async() => {
    let quizId = quiz.value.id;
    let roundId = currentRound.value?.id;

    try {
        setLoading(true);

        let participantPos, trend;

        const response = await api.get(`/quiz/${quizId}/results`);

        if (response.data.results) {
            const results = response.data.results;

            if (typeof results.rounds !== "undefined") {
                data.value.results.roundResults = results.rounds

                if (typeof roundId !== 'undefined') {
                    let thisRoundResults = results.rounds.find((r) => r.id === roundId);
                    if (thisRoundResults) {
                        let roundWinner = thisRoundResults.results[0];
                        quizStore.setRoundWinner(roundWinner);
                        participantPos = thisRoundResults.results.findIndex((result, index) => {
                            return result.id === participant.value.id;
                        });
                    }
                    if (results.rounds.length > 1 && hasRoundStarted.value && hasRoundEnded.value && thisRoundResults) {
                        data.value.activeTable = roundId;
                    }
                }
            }

            if (typeof results.total !== "undefined") {
                data.value.results.total = results.total

                if (participantPos >= 0) {
                    trend = results.total.results.find(p => p.id === participant.value.id).trend;
                }

                if (isQuizClosed.value) {
                    let quizWinner = data.value.results.total.results[0];
                    quizStore.setQuizWinner(quizWinner);

                    let participantIndex = results.total.results.findIndex((result) => {
                        return result.id === participant.value.id;
                    });
                    if (participantIndex < 0) return;

                    let roundPositions = [];
                    results.rounds.map((roundResult) => {
                        const roundPosition = roundResult.results.findIndex((result) => {
                            return result.id === participant.value.id
                        });
                        let roundPositionObj = {
                            position: roundPosition + 1,
                            roundId: roundResult.id,
                        }
                        roundPositions.push(roundPositionObj);
                    });

                    let participant = results.total.results[participantIndex];

                    participantStore.setOverallResults({
                        questionCount: parseInt(participant.questions_answered),
                        correctAnswerCount: parseInt(participant.correct_answer_count),
                        totalTime: participant.total_time,
                        position: participantIndex + 1,
                        roundPositions: roundPositions,
                        totalParticipantCount: self.results.total.results.length
                    });
                }
            }

            if (participantPos >= 0) {
                participantStore.setRoundFinalScore({
                    roundId: roundId,
                    position: participantPos + 1,
                    trend: trend
                });
            }
        }
    } catch (err) {
        console.error(err);
    } finally {
        setLoading(false);
    }
}

const handleTabsScroll = (e) => {
    const tabsWrapper = e.target;
    const scrollLeft = tabsWrapper.scrollLeft;
    const scrollWidth = tabsWrapper.scrollWidth;
    const clientWidth = tabsWrapper.clientWidth;

    if (scrollLeft + clientWidth >= scrollWidth) {
        tabsRef.classList.add('after:hidden');
    } else {
        tabsRef.classList.remove('after:hidden');
        tabsRef.classList.add('after:visible');
    }
}

const setLoading = (state = true) => {
    data.value.isLoading = state;
}

const showResultsTable = (context)  =>{
    data.value.userClicked = true;
    data.value.activeTable = context;
}

onMounted(async  () => {
    if ((hasRoundStarted.value || isQuizClosed.value || isRoundClosed.value)) {
        data.value.showResults = true;
        await loadResults();
    }
});


</script>
