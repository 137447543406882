<template>
    <div class="flex justify-between mb-8" v-if="currentRound">
        <h1 class="text-1xl mb-4" v-if="currentRound.title">
            {{ currentRound.title }}
        </h1>
        <div class="flex gap-12 items-center">
            <div>
                <h3 class="mb-0 font-bold">Round duration</h3>
                <span data-bg-colour="white" class="text-secondary text-xl">{{ currentRound.duration }}m</span>
            </div>
            <div>
                <h3 class="mb-0 font-bold">No of questions</h3>
                <span data-bg-colour="white" class="text-secondary text-xl">{{ currentRound.questionCount }}</span>
            </div>
            <button type="button" ref="shareBtnRef" @click="shareUrl"
                    class="btn btn-primary btn--large">
                <i class="fas fa-clipboard mr-2"></i>
                <span>Copy Share URL</span>
            </button>
        </div>
    </div>
</template>
<script setup>
import {computed, onBeforeUnmount, ref} from "vue";
import {QuizData} from "../../../composibles/quizData.js";

const {quiz} = QuizData();

const props = defineProps({
    currentRound: {
        default: () => {}
    }
});

const shareBtnTextTimeout = ref(null);
const shareBtnRef = ref(null);

const getShareURL = computed(() => {
    return `${baseUrl}/quiz/${quiz.value.slug}`;
});

const shareUrl = () => {
    navigator.clipboard.writeText(getShareURL.value);

    let shareBtn = shareBtnRef.value.getElementsByTagName('span')[0];
    let originalText = shareBtn.innerText;

    shareBtn.innerText = 'Copied!';

    if (shareBtnTextTimeout.value) {
        clearTimeout(shareBtnTextTimeout.value);
    }

    shareBtnTextTimeout.value = setTimeout(() => {
        shareBtn.innerText = originalText;
    }, 1000);
}

onBeforeUnmount(() => {
    if (shareBtnTextTimeout.value) {
        clearTimeout(shareBtnTextTimeout.value);
    }
});
</script>
