<template>
    <Bar :data="data" :options="options" />
</template>

<script setup>
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const props = defineProps({
    data: {
        labels: Array,
        datasets: Array,
    },
    options: {
        responsive: true,
        maintainAspectRatio: false
    }
});
</script>
