<template>
    <div class="mb-6">
        <Label :for="name" class="mb-2 font-bold font-sans flex flex-wrap items-center" :has-error="hasErrors" :error-message="getErrorMessage">{{label}}</Label>
        <QuillEditor  :modules="modules" theme="snow" toolbar="basic" :content="inputValue" v-model:content="inputValue" content-type="html"/>
        <input type="hidden" :name="name" :value="inputValue"/>
    </div>
</template>
<script setup>

import {computed, ref, watch} from "vue";
import Label from "./Label.vue";
import ImageUploader from 'quill-image-uploader';
import api from "../../api/axios-api.js";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: '',
    label: '',
    modelValue: '',
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const inputValue = ref(props.modelValue);

const modules = {
    name: 'imageUploader',
    module: ImageUploader,
    options: {
        upload: file => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("file", file);

                api.post('/upload', formData)
                    .then(res => {
                        console.log(res)
                        resolve(res.data.preview_url);
                    })
                    .catch(err => {
                        reject("Upload failed");
                        console.error("Error:", err)
                    })
            })
        }
    }
}

const data = ref({
    customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }],
    ]
});

watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
    inputValue.value = newValue;
});
</script>
