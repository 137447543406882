<template>
    <div>
        <div class="flex flex-wrap md:flex-nowrap gap-4">
            <div class="w-full" :class="!quiz.automaticStart ? 'md:w-5/12' : ''">
                <WaitingRoom/>
            </div>
            <div class="w-full md:w-7/12" v-if="!quiz.automaticStart">
                <LiveChat/>
            </div>
        </div>
    </div>
</template>
<script setup>

import WaitingRoom from "../../../components/Quiz/Round/WaitingRoom.vue";
import LiveChat from "../../../components/Quiz/LiveChat/LiveChat.vue";
import {QuizData} from "../../../composibles/quizData.js";

const {quiz} = QuizData();
</script>
