import {defineStore} from "pinia";
import api from "../api/axios-api";

export const useConfigStore = defineStore('configStore', {
    state: () => ({
        version: null,
        testingStage: null,
        env: null,
        privacy_policy: '',
        terms_and_conditions: ''
    }),
    getters: {
        getVersion: (state) => state.version,
        getTestingStage: (state) => state.testingStage,
        getEnv: (state) => state.env,
        getPrivacyPolicy: (state) => state.privacy_policy,
        getTermsAndConditions: (state) => state.terms_and_conditions
    },
    actions: {
        getConfig () {
            api.get(`/config`)
                .then(response => {
                   this.setConfig(response.data);
                })
        },
        setConfig(payload) {
            const {version, testing_stage, env, privacy, terms} = payload;

            this.version = version;
            this.testingStage = testing_stage;
            this.env = env;
            this.privacy_policy = privacy;
            this.terms_and_conditions = terms;
        },
    }
});
