<template>
   <div>
       <div class="panel">
           <h1 class="text-xl mb-4" v-if="editing">Edit Round</h1>
           <h1 class="text-xl mb-4" v-else>Add Round</h1>

           <Form @submit.prevent="handleSubmit" :error-message="errorMessage">
               <TextInput
                   label="Title"
                   name="title"
                   placeholder=""
                   v-model="data.title"
                   :value="data.title"
                   :errors="errors"
               />

               <TextArea
                   label="Description (optional)"
                   name="description"
                   placeholder=""
                   v-model="data.description"
                   :value="data.description"
                   :errors="errors"
               />

               <div v-if="quiz?.automatic_start" class="flex gap-4">
                   <div class="w-6/12">
                       <DateTime
                           name="start_date"
                           label="Start Date"
                           v-model="data.start_date"
                           :value="data.start_date"
                           :timezone="quiz?.timezone"
                           :errors="errors"
                       />
                   </div>
                   <div class="w-6/12">
                       <DateTime
                           name="end_date"
                           label="End Date"
                           v-model="data.end_date"
                           :value="data.end_date"
                           :timezone="data.quiz?.timezone"
                           :errors="errors"
                       />
                   </div>
               </div>
               <div v-else>
                   <Checkbox
                       name="enable_power_ups"
                       label="Enable Power-ups"
                       v-model="data.power_ups"
                       :value="data.power_ups"
                       :errors="errors"
                   />
               </div>

              <Number
                   label="Time limit (min)"
                   name="duration_min"
                   placeholder=""
                   v-model="data.duration_min"
                   :errors="errors"
              />
               <button type="submit" class="btn btn-tertiary">Save</button>
           </Form>
       </div>
   </div>
</template>
<script setup>
import router from "../../../router/router";
import api from "../../../api/axios-api";
import Form from "../../Form/Form.vue";
import TextInput from "../../Form/TextInput.vue";
import TextArea from "../../Form/TextArea.vue";
import DateTime from "../../Form/DateTime.vue";
import Checkbox from "../../Form/Checkbox.vue";
import Number from "../../Form/Number.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useFormData} from "../../../composibles/formData.js";

const route = useRoute();

const props = defineProps({
    editing: {
        type: Boolean,
        default: false
    },
});

const quiz = ref(route.meta.quizData);

const {setFormErrors, errors, errorMessage} = useFormData();

const data = ref({
    title: '',
    description: '',
    duration_min: false,
    power_ups: false,
    start_date: '',
    end_date: '',
});

const handleSubmit = (e) => {
    e.preventDefault();

    if (props.editing) {
        api.put('/quiz/'+ route.params.quizId +'/round/' + route.params.roundId, {
            title: data.value.title,
            description: data.value.description,
            duration_min: data.value.duration_min,
            power_ups: data.value.power_ups,
            start_date: data.value.start_date,
            end_date: data.value.end_date
        }).then((response) => {
            if (response.data.roundId) {
                router.push({name: 'account/my-quizzes/view-quiz', params: {
                        quizId: route.params.quizId,
                }});
            }
        }).catch(error => {
            setFormErrors(error);
        });
    } else {
        api.post('/quiz/' + route.params.quizId + '/round', {
            title: data.value.title,
            description: data.value.description,
            duration_min: data.value.duration_min,
            power_ups: data.value.power_ups,
            start_date: data.value.start_date,
            end_date: data.value.end_date
        }).then((response) => {
            if (response.data.roundId) {
                router.push({name: 'account/my-quizzes/view-quiz', params: {
                        quizId: route.params.quizId,
                }});
            }
        }).catch(error => {
            setFormErrors(error);
        });
    }
}
const loadRound = () => {
    if (props.editing) {
        const round = quiz.value.rounds.find((r) => r.id === parseInt(route.params.roundId));
        if (round) {
            data.value.title = round.title;
            data.value.description = round.description;
            data.value.duration_min = round.duration_min;
            data.value.start_date = round.start_date;
            data.value.end_date = round.end_date;
        }
    }
}

onMounted(() => {
    loadRound();
});
</script>
