import api from "../api/axios-api";
import {defineStore} from "pinia";
import {useToast} from "vue-toast-notification";

export function prepareParticipantRound(round) {
    return {
        answerJobStatus: round.answer_job_status,
        answeredQuestions: round?.answered_questions ?? [],
        completed: round?.completed ?? false,
        completedDate: round?.completed_date ?? new Date(round.completed_date),
        expired: round?.expired ?? false,
        endDate: round.end_date ?? new Date(round.end_date),
        position: round?.position ?? null,
        trend: round?.trend ?? null,
        roundId: round.round_id,
        startDate: round?.start_date ?? new Date(round.start_date),
        availablePowerUps: round?.available_power_ups ?? [],
        loadedDate: round.loaded_at ?? new Date(round.loaded_at)
    }
}

const defaultState = {
    id: null,
    name: '',
    rounds: [
        {
            answerJobStatus: 'pending',
            roundId: null,
            answeredQuestions: [],
            completed: false,
            expired: false,
            endDate: false,
            position: false,
            trend: false,
            startDate: false,
            availablePowerUps: []
        }
    ],
    overall: {
        questionCount: 0,
        correctAnswerCount: 0,
        totalTime: '',
        position: false,
    },
}

export const useParticipantStore = defineStore('participant', {
    state: () => ({
        participant: defaultState,
    }),
    getters: {
        getParticipant: (state) => state.participant
    },
    actions: {
        async loadParticipant(payload) {
            const {quizId, shouldJoinPresenceChannel, participant} = payload;
            this.setParticipant(participant);
            if (participant?.participant_id) {
                if (shouldJoinPresenceChannel) {
                    this.maybeJoinParticipantPresenceChannel(quizId);
                }
            }
        },
        async participate(payload) {
            try {
                const {name, quizId, shouldJoinPresenceChannel} = payload;
                const response = await api.post('/quiz/' + quizId + '/participate', {
                    name: name
                });
                if (response.data.participant_id) {
                    this.setParticipant(response.data);
                    if (shouldJoinPresenceChannel) {
                        this.maybeJoinParticipantPresenceChannel(quizId);
                    }
                }
                return response.data;
            } catch (err) {
                throw err;
            }
        },
        async participateInRound(payload) {
            try {
                const response = await api.post('/quiz/' + payload.quizId + '/participate/' + payload.participantId + '/round/' + payload.roundId);
                if (response.data.round_id) {
                    this.setParticipantRound(response.data);
                }
                return response.data;
            } catch (err) {
               throw err;
            }
        },
        async deleteRoundParticipant(payload) {
            const $toast = useToast();
            try {
                const response = await api.delete('/quiz/' + payload.quizId + '/round/' + payload.roundId + '/participant/' + payload.participantId);
                if (response.data.success) {
                    let participantRoundIndex = this.participant.rounds.findIndex(r => r.id === payload.roundId);
                    this.participant.rounds.splice(participantRoundIndex, 1);
                }
                return response.data;
            } catch (err) {
                throw err;
            }
        },
        setRoundAsCompleted(payload) {
            const {roundId} = payload;
            this.participant.rounds.filter((r) => r.roundId === roundId)[0].completed = true;
        },
        setRoundAsExpired(payload) {
            const {roundId} = payload;
            this.participant.rounds.filter((r) => r.roundId === roundId)[0].expired = true;
        },
        resetParticipantState() {
            this.participant = defaultState;
        },
        setParticipant(participant) {
            if (participant?.participant_id) {
                this.participant = {
                    id: participant.participant_id,
                    name: participant.name,
                    rounds: participant.rounds.map(round => {
                        return prepareParticipantRound(round);
                    }),
                    overall: {
                        questionCount: 0,
                        correctAnswerCount: 0,
                        totalTime: '',
                        position: false,
                    }
                };
            }
        },
        setParticipantRound(round) {
            this.participant.rounds.push(prepareParticipantRound(round));
        },
        setRoundAnswerJobStatus(payload) {
            const {roundId, status} = payload;
            let participantRoundIndex = this.participant.rounds.findIndex(r => r.roundId === roundId);
            if (participantRoundIndex > -1) {
                this.participant.rounds[participantRoundIndex].answerJobStatus = status;
            }
        },
        maybeJoinParticipantPresenceChannel(quizId) {
            if (typeof this.participantQuizPresenceChannel === 'undefined') {
                this.participantQuizPresenceChannel = Echo.join(`quiz-participant-streaming-channel.${quizId}`);
            }
        },
        deleteParticipantRound(payload) {
            const {roundId} = payload;
            const participantRounds = this.participant.rounds;
            const index = participantRounds.findIndex(r => r.id === roundId);
            if (index !== -1) {
                participantRounds.splice(index, 1);
            }
        },
        setRoundFinalScore(payload) {
            const {roundId, position, trend} = payload;
            let participantRoundIndex = this.participant.rounds.findIndex(r => r.roundId === roundId);

            if (participantRoundIndex > -1) {
                this.participant.rounds[participantRoundIndex]['position'] = position;
                this.participant.rounds[participantRoundIndex]['trend'] = trend;
            }
        },
        setOverallResults(payload) {
            this.participant.overall = payload;
        },
    }
});
