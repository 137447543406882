<template>
    <div class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            ></FadeLoader>
        </div>
        <div v-else class="quiz-screen h-full">
            <div class="login-screen h-full">
                <router-link
                    class="nav-link block my-6"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">
                    <img v-if="quizData.meta !== null && quizData.meta?.logo?.url && quizData.meta?.logo?.url.length > 0" alt="" :src="quizData.meta?.logo?.url" class="max-h-11 h-full mx-auto"/>
                    <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-48 mx-auto">
                </router-link>
                <div class="mx-auto max-w-[34rem] px-8">
                    <div class="panel">
                        <component :is="getCurrentForm" @success="handleSignIn"/>
                        <div v-if="allowGuests && getCurrentForm.__name !== 'GuestForm'" class="flex flex-col items-center">
                            <span class="block my-4 uppercase font-bold text-grey-300 text-sm">Or</span>
                            <button @click="showForm(GuestForm)" class="btn btn-primary">
                                Play as guest
                            </button>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script setup>

import router from "../../../router/router";
import Footer from "../../../layouts/Footer.vue";
import {computed, onMounted, ref} from "vue";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import LoginForm from "../../../components/Account/LoginForm.vue";
import GuestForm from "../../../components/Quiz/Participant/GuestForm.vue";
import {ScreenData} from "../../../composibles/screenData.js";

const {setQuizData, allowGuests, requiresPayment} = QuizData();
const {setQuizParticipantData} = ParticipantData();
const {setIsLoading, isScreenLoading} = ScreenData();

const props = defineProps({
    quizData: Object,
    quizParticipantData: Object
})

const data = ref({
    currentForm: LoginForm,
});

const getCurrentForm =  computed(() => {
    return data.value.currentForm;
});

const setState = async() => {
    setIsLoading(true);
    await setQuizData(props.quizData);
    await setQuizParticipantData(props.quizParticipantData);
    setIsLoading(false);
}

const handleSignIn = () => {
    if (requiresPayment()) {
        if (quiz.value.isFundraiser) {
            router.push({path: `/quiz/${props.quizData.id}/donate`});
        } else {
            router.push({path: `/quiz/${props.quizData.id}/payment`});
        }
    } else {
        router.push({name: 'play-quiz', params: {quizSlug: props.quizData.slug}});
    }
}

const showForm = (form) => {
    data.value.currentForm = form;
}

onMounted(async () => {
    await setState();
})
</script>
