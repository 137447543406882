<template>
    <div class="panel panel--centered h-full flex flex-col">
        <h4 class="text-md font-bold mb-7 tag">{{ title }}</h4>
        <div class="rankings div-scroll-wrapper overflow-x-auto flex gap-4 pb-2 mt-auto items-end h-52">
            <div v-for="(roundRank, index) in roundRankings" class="h-full flex justify-end items-center flex-col">
                <span class="text-xs font-bold mb-0.5">{{getRankOrdinal(roundRank.position)}}</span>
                <div class="w-12 rounded  mb-0.5"
                     :class="index % 2 === 0 ? 'bg-primary' : 'bg-secondary'"
                     :style="getRoundBarHeightStyle(roundRank.position)"></div>
                <span class="text-xs font-bold mb-0.5">Round {{roundRankings.length - index}}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from "moment/moment";

const props = defineProps({
    title: String,
    participantCount: Number,
    roundRankings: Array
})

const getRoundBarHeightStyle = (rank) => {
    let percentage = (props.participantCount - rank + 1) / props.participantCount * 100;
    return {
        height: `calc(${percentage}% - 15px)`,
    }
}

const getRankOrdinal = (rank) => {
    return rank > 0 ? moment.localeData().ordinal(rank) : 'DNP'
}
</script>
