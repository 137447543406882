import {computed, ref} from "vue";
import api from "../api/axios-api.js";
import {QuizData} from "./quizData.js";
import {useToast} from "vue-toast-notification";

export function QuizAdminActions() {

    const $toast = useToast();

    const {quiz, currentRound, nextRound} = QuizData();

    const currentAction = ref({
        action: '',
        text: ''
    });

    const getCurrentAction = computed(() => {
        return currentRound.value;
    })

    const startRound = async () => {
        try {
            await api.post(`/quiz/${quiz.value.id}/round/${currentRound.value.id}/start`);
        } catch (error) {
            $toast.open({
                message: err.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });
        }
    }

    const resetQuiz = async () => {
        if (confirm('Warning! Are you sure you want to reset this quiz? All participant data and answers will be reset')) {
            try {
                await api.post(`/quiz/${quiz.value.id}/reset`);
            } catch (error) {
                $toast.open({
                    message: err.response.data.message,
                    type: 'error',
                    duration: 100000,
                    dismissible: false
                });
            }
        }
    }
    const cancelRound = async () => {
        try {
            currentAction.value = {
                action: 'cancelRound',
                text: 'Cancelling round...'
            }
            await api.post(`/quiz/${quiz.value.id}/round/${currentRound.value.id}/cancel`)
                .then((response) => {
                    data.value.actionLoading = false;
                    data.value.actionLoadingText = '';
                });
        } catch (error) {
            $toast.open({
                message: err.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });
        }
    }

    const closeRound = async () => {
        try {
            const response = await api.post(`/quiz/${quiz.value.id}/round/${currentRound.value.id}/close`);

            if (response.data.requires_admin) {
                openPowerUpModal(response.data);
            }

        } catch (err) {
            $toast.open({
                message: err.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });
        }
    }

    const loadNextRound = async () => {
        try {
            currentAction.value = {
                action: 'nextRound',
                text: 'Preparing round...'
            }

            const nextRoundId = nextRound.value.id;

            const response = await api.post(`/quiz/${quiz.value.id}/round/${nextRoundId}/load`);

            if (response.data.participant_ids) {
                currentAction.value = {
                    action: '',
                    text: ''
                }
            }
        } catch (err) {
            $toast.open({
                message: err.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });

            currentAction.value = {
                action: '',
                text: ''
            }
        }
    }

    const closeQuiz = async () => {
        try {
            await api.post(`/quiz/${quiz.value.id}/close`);
        } catch (err) {
            $toast.open({
                message: err.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false
            });
        }
    }

    return {
        startRound,
        loadNextRound,
        closeQuiz,
        closeRound,
        cancelRound,
        resetQuiz,
        getCurrentAction
    }
}

