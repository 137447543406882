<template>
    <AccountGuestLayout>
        <RegisterForm @success="handleRegister"/>
    </AccountGuestLayout>
</template>
<script setup>
import router from "../../router/router";
import AccountGuestLayout from "../../layouts/AccountGuestLayout.vue";
import RegisterForm from "../../components/Account/RegisterForm.vue";
import {useRoute} from "vue-router";

const route = useRoute();

const handleRegister = (user, account) => {

    if (account.is_charity && account.charity_onboarding_status === 'pending')  {
        router.push({ name: 'charity-onboard', params: { id: account.id }, query: {redirect: route.query.redirect}});
    } else {
        if (route.query.redirect) {
            router.push(route.query.redirect);
        } else {
            router.push({ name: 'account', params: { id: account.id } });
        }
    }
}
</script>
