<template>
    <Form @submit.prevent="handleSubmit" :error-message="errorMessage">
        <div class="flex items-center sm:space-x-4">
            <CustomSelect class="w-5/12"
                name="categories"
                label="Category"
                :options="categories"
                v-model="data.form.categoryId"
                defaultItemLabel="Choose category"
                :errors="errors"
            />
            <CustomSelect class="w-5/12"
                name="difficulty"
                label="Difficulty"
                :options="difficultyLevels"
                v-model="data.form.difficulty"
                defaultItemLabel="Choose difficulty"
                :errors="errors"
            />
            <button class="w-2/12 btn btn-tertiary btn--large rounded-full" type="submit">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </Form>
</template>

<script setup>
import Form from "../Form/Form.vue";
import {ref} from "vue";
import CustomSelect from "../Form/CustomSelect.vue";

const emit = defineEmits(['search']);

const props = defineProps({
    categories: Array,
    difficultyLevels: Array,
    errors: Array,
    errorMessage: String
});

const data = ref({
    formId: 'questionLibrarySearchForm',
    form: {
        categoryId: null,
        difficulty: null,
    },
});

const handleSubmit = async(e) => {
    e.preventDefault();
    emit('search', data.value.form);
}
</script>

<style scoped>

</style>
