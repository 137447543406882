<template>
    <div class="flex flex-col">
        <div class="mb-12 text-center panel panel--no-shadow border border-grey-200 font-sans mb-2.5">
            <span class="tag text-secondary">Question</span>
            {{ question }}
        </div>

        <Search
            placeholder="Search participants..."
            @search="filterParticipants"
        />

        <ParticipantAnswersTable
            :participant-answers="getFilteredParticipants"
        />
    </div>
</template>
<script setup>
import api from "../../../api/axios-api";
import Search from "../../Global/Search.vue";
import ParticipantAnswersTable from "../Participant/ParticipantAnswersTable.vue";
import {computed, onMounted, ref} from "vue";
import {QuizData} from "../../../composibles/quizData.js";

const {quiz, currentRound} = QuizData();


const props = defineProps({
    question: String,
    questionId: Number,
    answerId: Number
});

const data = ref({
    searchTerm: '',
    participantAnswers: []
})

const  getFilteredParticipants = computed(() => {
    if (data.value.searchTerm.length > 0) {
        return data.value.participantAnswers.filter(a => a.name.toLowerCase().includes(data,value.searchTerm.toLowerCase()));
    } else {
        return data.value.participantAnswers;
    }
});

const getParticipantsFromAnswerId = async () => {
    try {
        const response = await api.get(`/quiz/${quiz.value.id}/round/${currentRound.value.id}/answers`, {
            params: {
                question_id: props.questionId,
                answer_id: props.answerId
            }
        });

        if (response.data?.answers) {
            data.value.participantAnswers = response.data.answers;
        }
    } catch (err) {
        console.error(err);
    }
}

const filterParticipants  = (e) => {
    data.value.searchTerm = e.target.value;
};


onMounted(async () => {
    await getParticipantsFromAnswerId();
})
</script>
