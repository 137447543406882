<template>
    <div class="mb-5 panel panel-xs border border-2 border-secondary relative">
        <div class="relative pb-[75%]">
            <div
                class="tag absolute top-4 left-4 z-10 rounded tag px-1.5 py-1 bg-red-200 flex items-center text-white text-tiny"
                v-if="data.cameraConnected && data.isVisibleLink">
                <span class="relative flex h-2 w-2 mr-1">
                    <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-2 w-2 bg-white"></span>
                </span>
                Live
            </div>
            <video class="absolute w-full h-full inset bg-primary rounded-md" ref="broadcasterRef" muted
                   autoplay></video>
            <div class="group flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 m-auto">
                <button v-if="!data.cameraConnected" @click="connectCamera" class="btn btn-secondary text-white">Connect
                    Camera
                </button>
                <button v-else @click="disconnectCamera" class="btn btn-primary invisible group-hover:visible">
                    Disconnect Camera
                </button>
            </div>
        </div>

        <div v-if="data.cameraConnected || data.isVisibleLink" class="mt-2 flex justify-between items-center">
            <div class="">
                <button v-if="data.cameraConnected && !data.isVisibleLink" class="btn btn-secondary text-white"
                        @click="startStream">Start Broadcast
                </button>
                <button v-else class="btn btn-secondary text-white" @click="disconnectCamera">End Broadcast</button>
            </div>
            <div class="flex items-center text-sm gap-3">
                <button @click="toggleMute" class="flex items-center"
                        :title="data.isMuted ? 'Unmute microphone' : 'Mute microphone'">
                    <i v-if="!data.isMuted" class="fas fa-microphone"></i>
                    <i v-else class="fas fa-microphone-slash"></i>
                </button>
                <span class="flex items-center rounded tag mx-auto inline-block bg-neutral px-1.5 py-1 mb-0"><i
                    class="fas fa-eye mr-1"></i> {{ viewerCount }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>

import {computed, ref} from "vue";
import {getPermissions} from "../../../utils/helpers.js";
import Peer from "simple-peer";
import api from "../../../api/axios-api.js";

const props = defineProps(["quizId", "adminId"])

const data = ref({
    cameraConnected: false,
    isVisibleLink: false,
    streamingPresenceChannel: null,
    streamingAnswerChannel: null,
    streamingUsers: [],
    allPeers: {}, // this will hold all dynamically created peers using the 'ID' of users who just joined as keys
    isMuted: false
});

const broadcasterRef = ref(null);

const streamId = computed(() => {
    return `${props.quizId}`;
});

const streamLink = computed(() => {
    return `http://127.0.0.1:8000/quiz/${this.quizId}/streaming/${data.value.streamId}`;
});

const viewerCount = computed(() => {
    return Object.keys(data.value.allPeers).length ?? 0;
});

const connectCamera = async () => {
    const stream = await getPermissions();
    broadcasterRef.value.srcObject = stream;
    data.value.cameraConnected = true;
    console.log('camera connected');
}


const startStream = async () => {
    cleanupPeers();

    initializeStreamingChannel();

    if (data.value.streamingAnswerChannel === null) {
        initializeSignalAnswerChannel();
    }

    data.value.isVisibleLink = true;
}

const cleanupPeers = () => {
    console.log('removing peers', data.value.allPeers);
    for (const peerId in data.value.allPeers) {
        if (data.value.allPeers.hasOwnProperty(peerId)) {
            const peer = data.value.allPeers[peerId];
            const peerInstance = peer.getPeer(); // Get the Peer instance

            if (peerInstance) {
                peerInstance.destroy(); // Disconnect the Peer connection
            }
        }
    }
    data.value.allPeers = {}; // Reset the allPeers object
}

const disconnectCamera = async () => {
    console.log('disconnecting camera');

    cleanupPeers();

    data.value.streamingUsers = [];

    const stream = broadcasterRef.srcObject;
    if (stream) {
        stream.getTracks().forEach(track => track.stop());
        broadcasterRef.srcObject = null;
    }

    // Leave the presence channel
    Echo.leave(`streaming-channel.${streamId.value}`);
    data.value.streamingPresenceChannel = null;

    data.value.isVisibleLink = false;
    data.value.cameraConnected = false;

    console.log('disconnected camera');
}

const peerCreator = (stream, user, signalCallback) => {
    console.log('creating peer connection for', user.client_id);

    let peer;
    return {
        create: () => {
            peer = new Peer({
                initiator: true,
                trickle: false,
                stream: stream,
                debug: true, // Enable logging
                // config: {
                //     iceServers: [
                //         {
                //             urls: "stun:stun.stunprotocol.org",
                //         },
                //         {
                //             urls: "stun:stun.l.google.com:19302",
                //         },
                //         {
                //             urls: "stun:turnserver.org",
                //         },
                //     ]
                // },
            });
        },
        getPeer: () => peer,
        initEvents: () => {
            peer.on("signal", (data) => {
                console.log('signalling peer connection for', user.client_id);

                // send offer over here.
                signalCallback(data, user);
            });
            peer.on("connect", () => {
                console.log('connected peer connection for', user.client_id);
            });
            peer.on("close", () => {
                console.log('peer closed', user.client_id);
                cleanUpPeer(user);
            });
            peer.on("error", (err) => {
                console.error(err);
            });
        },
    };
}

const cleanUpPeer = (user) => {
    if (data.value.allPeers[user.client_id]) {

        console.log( data.value.allPeers[user.client_id]);

        delete data.value.allPeers[user.client_id];
        console.log('deleting peer', user.client_id);
    }

    const joiningUserIndex = data.value.streamingUsers.findIndex(
        (userData) => userData.client_id === user.client_id
    );

    if (joiningUserIndex > -1) {
        data.value.streamingUsers.splice(joiningUserIndex, 1);
    }
}

const initializeStreamingChannel = () => {
    console.log('initializing stream presence channel');

    data.value.streamingPresenceChannel = Echo.join(
        `streaming-channel.${streamId.value}`
    ).here((users) => {

        // Get all users, apart from the admin
        data.value.streamingUsers = users.filter(user => !user.is_admin);

        console.log('found users in channel', data.value.streamingUsers);

        // Signal offers to all users waiting in lobby
        data.value.streamingUsers.forEach((user) => {
            initializeUser(user);
        });
    }).joining(async (user) => {
        if (user.is_admin) return;

        console.log('new user joined channel', user);

        // if this new user is not already on the call, send your stream offer
        const joiningUserIndex = data.value.streamingUsers.findIndex(
            (data) => data.id === user.id
        );

        if (joiningUserIndex < 0) {
            data.value.streamingUsers.push(user);
            initializeUser(user);
        }
    }).leaving(async (user) => {
        cleanUpPeer(user);
    });
}

const initializeUser = (user) => {
    console.log(
        '%cNEW VIEWER %c',
        'color: blue',
    );

    data.value.allPeers[user.client_id] = peerCreator(
        broadcasterRef.value.srcObject,
        user,
        signalCallback
    );

    // Create Peer
    data.value.allPeers[user.client_id].create();

    // Initialize Events
    data.value.allPeers[user.client_id].initEvents();

    console.log(user.client_id, 'connected');
}

const initializeSignalAnswerChannel = () => {
    data.value.streamingAnswerChannel = true;

    Echo.channel(`stream-signal-channel.${props.quizId}`).listen(
        "StreamAnswer",
        ({data: streamData}) => {

            console.log('stream answer initiated', data.value.allPeers[streamData.client_id])

            console.log("stream data", streamData);

            if (streamData.answer.sdp && streamData.client_id !== null && data.value.allPeers[streamData.client_id]) {

                const updatedSignal = {
                    ...streamData.answer,
                    sdp: `${streamData.answer.sdp}\n`,
                };

                data.value.allPeers[streamData.client_id]
                    .getPeer()
                    .signal(updatedSignal);


                console.log('received stream answer from', data.value.allPeers[streamData.client_id])
            }
        }
    );
}

const signalCallback = async (offer, user) => {
    await api.post(`/quiz/${props.quizId}/stream-offer`, {
        broadcaster: props.quizId,
        receiver: user.client_id,
        offer,
    })
}

const toggleMute = () => {
    data.value.isMuted = !data.value.isMuted;

    // Get the presenter's audio track
    const audioTrack = broadcasterRef.srcObject.getTracks().find(track => track.kind === 'audio');

    // Toggle the audio track enabled/disabled state
    if (audioTrack) {
        audioTrack.enabled = !data.value.isMuted;
    }
}

</script>
