<template>
    <div class="flex gap-4">
        <div class="panel bg-primary text-white mb-8" v-if="round" :class="'flex-grow'">
            <span class="tag text-secondary">Round {{round.order}} / {{quiz.rounds.length}}</span>

            <h2 class="text-xl mb-4">{{round.title}}</h2>

            <ul class="flex flex-wrap gap-4 sm:gap-12 mb-4 mt-4">
                <li v-if="getCountdownDisplayTimer?.date" class="w-full sm:w-auto">
                    <span class="font-bold" v-html="getCountdownDisplayTimer.title"></span>
                    <span class="text-secondary">
                        <CountdownTimer
                            :end-date="getCountdownDisplayTimer.date"
                            :circle="false"
                            :center="false"
                            title=""
                            format="dd\d hh\h mm\m ss\s"
                            type="blank"/>
                </span>
                </li>
                <li class="w-full sm:w-auto"><span class="font-bold">Duration</span><span class="text-secondary text-xl sm:text-1xl font-bold block">{{round.duration}}m</span></li>
                <li class="w-full sm:w-auto"><span class="font-bold">No. of questions</span><span class="text-secondary text-xl sm:text-1xl font-bold block">{{round.questionCount}}</span></li>
                <li v-if="quiz.payToEnter && quiz.entryFee" class="w-full sm:w-auto"><span class="font-bold">Entry fee</span><span class="text-secondary text-xl sm:text-1xl font-bold block">£{{quiz.entryFee}}</span></li>
                <li v-if="quiz.isFundraiser && quiz.totalRaised" class="w-full sm:w-auto"><span class="font-bold">Total Raised</span><span class="text-secondary text-xl sm:text-1xl font-bold block">£{{quiz.totalRaised}}</span></li>
            </ul>
            <div class="flex gap-4 mt-6">
                <button v-if="shouldShowButton" @click="handlePlayNowBtn" class="btn btn-tertiary">
                    <template v-if="hasRoundStarted && isParticipant && quiz.automaticStart">Play</template>
                    <template v-else-if="!isParticipatingInRound && (!hasRoundStarted && currentRound && !quiz.automaticStart) || (quiz.automaticStart)">Enter</template>
                </button>

                <button class="btn btn-outline-white" @click="showHowToPlayModal">
                    How to play
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed} from "vue";
import {RoundData} from "../../../composibles/roundData.js";
import {QuizData} from "../../../composibles/quizData.js";
import CountdownTimer from "../Global/CountdownTimer.vue";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useModal} from "vue-final-modal";
import Modal from "../../Global/Modal.vue";

const {quiz, currentRound} = QuizData();
const {hasRoundStarted, hasRoundEnded, hasCurrentRound, getRoundStartDate, getRoundEndDate} = RoundData();
const {isParticipatingInRound, isParticipant} = ParticipantData();

const emit = defineEmits(['play']);

const props = defineProps({
    round: {
        type: Object,
        default: () => {},
    }
});

const getCountdownDisplayTimer = computed(() => {
    if (hasCurrentRound.value && getRoundEndDate.value !== false) {
        return {
            title: 'Round ends in:',
            date: getRoundEndDate.value
        }
    } else if (getRoundStartDate.value !== false) {
        return {
            title: 'Round starts in:',
            date: getRoundStartDate.value
        }
    }

    return {}
});

const shouldShowButton = computed(() => {
    if (quiz.value.automaticStart) {
        return hasRoundStarted.value && !hasRoundEnded.value;
    } else {
        return hasCurrentRound.value && !isParticipatingInRound.value && !hasRoundStarted.value && !hasRoundEnded.value;
    }
});

const handlePlayNowBtn = () => {
    emit('play');
}

const showHowToPlayModal = () => {
    const { open } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel',
            adaptive: true, draggable: false, scrollable: true, height: 'auto'
        },
        slots: {
            default: quiz.value.meta.how_to_play,
        },
    });
    open();
}
</script>
