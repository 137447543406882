import {defineStore} from "pinia";
import api from "../api/axios-api.js";

export const useEditRoundQuestions = defineStore('roundQuestions', {
    state: () => ({
        questions: [
            {
                id: null,
                question: '',
                upload: null,
                answers: [
                    {
                        answer: '',
                        is_correct: false,
                    },
                    {
                        answer: '',
                        is_correct: false,
                    },
                ],
            }
        ],
    }),
    getters: {
        getQuestions(state) {
            return state.questions.filter(x => x.id !== null);
        },
    },
    actions: {
        setQuestions(payload) {
            payload.forEach(question => {
                const questionObj = {
                    id: question.id,
                    question: question.question,
                    answers: question.answers,
                    upload: question.upload
                }
                this.questions.push(questionObj);
            });
        },
        addQuestion(payload) {
            const questionObj = {
                id: payload.id,
                question: payload.question,
                answers: payload.answers ?? [],
                upload: payload.upload ?? null
            }

            console.log(questionObj);

            this.questions.push(questionObj);
        },
        removeQuestion(payload) {
            this.questions.splice(payload, 1);
        },
        clearQuestions() {
            this.questions = [
                {
                    id: null,
                    question: '',
                    upload: null,
                    answers: [
                        {
                            answer: '',
                            is_correct: false,
                        },
                        {
                            answer: '',
                            is_correct: false,
                        },
                    ],
                }
            ]
        },
        async deleteQuestion(quizId, roundId, questionId) {
            const response = await api.delete('/quiz/' + quizId  + '/round/' + roundId + '/question/' + questionId);
            if (response.data.success) {
                const questionKey = this.getQuestions.indexOf(question => question.id === questionId);
                this.removeQuestion(questionKey);
            }
        }
    },
});
