<template>
    <div>
        <Notice v-if="errorMessage" :message="errorMessage" notice-type="error">
            {{errorMessage}}
        </Notice>
        <div v-else>
            <div v-if="isTestMode" class="bg-red-100 border border-red-200 p-4 rounded-sm text-center">
                <h3 class="text-red-200 mb-3 font-bold">TEST MODE ENABLED!</h3>
                <p class="text-xs mb-3 font-bold">Please use the following test card number for testing:</p>
                <p class="text-xs mb-0.5"><strong>Card Number:</strong> 4242 4242 4242 4242</p>
                <p class="text-xs mb-0.5"><strong>Expiry Date:</strong> Any future date</p>
                <p class="text-xs mb-0.5"><strong>CVC:</strong> Any 3-digit number</p>
            </div>
            <div id="card-element" ref="cardElement" class="p-4 border border-grey-200 rounded-md my-3"></div>
        </div>
    </div>
</template>

<script>
import {loadStripe} from "@stripe/stripe-js";
import api from "../../api/axios-api";
import Notice from "../Form/Notice.vue";

export default {
    components: {Notice},
    props: {
        paymentData: {
            type: Object,
            default: () => {}
        },
        paymentIntentId: {
            type: String,
            default: ""
        },
        returnUrl: {
            type: String,
            default: `${window.location.origin}/return`
        },
    },
    data() {
        return {
            stripe: null,
            elements: null,
            cardElement: null,
            paymentMethod: null,
            error: false,
            isTestMode: false,
            errorMessage: ''
        };
    },
    beforeMount() {
        this.isTestMode = import.meta.env.VITE_STRIPE_TEST_MODE;
    },
    mounted() {
        this.initializeStripe();
    },
    methods: {
        initializeStripe() {
            loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
                stripeAccount: this.paymentData.accountId
            }).then((stripe) => {
                api.post('/get-or-create-payment-intent', {
                    payment_data: this.paymentData
                }).then((response) => {
                    let clientSecret = response.data.client_secret;
                    if (clientSecret) {
                        this.stripe = stripe;
                        this.elements = stripe.elements({clientSecret});
                        this.cardElement = this.elements.create('payment');
                        this.cardElement.mount(this.$refs.cardElement);
                    }
                }).catch((err) => {
                    this.errorMessage = err.response.data.message;
                });
            });
        },
        async createPaymentMethod() {
            const elements = this.elements;

            const {paymentMethod, error} = await this.stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: this.returnUrl
                },
            });

            if (error) {
                this.error = error;
            } else {
                this.paymentMethod = paymentMethod;
            }
        },
        resetError() {
            this.error = null;
        },
    },
};
</script>
