<script setup>

import {onMounted, ref} from "vue";
import api from "../../api/axios-api.js";
import {useRoute} from "vue-router";
import LoadingButton from "../Global/LoadingButton.vue";

const isLoading = ref(false);
const statusChanging = ref(false);
const fundraisers = ref([]);
const route = useRoute();

const getFundraisers = async () => {
    try {
        isLoading.value = true;

        const response = await api.get('/fundraisers', {
            params: {
                account_id: route.params.id
            }
        });

        if (response.data.fundraisers) {
            fundraisers.value = response.data.fundraisers;
        }
    } catch (err) {

    } finally {
        isLoading.value = false;
    }
}

const setStatus = async (id, status) => {
    try {
        statusChanging.value = id;

        const response = await api.post(`/fundraiser/${id}/verify` , {
            account_id: route.params.id,
            status: status
        });

        if (response.data.fundraiser) {
            const index = fundraisers.value.findIndex((f) => {
                return f.id === response.data.fundraiser.id;
            });

            if (index !== -1) {
                fundraisers.value[index].status = response.data.fundraiser.status;
            }
        }
    } catch (err) {

    } finally {
        statusChanging.value = false;
    }
}

onMounted(async () => {
    await getFundraisers();
});
</script>

<template>
    <div class="panel">
        <h1 class="text-xl">Fundraisers</h1>
        <div v-if="isLoading">
            <FadeLoader
                :loading="isLoading"
                color="#E8E7EB"
            />
        </div>
        <div v-else-if="fundraisers.length > 0">
            <div v-for="fundraiser in fundraisers" class="items-center mb-2 bg-grey-100 rounded px-5 py-3.5 font-bold text-sm flex flex-wrap md:flex-nowrap items-center mb-2">
                <span class="w-full md:w-5/12 mb-2 md:mb-0">
                    {{fundraiser.account.admin.email}}
                </span>
                <span class="w-6/12 md:w-2/12">{{fundraiser.status}}</span>
                <span class="w-6/12 flex-1 justify-end md:w-5/12 flex gap-2">
                    <LoadingButton :is-loading="statusChanging === fundraiser.id" v-if="fundraiser.status === 'pending' || fundraiser.status === 'rejected'" @click="setStatus(fundraiser.id, 'approved')" class="btn btn-primary">Approve</LoadingButton>
                    <LoadingButton :is-loading="statusChanging === fundraiser.id" v-if="fundraiser.status !== 'rejected'" @click="setStatus(fundraiser.id, 'rejected')" class="btn btn-red">Reject</LoadingButton>
                </span>
            </div>
        </div>
        <p v-else>
            No fundraisers found.
        </p>
    </div>
</template>

<style scoped>

</style>
