<template>
    <div class="panel">
        <h1 class="text-xl">Upgrade Plan</h1>

        <div class="mt-8">
            <p v-if="getUsersCurrentPlan.length > 0" class="mb-4">You are currently on the <span class="font-bold">{{getUsersCurrentPlan}}</span> plan</p>
            <button type="button" class="btn btn-primary" disabled>Upgrade (Coming soon)</button>
        </div>
    </div>
</template>
<script setup>

import {useUserStore} from "../../stores/user";

const {getUser} = useUserStore();

const getUsersCurrentPlan = () => {
    if (this.getUser !== null && this.getUser?.roles.length > 0) {
        return this.getUser?.roles[0]?.name;
    }
    return '';
}
</script>
