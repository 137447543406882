<template>
    <span class="flex items-center justify-center text-center w-5 h-5 rounded-full bg-secondary text-xs text-white hover:cursor-pointer" @mouseover="showToolTip = true" @mouseleave="showToolTip = false">
         <span class="absolute bg-primary min-w-[60px] bottom-full rounded-lg px-0.75 py-1" v-if="showToolTip">
            <span class="border-solid border-t-primary border-t-4 border-x-transparent border-x-4 border-b-0 absolute top-full left-1/2 -translate-x-1/2"></span>
            {{name}}
         </span>
        {{getNameInitial(name)}}
    </span>
</template>

<script setup>
import {ref} from "vue";
import {useHelpers} from "../../../composibles/helpers.js";

const {getNameInitial} = useHelpers();

const props = defineProps({
    name: String
});

const showToolTip = ref(false);

</script>

<style scoped>

</style>
