<template>
    <router-view></router-view>
    <ModalsContainer />
</template>
<script setup>
import { ModalsContainer } from 'vue-final-modal'

import {useConfigStore} from "../stores/config.js";
import {onMounted} from "vue";

const configStore = useConfigStore();

onMounted(async () =>{
    await configStore.getConfig();
})
</script>
