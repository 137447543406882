<template>
    <div class="panel">
        <div class="flex justify-between">
            <h1 class="text-xl mb-4">Accept Payments</h1>
        </div>
        <div v-if="isLoading" class="flex flex-col min-h-[36rem] justify-center items-center">
            <FadeLoader
                :loading="isLoading"
                color="#E8E7EB"
            />
        </div>
        <div v-else>
            <div v-if="connectedAccount">
                <h3 class="mb-2">Connected Stripe Account</h3>
                <div class="panel panel--no-shadow border border-grey-200 font-sans mb-2.5 flex justify-between">
                    {{connectedAccount.email}}
                    <span class="flex">
                    <span v-if="connectedAccount.details_submitted" class="flex items-center">
                        <span class="tag bg-green-200 rounded px-1.5 py-1 items-center text-tiny text-center mb-0 text-white mr-4">Connected</span>
                    </span>
                    <span v-else>
                       <button class="tag bg-grey-300 rounded px-1.5 py-1 items-center text-tiny text-center mb-0 text-white" @click="continueSetup(accountId)">Complete Setup</button>
                    </span>
                    <button @click="deleteAccount" class="text-sm text-red-200 font-bold underline">Disconnect</button>
                    </span>
                </div>
            </div>
            <Form @submit="handleSubmit" :id="id" v-else>
                <div class="flex flex-col items-center">
                    <button type="submit"
                            class="btn btn-secondary text-white"
                            :disabled="isProcessing"
                            :class="isProcessing ? 'btn--is-loading' : ''">

                        <span class="loader" v-if="isProcessing"></span>
                        Register Platform Account
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import api from "../../api/axios-api";
import Form from "../Form/Form.vue";

export default {
    components: {Form},
    data() {
        return {
            id: 'AcceptPaymentsForm',
            accountId: null,
            connectedAccount: null,
            isLoading: false,
            isProcessing: false
        }
    },
    mounted() {
        this.getConnectedAccount();
    },
    methods: {
        getConnectedAccount: function () {
            this.isLoading = true;
            api.get('/stripe/connected-account')
                .then(response => {
                    if (response.data.success && response.data?.account?.email) {
                        this.connectedAccount = response.data.account;
                        this.accountId = response.data.id;
                    }
                })
                .catch(error => {
                   this.setFormErrors(error);
                }).finally(() => {
                    this.isLoading = false;
            })
        },
        deleteAccount: async function () {
            if(confirm('Are you sure you want to delete?')) {
                try {
                    const response = await api.delete('/stripe/connected-account');
                    if (response.data.success) {
                        this.connectedAccount = null;
                    }
                } catch (err) {

                }
            }
        },
        continueSetup: function (id) {
            if (id) {
                this.handleOnBoard(id);
            }
        },
        handleSubmit: function (e) {
            this.isProcessing = true;
            e.preventDefault();
            api.post('/stripe/connected-account')
                .then(response => {
                    if (response.data.success) {
                        let connectedAccountId = response.data.id;
                        this.handleOnBoard(connectedAccountId);
                    }
                })
                .catch(error => {
                    this.setFormErrors(error);
                }).finally(() => {
                    this.isProcessing = false;
                })
        },
        handleOnBoard: function (id) {
            api.post(`/stripe/connected-account/${id}/onboard`, {
                'returnURL': window.location.href,
                'refreshURL': window.location.href
            })
            .then(response => {
                if (response.data.url) {
                    window.location.replace(response.data.url);
                }
            });
        }
    }
}
</script>
