<template>
    <header class="bg-white mb-8 sm:mb-12 border-b border-grey-200">
        <div class="container mx-auto">
            <div class="py-5 flex flex-wrap items-center justify-between">
                <router-link
                    class="nav-link flex items-start gap-1"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">
                    <img alt="Quick As You Can" src="../../img/logo.svg" class="w-32">
                    <span class="tag rounded ml-1 text-tiny inline-block px-1 py-0.5 bg-tertiary">
                        beta
                    </span>
                </router-link>

                <nav class="hidden lg:inline-block flex-initial justify-self-end">
                    <ul class="sans flex flex-wrap items-center">
                        <router-link
                                     :to="{ name: 'about' }"
                                     class="btn btn--large px-4"
                        >
                           About Us
                        </router-link>

                        <router-link
                            :to="{ name: 'quizzes' }"
                            class="btn btn--large px-4 mr-4"
                        >
                            Quizzes
                        </router-link>

                        <router-link v-if="userStore.getUser !== null"
                            :to="{ name: 'account', 'params': {id: userStore.getUser?.account?.id} }"
                            class="w-10 h-10 bg-primary rounded-full flex items-center justify-center"
                        >
                            <i class="fas fa-user text-white"></i>
                        </router-link>
                        <router-link v-else
                                     :to="{ name: 'login' }"
                                     class="btn btn--large btn-tertiary"
                        >
                            Get Started
                        </router-link>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script setup>
    import {useUserStore} from "../stores/user";

    const userStore = useUserStore();
</script>

<style scoped>

</style>
