<template>
   <div>
       <div class="panel">
           <div v-if="data.isLoading" class="flex flex-col min-h-[36rem] justify-center items-center">
               <FadeLoader
                   :loading="data.isLoading"
                   color="#E8E7EB"
               ></FadeLoader>
           </div>
           <div v-else-if="data.quiz !== null">
               <div class="flex items-end mb-8">
                   <h1 class="text-xl">
                       {{ data.quiz.title }}
                   </h1>
                   <router-link
                       :to="{name: 'account/my-quizzes/edit-quiz/info', params: { quizId: data.quiz.id }}"
                       class="text-grey-300 ml-2">
                       <i class="far fa-edit"></i> Edit
                   </router-link>
               </div>
               <Form :id="data.id" :error-message="errorMessage">
                   <div class="mb-8">
                       <h2 class="tag">Rounds ({{roundsCount}})</h2>
                           <transition-group>
                               <div class="flex justify-between items-center mb-2 bg-grey-100 rounded px-5 py-3.5 font-bold text-sm"
                                    :class="roundHasError(round.id) ? 'border border-red-200' : ''"
                                    v-for="(round, round_index) in data.quiz.rounds" :key="round.id">

                                   <div class="cursor-grab mr-4">
                                       <i class="fas fa-grip-vertical text-grey-300"></i>
                                   </div>

                                   <div class="w-4/12"><router-link :to="{ name: 'account/my-quizzes/edit-round', params: { quizId: data.quiz.id, roundId: round.id }}">{{round.title}}</router-link></div>

                                   <div class="w-5/12 flex items-center gap-4 items-center">
                                       <div :class="getStatusClass(round.status)" class="w-4/12">
                                           {{getStatus(round.status)}}
                                       </div>
                                       <div v-if="round.status === 'active' && round.end_date !== null" class="flex text-xs font-normal">
                                           Ends:<br>
                                           {{formattedDateAndTime(round.end_date)}}
                                       </div>
                                       <div v-else-if="round.status === 'scheduled' && round.start_date !== null" class="flex text-xs font-normal">
                                           Scheduled for:<br>
                                           {{formattedDateAndTime(round.start_date)}}
                                       </div>
                                       <div v-else-if="round.end_date !== null" class="flex text-xs font-normal">
                                           Closed:<br>
                                           {{formattedDateAndTime(round.end_date)}}
                                       </div>
                                   </div>

                                   <div class="w-4/12 flex items-center gap-0.25">
                                       <router-link :to="{ name: 'account/my-quizzes/view-questions', params: { quizId: data.quiz.id, roundId: round.id }}" class="btn btn-outline-secondary mr-2">
                                           <template v-if="round.question_count > 0">Edit</template><template v-else>Add</template> Questions
                                       </router-link>

                                       <router-link :to="{ name: 'account/my-quizzes/edit-round', params: { quizId: data.quiz.id, roundId: round.id }}">
                                           <button type="button" class="ml-2 bg-primary text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                               <i class="fas fa-cog" aria-label="Edit quiz"></i>
                                           </button>
                                       </router-link>

                                       <button type="button" @click="deleteRound(round.id, round_index)"
                                               class="ml-2 bg-red-200 text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                           <i class="far fa-trash-alt" aria-label="Delete Round"></i>
                                       </button>
                                   </div>
                               </div>
                           </transition-group>
                       <div class="flex items-center justify-center" v-if="data.quiz.status !== 'completed'">
                           <router-link :to="{ name: 'account/my-quizzes/add-round', params: { quizId: data.quiz.id }}" class="btn btn-primary">
                               <i class="fas fa-plus mr-1"></i> Add Round
                           </router-link>
                       </div>
                   </div>
                   <div class="flex flex-wrap mt-16 border-t border-grey-200 pt-8 -ml-8 -mr-8 pr-8 pl-8">

                       <div class="flex items-center flex-1 md:flex-0 gap-2">
                           <LoadingButton type="button" v-if="(data.quiz.status !== 'draft' && data.quiz.status !== 'pending_approval' && data.quiz.status !== 'completed') && data.quiz.rounds && data.quiz.rounds.length > 0 && !data.quiz.automatic_start"
                                   @click="launchQuiz"
                                   class="w-full mb-2 md:mb-0 md:w-auto btn btn-tertiary">
                               <i class="fas fa-rocket mr-1"></i>
                               Launch
                           </LoadingButton>
                           <LoadingButton v-else-if="data.quiz.status === 'draft' || data.quiz.status === 'approved'"
                                         :is-loading="data.isPublishing"
                                          @click="publishQuiz"
                                          class="w-full mb-2 md:mb-0 md:w-auto btn btn-tertiary text-primary">
                               <i class="fas fa-rocket mr-1"></i>
                               Publish
                           </LoadingButton>
                           <template v-else-if="data.quiz.status === 'pending_approval'">
                               <div class="text-center tag text-white items-center rounded ml-1 inline-block px-4 py-2 mb-0 bg-red-200">
                                   Pending Approval
                               </div>
                               <span class="block text-tiny">Charity must approve this quiz.</span>
                           </template>
                       </div>

                       <div class="md:ml-auto flex gap-2">
                           <button type="button" @click="shareUrl" ref="shareBtnRef" class="btn btn-grey-200">
                               <i class="fas fa-clipboard mr-2"></i><span>Copy Share URL</span>
                           </button>
                           <router-link :to="{ name: 'preview-quiz', params: { quizSlug: data.quiz.slug }}"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="btn btn-primary">
                               <i class="far fa-eye mr-1" aria-label="View quiz"></i> View quiz
                           </router-link>
                           <router-link :to="{ name: 'account/my-quizzes'}" class="btn btn-primary">
                               <i class="fas fa-save mr-1"></i> <template v-if="data.quiz.status !== 'draft'">Save</template><template v-else>Save Draft</template>
                           </router-link>
                       </div>
                   </div>
               </Form>
           </div>
       </div>
   </div>
</template>
<script setup>
import router from "../../../router/router";
import api from "../../../api/axios-api";
import Form from "../../Form/Form.vue";
import LoadingButton from "../../Global/LoadingButton.vue";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useHelpers} from "../../../composibles/helpers.js";
import {useToast} from "vue-toast-notification";
import {useFormData} from "../../../composibles/formData.js";

const route = useRoute();

const {formattedDateAndTime} = useHelpers();

const $toast = useToast();

const props = defineProps({
    editing: {
        type: Boolean,
        default: false
    },
});

const {setFormErrors, errors, errorMessage} = useFormData();

const data = ref({
    id: 'ViewQuiz',
    quiz: null,
    roundIdErrors: [],
    isLoading: false,
    isPublishing :false,
    shareBtnTextTimeout: null
});

const shareBtnRef = ref(null);

const roundsCount = computed(() => {
    return data.value.quiz?.rounds?.length || 0;
});

const getShareURL = computed(() => {
    return `${baseUrl}/quiz/${data.value.quiz.slug}`;
});

const getStatus = (status) => {
    switch(status) {
        case 'active' :
            return 'Active';
        case 'closed' :
            return 'Closed';
        case 'scheduled' :
            return 'Scheduled';
    }
}

const getStatusClass = (status) => {
    let classes = 'tag rounded px-1.5 py-1 items-center text-tiny text-center mb-0 ';
    switch (status) {
        case 'active' :
            classes += 'bg-green-200 text-white';
            break;
        case 'scheduled' :
            classes += 'bg-grey-300 text-white';
            break;
        case 'closed' :
            classes += 'bg-red-200 text-white';
            break;
    }

    return classes;
}

const roundHasError = (roundId) => {
    return data.value.roundIdErrors.includes(roundId)
}

const deleteRound = async (roundId, roundIndex) => {
    if (confirm("Do you really want to delete?")){
        try {
            const response = await api.delete('/quiz/'+ route.params.quizId + '/round/' + roundId)
            if (response.data.success) {
                data.value.quiz.rounds.splice(roundIndex, 1);
            }
        } catch (error) {
            setFormErrors(error);
        }
    }
}

const loadQuiz = async () => {
    data.value.isLoading = true;
    try {
        const response = await api.get('/quiz/' + route.params.quizId);
        if (response.data.success && response.data.quiz) {
            data.value.quiz = response.data.quiz;
        }
    } catch (err) {
        setFormErrors(err);
    } finally {
        data.value.isLoading = false;
    }
}
const publishQuiz = async () => {
    data.value.isPublishing = true;

    try {
        const response = await api.patch('/quiz/' + route.params.quizId, {
            status: 'published'
        });

        if (response.data.success) {
            data.value.quiz.status = response.data.quiz.status;

            $toast.open({
                message: 'Quiz published',
                type: 'success',
                duration: 1000,
            });
        }
    } catch(err) {
        setFormErrors(err);
    } finally {
        data.value.isPublishing = false;
    }
}

const launchQuiz = async () => {
    try {
        const response = await api.post('/quiz/' + route.params.quizId + '/launch');

        if (response.data.success) {
            if (response.data.automatic_start) {
                await router.push({name: 'play-quiz', params: {quizSlug: data.value.quiz.slug}});
            } else {
                await router.push({name: 'launch-quiz', params: {quizId: data.value.quiz.id}});
            }
        }
    } catch (err) {
        if (err.response.round_ids) {
            data.value.roundIdErrors = err.response.data.round_ids;
        }
        setFormErrors(err);
    }
}
const shareUrl = () => {
    navigator.clipboard.writeText(getShareURL.value);

    let shareBtn = shareBtnRef.getElementsByTagName('span')[0];
    let originalText = shareBtn.innerText;

    shareBtn.innerText = 'Copied!';

    if (data.value.shareBtnTextTimeout ) {
        clearTimeout(data.value.shareBtnTextTimeout);
    }

    data.value.shareBtnTextTimeout = setTimeout(() => {
        shareBtn.innerText = originalText;
    }, 1000);
}

onMounted(async () => {
    await loadQuiz();
});

onBeforeUnmount(() => {
    if (data.value.shareBtnTextTimeout) {
        clearTimeout(data.value.shareBtnTextTimeout);
    }
})
</script>
