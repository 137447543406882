<template>
    <div class="panel">
        <template v-if="getRoundLoadedTime !== false && getRoundStartDate !== false">
            <CountdownTimer
                title="Round starting in"
                :start-date="getRoundLoadedTime"
                :end-date="getRoundStartDate"
                :primary-colour="getQuizThemeColours.primary"
                :secondary-colour="getQuizThemeColours.secondary"
            />
            <div class="text-center">
                <button v-if="quiz.automaticStart" @click="cancelTimer" class="btn btn-grey-200">
                    Cancel
                </button>
            </div>
        </template>

        <template v-else>
            <div class="flex flex-col items-center mb-4 sm:mb-7">
                <h3 class="text-md font-bold mb-7 tag">
                    <template v-if="!isAdmin">
                        <template v-if="!quiz.automaticStart">Please wait for the host to start</template>
                        <template v-else>Please wait for the quiz to start</template>
                    </template>
                    <template v-else>Waiting for players to join</template>
                </h3>
                <ParticipantCount
                    :participant-count="getScoreboardCount"
                />
            </div>
            <div ref="participants" class="relative before:block before:absolute before:bottom-0 before:bg-gradient-to-t before:from-white before:w-full before:h-1/5">
                <div @scroll="handleTableScroll" class="max-h-[568px] overflow-y-scroll table-scroll-wrapper pr-3">
                    <TransitionGroup name="list" tag="ul" v-if="getScoreboardCount > 0">
                        <li :key="index"
                            v-for="(participant, index) in getRoundParticipants"
                            class="border p-2.5 rounded text-sm font-bold font-sans mb-2.5 flex justify-between items-center border-grey-200">
                                {{participant?.name}}
                        </li>
                    </TransitionGroup>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import CountdownTimer from "../Global/CountdownTimer.vue";
import ParticipantCount from "./ParticipantCount.vue";
import {QuizData} from "../../../composibles/quizData.js";
import {RoundData} from "../../../composibles/roundData.js";
import {useParticipantStore} from "../../../stores/participant.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {computed} from "vue";

const participantStore = useParticipantStore();

const {quiz, currentRound, getQuizThemeColours} = QuizData();
const {getRoundParticipants, getRoundStartDate, getRoundLoadedTime} = RoundData();
const {participant} = ParticipantData();

const props = defineProps({
    isAdmin: {
        type: Boolean,
        default: false
    }
})

const getScoreboardCount = computed(() => {
    return getRoundParticipants.value?.length ?? 0
});

const cancelTimer = async () => {
    if (quiz.value.automaticStart) {
        await participantStore.deleteRoundParticipant({
            quizId: quiz.value.id,
            roundId: currentRound.value.id,
            participantId: participant.value.id
        });

    } else if (props.isAdmin) {
        // Cancel round timer
    }
}

const handleTableScroll = (e) => {
    const tableWrapper  = e.target;
    const scrollTop    = tableWrapper.scrollTop;
    const scrollHeight = tableWrapper.scrollHeight;
    const clientHeight = tableWrapper.clientHeight;
    const participantsRef = this.$refs.participants;

    if (scrollTop + clientHeight >= scrollHeight) {
        participantsRef.classList.add('before:hidden');
    } else {
        participantsRef.classList.remove('before:hidden');
        participantsRef.classList.add('before:visible');
    }
}
</script>
