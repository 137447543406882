<template>
    <div>
        <div ref="leaderboardRef"
             class="relative before:block before:absolute before:bottom-0 before:bg-gradient-to-t before:from-white before:w-[95%] before:h-1/5">
            <div v-if="data.isLoading" class="flex justify-center items-center h-96 w-full">
                <FadeLoader
                    :loading="data.isLoading"
                    color="#E8E7EB"
                >
                </FadeLoader>
            </div>
            <div v-else-if="typeof results !== 'undefined'" @scroll="handleTableScroll" class="table-scroll-wrapper overflow-auto mb-8 h-96 pb-12 pr-2 sm:pr-8">
                <table style="border-spacing: 0 5px;"
                       class="w-full text-left rounded-md bg-white shadow-sm border-separate">
                    <thead
                        class="text-tiny sm:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 bg-white">
                    <th scope="col" class="sm:p-2.5 pt-0 sm:w-1/12">#</th>
                    <th scope="col" class="sm:p-2.5 pt-0 sm:w-8/12">
                        Name
                    </th>
                    <th scope="col" class="sm:p-2.5 pt-0 sm:w-1/12">
                        Score
                    </th>
                    <th scope="col" class="sm:p-2.5 pt-0 sm:w-2/12">
                        Time
                    </th>
                    </thead>
                    <tbody>
                        <tr v-for="(result, index) in results" ref="resultRows" :data-participant-id="result.id">
                            <td class="px-1 py-1.5 sm:p-2.5 rounded-bl rounded-tl border-l border-t border-b"
                                :class="participantId === result.id ? 'bg-neutral border-secondary' : 'border-grey-200'">
                                <div class="flex items-center ml-1">
                                    <ScoreTrend
                                        :trend="result.trend"/>
                                    <span style="width:25px;"
                                          class="inline-block align-bottom overflow-x-hidden rounded border py-0.5 text-tiny sm:text-xs sm:mr-4 text-center font-bold"
                                          :class="participantId === result.id ? 'bg-secondary border-secondary text-white' : 'border-grey-200 bg-grey-200'">
                                        {{ index + 1 }}
                                    </span>
                                </div>
                            </td>
                            <td class="px-1 py-1.5 sm:p-2.5 text-xs sm:text-sm font-bold font-sans  border-t border-b"
                                :class="participantId === result.id ? 'bg-neutral border-secondary' : 'border-grey-200'">
                                {{ result.name }}
                            </td>
                            <td class="px-1 py-1.5 sm:p-2.5 text-xs sm:text-sm font-bold font-sans  border-t border-b"
                                :class="participantId === result.id ? 'bg-neutral border-secondary' : 'border-grey-200'">
                                {{ result.correct_answer_count }}
                            </td>
                            <td class="px-1 py-1.5 sm:p-2.5 text-xs sm:text-sm font-bold font-sans  rounded-br rounded-tr border-r border-t border-b  border-grey-200"
                                :class="participantId === result.id ? 'bg-neutral border-secondary' : 'border-grey-200'">
                                <span v-if="result.total_time !== false">{{ result.total_time }}</span>
                                <span v-else>DNF</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button v-if="shouldShowBackToTopBtn" type="button" class="absolute text-sm border-4 border-white text-white -top-[40px] mb-4 -right-[13px] w-8 h-8 bg-primary hover:bg-secondary hover:text-primary transition-all rounded-full flex items-center justify-center"
                        @click="leaderboardRef.querySelector('.table-scroll-wrapper').scrollTo({top: 0, behavior: 'smooth'})">
                    <i class="fas fa-chevron-up"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, ref, watch} from "vue";
import ScoreTrend from "../Participant/Results/ScoreTrend.vue";

const props = defineProps({
    title: String,
    results: Array,
    participantId: Number,
    isLoading: Boolean,
});

const data = ref({
    scrollPos: 0
});

const leaderboardRef = ref(null);

const shouldShowBackToTopBtn = computed(() => {
    return data.value.scrollPos > 0;
});

const handleTableScroll = (e) => {
    const tableWrapper = e.target;

    data.value.scrollPos = tableWrapper.scrollTop;

    const scrollHeight = tableWrapper.scrollHeight;
    const clientHeight = tableWrapper.clientHeight;

    if (data.value.scrollPos + clientHeight >= scrollHeight) {
        leaderboardRef.classList.add('before:hidden');
    } else {
        leaderboardRef.classList.remove('before:hidden');
        leaderboardRef.classList.add('before:visible');
    }
}
const maybeScrollToCurrentParticipant = () => {
    if (!props.participantId) return;

    const offset = 85;

    setTimeout(() => {
        if (leaderboardRef !== null) return;
        const participantResultRow = leaderboardRef.querySelector('tr[data-participant-id="'+ props.participantId.toString() +'"]');
        if (participantResultRow) {
            leaderboardRef.querySelector('.table-scroll-wrapper').scrollTop = participantResultRow.offsetTop - offset;
        }
    }, 100); // Adjust the delay as needed
}

watch(
    () => props.isLoading,
    (newValue, oldValue) => {
        if (oldValue === true && newValue === false) {
            maybeScrollToCurrentParticipant();
        }
    }
);

</script>
