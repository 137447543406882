import {onBeforeUnmount} from "vue";

export function QuizThemeData() {

    const getContrast = (hexcolor1, hexcolor2) => {
        if (!hexcolor1 || !hexcolor2) return;

        const r1 = parseInt(hexcolor1.substring(1, 3), 16);
        const g1 = parseInt(hexcolor1.substring(3, 5), 16);
        const b1 = parseInt(hexcolor1.substring(5, 7), 16);
        const r2 = parseInt(hexcolor2.substring(1, 3), 16);
        const g2 = parseInt(hexcolor2.substring(3, 5), 16);
        const b2 = parseInt(hexcolor2.substring(5, 7), 16);

        // Calculate the relative luminance of the colors
        const l1 = 0.2126 * Math.pow(r1 / 255, 2.2) +
            0.7152 * Math.pow(g1 / 255, 2.2) +
            0.0722 * Math.pow(b1 / 255, 2.2);
        const l2 = 0.2126 * Math.pow(r2 / 255, 2.2) +
            0.7152 * Math.pow(g2 / 255, 2.2) +
            0.0722 * Math.pow(b2 / 255, 2.2);

        // Calculate the contrast ratio between the colors
        const ratio = (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);

        return ratio.toFixed(2);
    }

    const getNeutralColour = (colour) => {
        if (!colour) return '';
        const opacity = 0.2;
        const red = parseInt(colour.substring(1, 3), 16);
        const green = parseInt(colour.substring(3, 5), 16);
        const blue = parseInt(colour.substring(5, 7), 16);
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }

    const setBrandedStyles = (colours) => {
        if (!colours.primary && !colours.secondary && !colours.accent) return;

        const style = document.createElement('style');
        const primaryColour = colours.primary  ?? '#fffff';
        const secondaryColour = colours.secondary ?? '#fffff';
        const accentColour = colours.accent  ?? '#fffff';

        const neutralColour = getNeutralColour(secondaryColour);
        const primaryTextColour = getContrast('#fafafa', primaryColour) < 2 ? '#1a1a1a' : '#fafafa';
        const secondaryTextColour = getContrast('#fafafa', secondaryColour) < 2 ? '#1a1a1a' : '#fafafa';
        const tertiaryTextColour = getContrast('#fafafa', accentColour) < 2 ? '#1a1a1a' : '#fafafa';
        const signOnScreenBgColour = colours?.sign_in_screen_bg_colour;
        const signOnScreenBgImage = colours?.sign_on_screen_bg_image;

        style.innerHTML = `
                    body {
                       background-color: ${signOnScreenBgColour}!important;
                       background-image: ${signOnScreenBgImage !== undefined ? signOnScreenBgImage : 'none'}!important;
                    }
                     .login-screen {
                        background-image:none!important;
                    }
                    .bg-primary, .btn-primary, .sm\\:bg-primary {
                        background-color: ${primaryColour}!important;
                        color: ${primaryTextColour}!important;
                    }
                    .bg-secondary, .btn-secondary, .sm\\:bg-secondary {
                        background-color: ${secondaryColour}!important;
                        color: ${secondaryTextColour}!important;
                    }
                    .bg-tertiary, .btn-tertiary, .sm\\:bg-tertiary {
                        background-color: ${accentColour}!important;
                        color: ${tertiaryTextColour}!important;
                    }
                    .bg-neutral, .btn-neutral, .sm\\:bg-neutral {
                        background-color: ${neutralColour}!important;
                    }
                    .md\\:hover\\:bg-neutral:hover {
                        background-color: ${neutralColour}!important;
                    }
                    .bg-white {
                        color: #1a1a1a!important;
                    }
                    .peer:checked ~ .peer-checked\\:bg-neutral {
                        background-color: ${neutralColour}!important;
                    }
                    .border-tertiary {
                        border-color: ${accentColour}!important;
                    }
                    .border-primary {
                        border-color: ${primaryColour}!important;
                    }
                    .border-secondary, .md\\:hover\\:border-secondary:hover  {
                        border-color: ${secondaryTextColour}!important;
                    }
                    .border-neutral {
                        border-color: ${neutralColour}!important;
                    }
                    .text-primary:not(.icon) {
                        color: ${primaryTextColour}!important;
                    }
                    .text-secondary:not(.icon) {
                        color: ${secondaryTextColour}!important;
                    }
                    .text-tertiary:not(.icon) {
                        color: ${accentColour}!important;
                    }
                    .text-neutral:not(.icon) {
                        color: ${neutralColour}!important;
                    }
                    .text-branded-default-dark {
                        color: #1a1a1a!important;
                    }
                    .progress {
                        background-color: ${neutralColour}!important;
                    }
                    .table-scroll-wrapper::-webkit-scrollbar-thumb  {
                       background-color: ${secondaryTextColour}!important;
                    }
                    .text-secondary[data-bg-colour=white] {
                        color: ${secondaryColour}!important;
                    }
                    .text-primary[data-bg-colour=white] {
                        color: ${primaryColour}!important;
                    }
                    `;
        document.head.appendChild(style);

        onBeforeUnmount(() => {
            document.head.removeChild(style);
        })
    }

    return {
        getContrast,
        getNeutralColour,
        setBrandedStyles
    }
}

