<template>
    <Form @submit.prevent="handleSubmit" :error-message="errorMessage">
        <h1 class="text-xl mb-1 font-bold">Register</h1>
        <p class="text-md">Already have an account? <router-link :to="{ name: getLoginRouteName }"> <span data-bg-colour="white" class="underline text-secondary font-bold">Login here</span></router-link></p>

        <div class="mt-6 mb-8">
            <TextInput
                label="Name"
                v-model="data.name"
                name="name"
                placeholder="Enter name"
                :errors="errors"
            />

            <TextInput
                label="Email Address"
                v-model="data.email"
                name="email"
                type="email"
                placeholder="Enter email address"
                :errors="errors"
            />

            <TextInput
                label="Password"
                v-model="data.password"
                name="password"
                type="password"
                placeholder="Enter your password"
                :errors="errors"
            />

            <TextInput
                label="Password Confirmation"
                v-model="data.password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirm password"
                :errors="errors"
            />

            <div class="mb-4">
                <label class="relative text-sm mb-2 font-sans flex">
                    <input type="checkbox" class="mr-2 border border-grey-300 p-2 rounded-md" v-model="data.is_charity">
                    <span class="block">I'm registering as a charity</span>
                </label>
            </div>

            <div class="mb-6">
                <label class="relative text-sm mb-2 font-sans flex">
                    <input type="checkbox" class="mr-2 border border-grey-300 p-2 rounded-md" v-model="data.privacy_consent">
                    <span class="block">I have read and agree to the <button class='underline' type='button'  @click="handleOptInModal('privacy')">privacy policy</button> and <button class='underline' @click="handleOptInModal('terms')" type='button'>terms and conditions</button></span>
                </label>
            </div>

            <div class="mb-6" v-if="quizPrivacyStatement && quizPrivacyStatement.length > 0 || quizTermsAndConditionsStatement && quizTermsAndConditionsStatement.length > 0">
                <label class="relative text-sm mb-2 font-sans flex">
                    <input type="checkbox" class="mr-2 border border-grey-300 p-2 rounded-md" v-model="data.quiz_privacy_consent">
                    <span class="block">I have read and agree to the quiz operators
                        <button v-if="quizPrivacyStatement && quizPrivacyStatement.length > 0" class='underline' type='button'  @click="handleOptInModal('quiz_privacy')">privacy policy</button>
                        <span v-if="quizPrivacyStatement && quizPrivacyStatement.length > 0 && quizTermsAndConditionsStatement && quizTermsAndConditionsStatement.length >0"> and </span>
                        <button  v-if="quizTermsAndConditionsStatement && quizTermsAndConditionsStatement.length > 0" class='underline' @click="handleOptInModal('quiz_terms')" type='button'>terms and conditions</button>
                    </span>
                </label>
            </div>
        </div>

        <div class="flex flex-col items-center">
            <LoadingButton type="submit" :is-loading="data.isLoading" class="btn--large btn-primary">
                Register
            </LoadingButton>
        </div>
    </Form>
</template>

<script setup>
import api from "../../api/axios-api";
import {computed, ref} from "vue";
import {useUserStore} from "../../stores/user";
import {useConfigStore} from "../../stores/config";
import Form from "../Form/Form.vue";
import TextInput from "../Form/TextInput.vue";
import {useRoute} from "vue-router";
import {useFormData} from "../../composibles/formData.js";
import LoadingButton from "../Global/LoadingButton.vue";

const userStore = useUserStore();
const {getPrivacyPolicy, getTermsAndConditions} = useConfigStore();

const {setFormErrors, errors, errorMessage} = useFormData();
const route = useRoute();
const emit = defineEmits(['success']);

const props = defineProps({
    quizPrivacyStatement: {
        type: String,
        default: '',
    },
    quizTermsAndConditionsStatement: {
        type: String,
        default: '',
    }
});

const data = ref({
    id: 'RegisterForm',
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    privacy_consent: false,
    quiz_privacy_consent: false,
    is_charity: false
});

const getLoginRouteName = computed(() => {
    if (route.name === 'quiz-register-screen') {
        return 'quiz-login-screen';
    }
    return 'login'
});

const handleSubmit = async (e) => {
    try {
        const response = await api.post('/register', {
            name: data.value.name,
            email: data.value.email,
            password: data.value.password,
            password_confirmation: data.value.password_confirmation,
            is_charity: data.value.is_charity,
            privacy_consent: data.value.privacy_consent,
        });

        localStorage.setItem('authToken', response.data.token);

        userStore.storeUser(response.data.user);

        emit('success', response.data.user, response.data.account);
    } catch (error) {
        setFormErrors(error);
    } finally {

    }
};

const handleOptInModal = (name) =>  {
    let modalHeader = '';
    let modalBody = '';

    switch(name) {
        case 'quiz_privacy' :
            modalHeader = 'Privacy Statement';
            // modalBody = this.quizPrivacyStatement;
            break;
        case 'quiz_terms' :
            modalHeader = 'Terms and Conditions';
            // modalBody = this.quizTermsAndConditionsStatement;
            break;
        case 'privacy' :
            modalHeader = 'Privacy Statement';
            // modalBody = this.getPrivacyPolicy;
            break;
        case 'terms' :
            modalHeader = 'Terms and Conditions';
            // modalBody = this.getTermsAndConditions;
            break;
    }

    // this.showPolicyModal(name, modalHeader, modalBody);
};

</script>
