<template>
    <div>
        <div class="h-full">
            <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
                <FadeLoader
                    :loading="isScreenLoading"
                    color="#E8E7EB"
                />
            </div>
            <div v-else class="quiz-screen h-full">
                <QuizBaseLayout>
                    <template v-slot:header>
                        <div v-if="hasCurrentRound">
                            <Header
                                :title="currentRound.title"
                                :round-number="currentRound.order"
                            />
                            <AdminHeaderMeta :current-round="currentRound"/>
                        </div>
                    </template>

                    <component :is="currentScreen.component"/>

                    <template v-slot:sidebar>
                        <Broadcaster
                            :quiz-id="quiz.id"
                            :admin-id="userStore.getUser.id"
                        />

                        <ActionBtns/>

                        <div class="panel panel-sm" v-if="currentScreen.component.__name === 'QuizAdminRoundPlayingScreen'">
                            <CountdownTimer
                                :start-date="getRoundStartDate"
                                :end-date="getRoundEndDate"
                                :primary-colour="getQuizThemeColours.primary"
                                :secondary-colour="getQuizThemeColours.secondary"
                            />
                            <Scoreboard/>
                        </div>
                    </template>
                </QuizBaseLayout>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from "../../../api/axios-api";
import PowerUpForm from "../../../components/Quiz/Admin/PowerUpForm.vue";
import QuizBaseLayout from "../../../layouts/QuizBaseLayout.vue";
import Broadcaster from "../../../components/Quiz/Admin/BroadcastVideo.vue";
import ActionBtns from "../../../components/Quiz/Admin/ActionBtns.vue";
import CountdownTimer from "../../../components/Quiz/Global/CountdownTimer.vue";
import Scoreboard from "../../../components/Quiz/Round/Scoreboard.vue";
import {computed, onBeforeMount} from "vue";
import {RoundData} from "../../../composibles/roundData.js";
import {QuizData} from "../../../composibles/quizData.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useUserStore} from "../../../stores/user.js";
import {ScreenData} from "../../../composibles/screenData.js";
import Header from "../../../components/Quiz/Global/Header.vue";
import QuizAdminClosedScreen from "./QuizAdminClosedScreen.vue";
import QuizAdminRoundResultsScreen from "./QuizAdminRoundResultsScreen.vue";
import QuizAdminRoundPlayingScreen from "./QuizAdminRoundPlayingScreen.vue";
import QuizAdminRoundWaitingRoomScreen from "./QuizAdminRoundWaitingRoomScreen.vue";
import {EventData} from "../../../composibles/eventData.js";
import {useQuizStore} from "../../../stores/quiz.js";
import {useModal, useModalSlot} from "vue-final-modal";
import Modal from "../../../components/Global/Modal.vue";
import {useToast} from "vue-toast-notification";
import AdminHeaderMeta from "../../../components/Quiz/Admin/AdminHeaderMeta.vue";

const quizStore = useQuizStore();
const userStore = useUserStore();

const {checkScreenSize, setIsLoading, isScreenLoading} = ScreenData();
const {setQuizData, quiz, nextRound, currentRound, isQuizOpen, isQuizClosed, getQuizThemeColours} = QuizData();
const {subscribeToQuizChannelEvents, leaveChannels} = EventData();
const {hasCurrentRound, hasRoundStarted, hasRoundEnded, getRoundStartDate, getRoundEndDate} = RoundData();
const {hasTimeStarted} = ParticipantData();

const $toast = useToast();

const props = defineProps({
    quizData: {
        type: Object,
        return: () => {}
    }
});

const currentScreen = computed(() => {
    switch (true) {
        case (isQuizOpen.value && isQuizClosed.value) :
            console.log('closed screen');
            return {
                title: 'Quiz Closed',
                component: QuizAdminClosedScreen
            };
        case (hasRoundStarted.value && hasRoundEnded.value):
            console.log('ended screen');

            return {
                title: 'Round Ended',
                component: QuizAdminRoundResultsScreen
            };
        case (hasRoundStarted.value && !hasRoundEnded.value && hasTimeStarted.value):
            console.log('launch screen');

            return {
                title: 'Launch Screen',
                component: QuizAdminRoundPlayingScreen
            };
        case (!hasRoundStarted.value && !hasRoundEnded.value || !hasTimeStarted.value) :
            console.log('waiting screen');

            return {
                title: 'Launch Screen',
                component: QuizAdminRoundWaitingRoomScreen
            };
    }
});

const getParticipantChannelName = () => {
    return `quiz-participant-streaming-channel.${quiz.value.id}`;
}

const openPowerUpModal = (data) => {
    if (!data.action) return;

    const { open, close } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel !bg-primary', closable: true, draggable: false
        },
        slots: {
            default: useModalSlot({
                component: PowerUpForm,
                attrs: {
                    title: data.message,
                    action: data.action,
                    completedCount: data.completed_count,
                    participantIds: data.participant_ids,
                    btnLabel: data.btn_text,
                    startDate: data.start_date ? new Date(data.start_date) : false,
                    endDate: data.end_date ? new Date(data.end_date) : false,
                },
                onClose() {
                    close()
                },
            })
        }
    });

    open();
}

const initializeParticipantHeartbeatChannel = () => {
    Echo.join(getParticipantChannelName())
        // .here((users) => {
        //     let participantsInLobby = users.filter(u => !u.is_admin);
        //
        //     console.log(participantsInLobby);
        //     console.log('Participants found in presence channel:', participantsInLobby.length);
        //
        //     api.post(`/quiz/${this.getQuizId}/participants/check-and-upsert-presence`, {
        //         participants: participantsInLobby
        //     })
        //     .then(response => {
        //         /** @TODO update participant state */
        //         console.log('Presence data bulk inserted:', response.data);
        //     })
        //     .catch(error => {
        //         console.error('Error inserting presence data:', error);
        //     });
        // })
        .joining((participant) => {
            api.post(`/quiz/${quiz.value.id}/participants/check-and-insert-presence`, {
                participant: participant,
                status: 'active'
            })
                .then(response => {
                    console.log('Presence data bulk inserted:', response.data);
                })
                .catch(error => {
                    console.error('Error inserting presence data:', error);
                });
        })
        // .leaving((participant) => {
        //     api.post(`/quiz/${this.getQuizId}/participants/check-and-insert-presence`, {
        //         participant: participant,
        //         status: 'inactive'
        //     })
        //     .then(response => {
        //         console.log('Presence data bulk inserted:', response.data);
        //     })
        //     .catch(error => {
        //         console.error('Error inserting presence data:', error);
        //     });
        // })
        .error((error) => {
            console.error('An error occurred when updating participant presence channel state', error);
        });
}

const setState = async () => {
    try {
        setIsLoading(true);
        setQuizData(props.quizData);

        initializeParticipantHeartbeatChannel();

        await subscribeToQuizChannelEvents();
        await quizStore.loadParticipants({
            quizId: quiz.value.id
        });

    } catch (err) {
        data.value.toastInstance = $toast.open({
            message: err.message,
            type: 'error',
            duration: 100000,
            dismissible: false
        });

    } finally {
        setIsLoading(false);
    }
}

onBeforeMount(async () => {
    setState();
})
</script>
