<template>
    <div>
        <QuestionsAnsweredGraph/>

        <Answers
            title="Questions Completed"
            :answers="data.completedQuestionsWithAnswers"
            :refresh-count="data.answersToRefresh.length"
            @refresh="refreshAnswers"
        />
    </div>
</template>

<script setup>
import api from "../../../api/axios-api";
import QuestionsAnsweredGraph from "../../../components/Quiz/Admin/QuestionsAnsweredGraph.vue";
import Answers from "../../../components/Quiz/Round/Answers.vue";
import {onMounted, ref, watch} from "vue";
import {QuizData} from "../../../composibles/quizData.js";
import {RoundData} from "../../../composibles/roundData.js";

const {quiz, currentRound} = QuizData();
const {getRoundParticipants, getRoundQuestionIds} = RoundData();

const data = ref({
    completedQuestionsWithAnswers: [],
    unansweredQuestionIds: [],
    answersToRefresh: []
});

const loadCompletedQuestionsWithAnswers = async () => {
    const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/answers');
    data.value.completedQuestionsWithAnswers = response.data.answers;
    updateUnansweredQuestionIds(getRoundParticipants.value);
}

const updateUnansweredQuestionIds = (roundParticipants) => {
    if (roundParticipants.length <= 0) return;
    let unansweredQuestionIds = getRoundQuestionIds.value.filter((id) => {
        let questionIdIndex = data.value.completedQuestionsWithAnswers.findIndex(q => q.question_id === id);
        return questionIdIndex === -1;
    });

    unansweredQuestionIds.forEach((questionId) => {
        const allParticipantsAnsweredQuestionId = roundParticipants.every((participant) => {
            return typeof participant.answered_questions[questionId] !== 'undefined';
        });

        if (allParticipantsAnsweredQuestionId) {
            getCompletedQuestionWithParticipantAnswers(questionId);
        }
    });
}

const getCompletedQuestionWithParticipantAnswers = async (questionId) => {
    if (data.value.answersToRefresh.some((answer) => answer.question_id === questionId)) return;
    const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/question/' + questionId + '/participant_answers');
    if (response.data.answers) {
        if (data.value.completedQuestionsWithAnswers.length > 0) {
            data.value.answersToRefresh.push(response.data.answers[0]);
        } else {
            data.value.completedQuestionsWithAnswers.push(response.data.answers[0]);
        }
    }
}

const refreshAnswers = () => {
    data.value.answersToRefresh.forEach((answer) => {
        answer.is_new = true
        data.value.completedQuestionsWithAnswers.unshift(answer);
    });

    data.value.answersToRefresh = [];
}

watch(
    () => getRoundParticipants,
    (newRoundParticipants, oldRoundParticipants) => {
        updateUnansweredQuestionIds(newRoundParticipants);
    }
);

onMounted(async () => {
    await loadCompletedQuestionsWithAnswers();
})
</script>
