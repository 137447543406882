<template>
    <AccountGuestLayout>
        <ForgotPasswordForm @success="handleSignIn"/>
    </AccountGuestLayout>
</template>
<script setup>
import AccountGuestLayout from "../../layouts/AccountGuestLayout.vue";
import ForgotPasswordForm from "../../components/Account/ForgotPasswordForm.vue";
import {useRoute, useRouter} from "vue-router";

const router = useRouter();
const route = useRoute();

const handleSignIn = () => {
    if (route.query.redirect) {
        router.push(route.query.redirect);
    } else {
        // router.push({ name: 'account', params: { id: user.account.id } });
    }
}
</script>
