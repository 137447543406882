<template>
    <div class="min-h-full flex flex-col bg-cover">
        <Header class="!mb-0" data-aos="fade-down"
                       data-aos-duration="1000"
                       data-aos-delay="100"/>
        <div class="flex-grow bg-bg-pattern-2 lg:bg-[center_bottom] bg-no-repeat bg-cover">
            <div class="text-center mx-auto max-w-[70rem] container flex items-center min-h-[calc(100vh-240px)]">
                <h1 class="text-2xl sm:text-3xl text-primary"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200">The <span class="text-secondary">large scale</span> quiz platform
                    which makes it easy for charities to <span class="text-secondary">raise funds</span>.</h1>
            </div>

            <div class="absolute w-full text-center">
                <button @click="scrollToMain"
                        class="mx-auto inline-block animate-bounce hover:opacity-60 transition-opacity">
                    <img class="w-8" alt="" :src="asset('images/down-arrow.svg')"/>
                </button>
            </div>
        </div>
        <main id="main" ref="main" class="mt-12">
            <div class="container mb-4">
                <QuizListings :quizzes="quizzes"/>
            </div>
            <div class="container mb-4">
                <div class="home-section">
                    <div class="w-full lg:w-5/12">
                        <h2 class="text-[2.5rem]" data-aos="fade" data-aos-duration="1000">Everyone loves a <span class="text-secondary">fair</span> fight</h2>
                        <p data-aos="fade" data-aos-duration="1000">A cloud-based quiz platform designed for hosting live quiz tournaments. An unlimited number
                            of people, pay to play and to win prizes, knowing that they can trust the result. This is
                            because, to win a Quick As you Can tournament, you must give the most correct answers, in
                            the shortest time.</p>
                        <p data-aos="fade" data-aos-duration="1000">Combined with other features, this effectively makes it impossible to cheat. Ask a friend or
                            Google? No problem – but it will take time.</p>
                    </div>
                    <div class="w-full lg:w-6/12 ml-auto">
                        <img alt="" :src="asset('images/demo-1.png')" data-aos="fade-left" data-aos-duration="1000"/>
                    </div>
                </div>
                <div class="home-section flex-row-reverse">
                    <div class="w-full lg:w-5/12">
                        <h2 class="text-[2.5rem]"  data-aos="fade" data-aos-duration="1000">Boost <span class="text-secondary">engagement</span> & awareness</h2>
                        <p data-aos="fade" data-aos-duration="1000">Quick As you Can also offers free-to-play, ‘fixed time window’ tournaments involving, say,
                            one round per day (much like Wordle). These can be used to promote live events, raise
                            awareness and increase stakeholder engagement.</p>
                    </div>
                    <div class="w-full lg:w-6/12 mr-auto">
                        <img alt="" :src="asset('images/demo-2.png')"  data-aos="fade-right" data-aos-duration="1000"/>
                    </div>
                </div>
                <div class="home-section">
                    <div class="w-full lg:w-5/12">
                        <h2 class="text-[2.5rem]" data-aos="fade" data-aos-duration="1000">From idea to live quiz - in <span
                            class="text-secondary">minutes</span></h2>
                        <p data-aos="fade" data-aos-duration="1000">Creating quizzes with Quick As You Can is quick and easy: you can manually input your questions and answers, one by one, upload a CSV file of them all, or import them from a huge database of questions. These can be filtered by level of difficulty and by subject.</p>
                    </div>
                    <div class="w-full lg:w-6/12 ml-auto">
                        <img alt="" :src="asset('images/demo-3.png')"  data-aos="fade-left" data-aos-duration="1000"/>
                    </div>
                </div>
            </div>
            <div class="slider-wrapper mt-36 bg-white py-24">
                <div class="container">
                    <h2 class="text-[2.5rem] mb-16" data-aos="fade" data-aos-duration="1000">All the <span class="text-secondary">features</span> you need<br> to
                        boost awareness.</h2>

                    <div class="features-grid">
                        <div class="feature" data-aos="zoom-in-up">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/tournaments.svg')"/>
                                </div>
                                <h3>Play anytime and live
                                    tournament modes</h3>
                                <p>Enjoy the flexibility of playing quizzes at your convenience or participating in live
                                    tournaments for a thrilling competitive experience in real-time.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="50">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/large-scale.svg')"/>
                                </div>
                                <h3>Large scale - up to 100,000 players</h3>
                                <p>Seamlessly accommodate massive numbers of players simultaneously, leveraging the
                                    power and scalability of Amazon Web Services (AWS) infrastructure.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="100">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/anti-cheat.svg')"/>
                                </div>
                                <h3>Rigorously fair</h3>
                                <p>To maintain trust, speed of response is just one means by which we ensure it is effectively impossible to cheat in a live Quick As You Can quiz.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/video.svg')"/>
                                </div>
                                <h3>Video integration</h3>
                                <p>Enhance the quiz experience with seamless integration of videos, providing engaging
                                    multimedia content to enrich the user experience.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="50">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/user-friendly.svg')"/>
                                </div>
                                <h3>User friendly design</h3>
                                <p>Navigate effortlessly through the platform with an intuitive and ergonomic design,
                                    tailored to provide a smooth and enjoyable user experience for all participants.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="100">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/branded-quizzes.svg')"/>
                                </div>
                                <h3>Branded quizzes</h3>
                                <p>Customize quizzes with your brand identity, creating a unique and memorable
                                    experience for participants while reinforcing brand recognition.</p>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-6 relative z-9" v-if="!data.loadedAllFeatures">
                        <button type="button" class="btn btn-primary btn--large" @click="viewAllFeatures">View all</button>
                    </div>
                    <div class="features-grid" v-if="data.loadedAllFeatures">
                        <div class="feature" data-aos="zoom-in-up">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/live-chat.svg')"/>
                                </div>
                                <h3>Live Chat</h3>
                                <p>Foster interaction and engagement among participants with live chat functionality,
                                    enabling real-time communication during quiz sessions.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="50">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/libary.svg')"/>
                                </div>
                                <h3>Quiz library</h3>
                                <p>Access a vast repository of quizzes covering various topics and themes, providing a
                                    diverse selection to cater to different interests and preferences.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="100">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/realtime-insights.svg')"/>
                                </div>
                                <h3>Real-time insights</h3>
                                <p>Gain valuable insights into quiz performance and participant engagement in real-time,
                                    enabling informed decision-making and optimization of quiz content and
                                    strategies.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/responsive-design.svg')"/>
                                </div>
                                <h3>Mobile Friendly</h3>
                                <p>Enjoy seamless access and usability across a range of mobile devices, ensuring a
                                    consistent and enjoyable experience for users on smartphones and tablets.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="50">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/secure-payments.svg')"/>
                                </div>
                                <h3>Secure payments</h3>
                                <p>Secure and convenient payments for quiz participation and transactions using the
                                    trusted Stripe payment gateway, ensuring the safety of financial transactions.</p>
                            </div>
                        </div>
                        <div class="feature" data-aos="zoom-in-up" data-aos-delay="100">
                            <div class="feature-inner">
                                <div class="icon">
                                    <img alt="" :src="asset('images/ssl.svg')"/>
                                </div>
                                <h3>Secured with SSL encryption</h3>
                                <p>Ensure privacy with SSL encryption, safeguarding user information and providing a
                                    secure environment for quiz participation and interactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-32">
                <div class="container">
                    <div class="text-center"  data-aos="fade-down" data-aos-duration="1000">
                        <img alt="" :src="asset('images/supported-by-logos.png')" class="mx-auto  lg:max-w-[620px]"/>
                    </div>
                </div>
            </div>

            <div id="contact" ref="contact">
                <div class="container">
                    <form @submit.prevent="submitEnquiry" class="form mt-8" data-aos="fade-up" data-aos-duration="1000">
                        <div class="text-center mb-16 mt-8">
                            <h2 class="text-[2.5rem] text-primary mb-4">Would you like to know more? <span
                                class="text-secondary">Let’s talk!</span></h2>
                            <p class="text-lg text-grey-500">Please fill out the form below and we will back to you
                                within 1-2 business
                                days.</p>
                        </div>
                        <div class="form-inner">
                            <ul class="notices" v-if="data.successMessage.length > 0 || data.errorMessage.length > 0">
                                <li v-if="data.errorMessage.length > 0" class="error">
                                    <i class="fas fa-exclamation-triangle mr-2"></i> {{data.errorMessage}}
                                </li>
                                <li v-else-if="data.successMessage.length > 0" class="success">
                                  {{data.successMessage}}
                                </li>
                            </ul>
                            <template  v-if="data.successMessage.length === 0">
                                <div class="lg:flex -mx-4">
                                    <div class="lg:w-6/12 px-4 mb-8">
                                        <label>
                                            <span class="block">Name</span>
                                            <input name="name" class="" v-model="form.name">
                                        </label>
                                        <span class="mt-1 block text-red-200 text-sm" v-if="data.formErrors && data.formErrors?.name?.length > 0">{{data.formErrors.name[0]}}</span>
                                    </div>
                                    <div class="lg:w-6/12 px-4 mb-8">
                                        <label>
                                            <span class="block">Email</span>
                                            <input name="email" class="" v-model="form.email">
                                        </label>
                                        <span class="mt-1 block text-red-200 text-sm" v-if="data.formErrors && data.formErrors?.email?.length > 0">{{data.formErrors.email[0]}}</span>
                                    </div>
                                </div>
                                <div class="lg:flex -mx-4">
                                    <div class="lg:w-6/12 px-4 mb-8">
                                        <label>
                                            <span class="block">Telephone</span>
                                            <input name="telephone" class="" v-model="form.tel">
                                        </label>
                                        <span class="mt-1 block text-red-200 text-sm" v-if="data.formErrors && data.formErrors?.tel?.length > 0">{{data.formErrors.tel[0]}}</span>
                                    </div>
                                    <div class="lg:w-6/12 px-4 mb-8">
                                        <label>
                                            <span class="block">Charity/Organisation</span>
                                            <input name="organisation" class="" v-model="form.organisation">
                                        </label>
                                        <span class="mt-1 block text-red-200 text-sm" v-if="data.formErrors && data.formErrors?.organisation?.length > 0">{{data.formErrors.organisation[0]}}</span>
                                    </div>
                                </div>
                                <div>
                                    <label class="relative text-sm mb-2 font-sans flex">
                                        <input type="checkbox" class="!w-auto !pt-0 mr-2 border border-grey-300 p-2 rounded-md" v-model="form.agreement">
                                        <span class="block">I have read and agree to the <button class='underline' type='button' @click="handlePrivacyModal">privacy policy</button>
                                    </span>
                                    </label>
                                </div>
                                <div class="text-center mt-8">
                                    <button class="btn btn-tertiary btn--large" type="submit" :disabled="data.isFormProcessing" :class="data.isFormProcessing ? 'btn--is-loading' : ''">
                                        <span class="loader" v-if="data.isFormProcessing"></span> Submit
                                    </button>
                                </div>
                            </template>
                        </div>
                    </form>
                </div>
            </div>
        </main>
        <Footer/>
    </div>
</template>

<script setup>
import api from "../api/axios-api";
import Header from "../layouts/Header.vue";
import Footer from "../layouts/Footer.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useQuizListings} from "../composibles/quizListings.js";
import QuizListings from "../components/Quiz/QuizListings.vue";

const route = useRoute();

const {isLoading, quizzes} = useQuizListings();

const data = ref({
    loadedAllFeatures: false,
    isFormProcessing: false,
    isFormSuccessful: false,
    errorMessage: '',
    successMessage: '',
    formErrors: {},
})

const form = ref({
    name: '',
    email: '',
    tel: '',
    organisation: '',
    agreement: '',
    "g-recaptcha-response": ''
});


onMounted(() => {
    if (route.hash === '#contact') {
        setTimeout(() => {
            scrollTo(this.$refs.contact.offsetTop);
        }, 20)
    }
});

const scrollToMain = () => {
    scrollTo(this.$refs.main.offsetTop);
}

const scrollTo = (pos) => {
    document.querySelector('html').scrollTo({top: pos, behavior: 'smooth'})
}

const viewAllFeatures = () => {
    data.value.loadedAllFeatures = true;
}

const submitEnquiry = async() => {
    data.isFormProcessing= true;

    await this.$recaptchaLoaded()

    form['g-recaptcha-response'] = await this.$recaptcha('contact');
    data.formErrors = {};
    data.errorMessage = '';

    try {
        const response = await api.post('/contact-us', this.form);

        if (response.status === 200) {
            this.isFormSuccessful = true;
            this.successMessage = response.data.message;
        }
    } catch (error) {
        if (error.response.data.errors) {
            this.formErrors = error.response.data.errors;
        }
        if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
        }
    } finally {
        this.isFormProcessing = false;
    }
}
const handlePrivacyModal = () => {
    // this.showPolicyModal('privacy', 'Privacy Policy', this.$store.getters.getPrivacyPolicy);
}

</script>

