<template>
    <button
        @click="share"
        class="btn btn-primary btn--with-icon mb-4">
<!--        <img alt="" :src="asset('images/share.svg')" class="w-3 mr-4">-->
        {{btnLabel}}
    </button>
</template>
<script setup>

const props = defineProps({
    title: {
        default: document.title,
        type: String
    },
    btnLabel: {
        default: 'Share',
        type: String
    },
    text: {
        default: '',
        type: String
    },
    url: {
        default: document.title,
        type: String
    }
})

const share = async () => {
    if (!navigator.canShare) {
        console.log('Your browser doesn\'t support the Web Share API');
        return;
    }
    try {
        await navigator.share({
            title: props.title,
            url: props.url,
            text: props.text
        });
        console.log('Thanks for sharing!');
    } catch (err) {
        console.error(err);
    }
}
</script>
