<template>
    <div class="panel">
        <h1 class="text-xl">Edit Charity</h1>
        <Form @submit="(e) => handleSubmit(e, true)">
            <TextInput
                label="Charity Name"
                v-model="data.charity_name"
                name="name"
                placeholder="Enter name"
                :errors="errors"
            />
            <TextInput
                label="Charity Number"
                v-model="data.charity_number"
                name="charity_number"
                placeholder="Enter number"
                :errors="errors"
            />
            <TextInput
                label="Website URL"
                v-model="data.website_url"
                name="website_url"
                placeholder=""
                :errors="errors"
            />

            <RichTextArea
                label="Description"
                name="description"
                v-model="data.description"
            />
            <FileUploader
                name="logo"
                label="Logo"
                v-model="data.logo"
                :file="data.logo"
            />
            <FileUploader
                name="banner_image"
                label="Banner Image"
                v-model="data.banner"
                :file="data.banner"
            />

            <h4 class="block text-lg text-secondary mt-8 mb-6">Branding</h4>

            <div class="flex align-top sm:gap-8">
                <div class="w-3/12">
                    <h4 class="font-bold mt-4 mb-4">Quiz Colours</h4>

                    <ColourPicker
                        label="Primary"
                        name="primary_colour"
                        v-model="data.primary_colour"
                    />

                    <ColourPicker
                        label="Secondary"
                        name="secondary_colour"
                        v-model="data.secondary_colour"
                    />

                    <ColourPicker
                        label="Accent"
                        name="accent_colour"
                        v-model="data.accent_colour"
                    />
                </div>
                <div class="w-4/12">
                    <h4 class="font-bold mt-4 mb-4">Sign in screens</h4>
                    <ColourPicker
                        label="Background colour"
                        name="sign_in_screen_bg_colour"
                        v-model="data.sign_in_screen_bg_colour"
                    />
                </div>
            </div>

            <div class="w-full bg-grey-200 border-grey-300 pt-4 pb-6 px-6 rounded-lg mb-8">
                <p class="tag text-center text-green-500">Preview</p>
                <DummyPreviewRound title="Example Round"
                                   :primary-colour="data.primary_colour"
                                   :secondary-colour="data.secondary_colour"
                                   :accent-colour="data.accent_colour"
                />
            </div>

            <LoadingButton type="submit" :is-loading="data.isLoading" class="btn--large btn-primary">
                Update
            </LoadingButton>

            <router-link class="btn btn-tertiary btn--large" :to="{name: 'charity', params: {slug: userStore.getUser?.account?.charity?.id}}">View Page</router-link>
        </Form>
    </div>
</template>

<script setup>
import {useNoticesStore} from "../../stores/notices";
import TextInput from "../Form/TextInput.vue";
import Form from "../Form/Form.vue";
import {CharityForm} from "../../composibles/charityForm.js";
import {useUserStore} from "../../stores/user.js";
import FileUploader from "../Form/FileUploader.vue";
import RichTextArea from "../Form/RichTextArea.vue";
import LoadingButton from "../Global/LoadingButton.vue";
import ColourPicker from "../Form/ColourPicker.vue";
import DummyPreviewRound from "./Quizzes/DummyPreviewRound.vue";

const userStore = useUserStore();
const {setNotice} = useNoticesStore();

const {data, handleSubmit, errors} = CharityForm();
</script>
