<template>
    <div>
        <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
            <select
                :name="name"
                :id="name"
                v-model="internalValue"
                class="py-4 px-3.5 text-md font-medium font-sans border border-grey-300 rounded-lg w-full"
                :class="[{'border-red-200': hasErrors}]"
            >
                <option :value="null" disabled>
                    {{ defaultItemLabel }}
                </option>
                <option
                    v-for="(option, key) in options"
                    :key="key"
                    :value="option.value"
                >
                    {{ option.label }}
                </option>
            </select>
        </Label>
    </div>
</template>

<script setup>
import {ref, watch, defineProps, defineEmits, computed} from 'vue';
import Label from './Label.vue';

// Define props
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, null],
        default: null,
    },
    options: {
        type: Array,
        default: () => [],
    },
    defaultItemLabel: {
        type: String,
        default: 'Select quiz',
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

// Define emits
const emit = defineEmits(["update:modelValue"])

// Create a reactive reference for internal value
const internalValue = ref(props.modelValue);

// Watch for changes to props.value and update internalValue
watch(() => props.modelValue, (newValue) => {
    internalValue.value = newValue;
});

// Watch for changes to internalValue and emit the update
watch(internalValue, (newValue) => {
    console.log(newValue);
    emit('update:modelValue', newValue);
});
</script>
