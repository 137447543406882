<template>
    <header class="bg-white mb-4 sm:mb-12 border-b border-grey-200 sticky top-0 z-[996]">
        <div class="container mx-auto">
            <div class="flex flex-wrap items-center justify-between">
                <div class="header-col w-2/6 hidden lg:inline-block">
                    <div v-if="quiz && quiz.title && !quiz.automaticStart" class="py-4">
                        <h1 data-bg-colour="white" class="sans mb-1 text-primary">{{quiz.title}}</h1>
                        <p class="sans text-xs text-grey-400">Hosted by: {{quiz.createdBy}}</p>
                    </div>
                </div>
                <div class="header-col sm:py-2.5 lg:py-0 w-2/6 text-center">
                    <router-link
                        class="nav-link py-2.5 block"
                        data-toggle="collapse"
                        :to="{ name: 'home' }">

                        <img v-if="quiz?.logo !== null && quiz?.logo && quiz?.logo.length > 0" alt=""
                             :src="quiz?.logo" class="max-h-11 h-full mx-auto"
                        >
                        <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="h-11 mx-auto">
                    </router-link>
                </div>
                <div class="header-col w-2/6 text-right">
                    <nav class="hidden lg:inline-block flex-initial justify-self-end">
                        <ol class="sans flex flex-wrap items-center">
                            <router-link v-if="userStore.getUser?.account?.id" :to="{ name: 'account', 'params': {id: userStore.getUser?.account?.id} }">
                                Account
                            </router-link>
                            <router-link v-else :to="{name: 'login'}">
                                Login/Register
                            </router-link>
                        </ol>
                    </nav>
                    <slot></slot>
                </div>
            </div>
        </div>
    </header>
</template>
<script setup>
import {QuizData} from "../composibles/quizData.js";
import {useUserStore} from "../stores/user.js";

const userStore = useUserStore();
const {quiz} = QuizData();
</script>

