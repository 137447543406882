import {defineStore} from "pinia";

export const useScreenStore = defineStore('screen', {
    state: () => ({
        now: null,
        isLoading: false,
        notices: [],
    }),
    getters: {
        getIsLoading: (state) => state.isLoading,
        getTime: (state) => state.now
    },
    actions: {
        setIsLoading(payload) {
            this.isLoading = payload;
        },
        updateTime() {
            this.now = new Date();
        },
    },
})
