<template>
    <Form @submit="handleSubmit" :error-message="errorMessage">
        <h1 class="text-xl mb-4">Forgot your password</h1>
        <p class="text-lg">Enter your email and we'll send you instructions on how to reset your password</p>
        <div class="mt-8 mb-8">
            <TextInput
                label="Email Address"
                v-model="data.email"
                name="email"
                type="email"
                placeholder="Enter email address"
                :errors="errors"
            />
        </div>
        <div class="flex flex-col items-center">
            <button type="submit"
                    class="btn btn-secondary text-white" :disabled="data.isLoading"
                    :class="data.isLoading ? 'btn--is-loading' : ''">
                <span class="loader" v-if="data.isLoading"></span> Send Instructions
            </button>
        </div>
    </Form>
</template>

<script setup>
import api from "../../api/axios-api";
import Form from "../Form/Form.vue";
import TextInput from "../Form/TextInput.vue";
import {ref} from "vue";
import {useRoute} from "vue-router";
import {useFormData} from "../../composibles/formData.js";

const data = ref({
    id: 'ForgotPasswordForm',
    email: '',
    isLoading: false
});

const route = useRoute();

const {errors, errorMessage, setFormErrors} = useFormData();

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        data.isLoading = true;

        await api.post('/forgot-password', {
            email: data.email,
            quiz_id: route.params.quizId ?? null
        })
    } catch (error) {
        setFormErrors(error);
    } finally {
        data.isLoading = false;
    }
}
</script>
