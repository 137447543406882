<template>
    <div class="panel panel--centered">
        <h3 class="tag">Power ups used in this round <span style="width: 25px;"
                                                           class="align-middle count inline-block overflow-x-hidden rounded border py-0.5 text-xs mr-4 text-center bg-secondary border-secondary text-white">{{ getUsedPowerUpCount }}</span>
        </h3>
        <ul class="flex gap-4 mt-14">
            <li class="bg-primary w-4/12 p-8 rounded-lg relative text-white" v-for="powerUp in usedPowerUps">
                <div class="rounded-full w-14 h-14 p-4 shadow-lg bg-white absolute -top-8 left-1/2 -translate-x-1/2">
                    <img :src="'/images/' + powerUp.name.toLowerCase() + '.svg'" class="w-full h-full">
                </div>
                <h4 class="tag text-secondary">{{ powerUp.name }}</h4>
                <!--                <span class="font-bold mb-2 block">Activated on Question 5</span>-->
                <p class="text-sm">Wait for all players to finish to select a player to pinch the answer from.</p>
            </li>
        </ul>
    </div>
</template>
<script setup>

import {computed} from "vue";

const props = defineProps({
    usedPowerUps: Array
})

const getUsedPowerUpCount = computed(() => {
    return props.usedPowerUps.length;
});

</script>
