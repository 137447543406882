<template>
    <div class="panel">

        <div class="flex items-end justify-between mb-4">
            <div>
                <h1 class="text-xl">My Stats</h1>
                <p class="mt-2 text-xs text-grey-500"><strong>Last updated:</strong> {{data.lastUpdated}}</p>
            </div>
        </div>

        <div class="flex flex-wrap -mx-2 gap-y-4">
            <div class="w-4/12 text-center px-2">
                <div class="panel !px-1 !bg-primary text-white">
                    <h2 class="tag mb-4 !text-secondary">Quizzes played</h2>
                    <div class="flex gap-4 items-center justify-center">
                        <i class="fas fa-user text-1xl "></i>
                        <p class="text-2xl">{{data.playedQuizzed ?? 0}}</p>
                    </div>
                </div>
            </div>
            <div class="w-4/12 text-center px-2">
                <div class="panel !px-1 !bg-tertiary">
                    <h2 class="tag mb-4">Quizzes Won</h2>
                    <div class="flex gap-4 items-center justify-center">
                        <i class="fas fa-crown text-1xl "></i>
                        <p class="text-2xl ">{{data.quizzesWon ?? 0}}</p>
                    </div>
                </div>
            </div>

            <div class="w-4/12 text-center px-2">
                <div class="panel !px-1  !bg-secondary">
                    <h2 class="tag mb-4">Average Position</h2>
                    <div class="flex gap-4 items-center justify-center">
                        <i class="fas fa-chart-bar text-1xl"></i>
                        <p class="text-2xl">{{getOrdinal(data.avgPosition)}}</p>
                    </div>
                </div>
            </div>

            <div class="w-3/12 text-center px-2">
                <div class="panel !px-1">
                    <h2 class="tag mb-4">Questions Answered</h2>
                    <p class="text-1xl">{{data.questionsAnswered ?? 0}}</p>
                </div>
            </div>

            <div class="w-3/12 text-center px-2">
                <div class="panel !px-1">
                    <h2 class="tag mb-4">Correct Answers:</h2>
                    <p class="text-1xl">{{data.correctAnswers ?? 0}}</p>
                </div>
            </div>

            <div class="w-3/12 text-center px-2">
                <div class="panel !px-1">
                    <h2 class="tag mb-4">Top 10%:</h2>
                    <p class="text-1xl">{{data.top10 ?? 0}}</p>
                </div>
            </div>

            <div class="w-3/12 text-center px-2">
                <div class="panel !px-1">
                    <h2 class="tag mb-4">Top 50%:</h2>
                    <p class="text-1xl">{{data.top50 ?? 0}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from "../../api/axios-api";
import moment from "moment/moment";
import {onMounted, ref} from "vue";

const data = ref({
    playedQuizzed: 0,
    quizzesWon: 0,
    avgPosition: 0,
    questionsAnswered: 0,
    correctAnswers: 0,
    top10: 0,
    top50: 0,
    lastUpdated: null
});

const getStats = async () => {
    try {
        const response = await api.get(`/account/stats`);
        data.value.playedQuizzed = response.data.stats.played_quizzes;
        data.value.quizzesWon = response.data.stats.quizzes_won;
        data.value.avgPosition = response.data.stats.average_position;
        data.value.questionsAnswered = response.data.stats.questions_answered;
        data.value.correctAnswers = response.data.stats.correct_answers;
        data.value.top10 = response.data.stats.top_10_percent;
        data.value.top50 = response.data.stats.top_50_percent;
        data.value.lastUpdated = new Date(response.data.last_updated);
    } catch (error) {
        console.error('error whilst getitng stats');
    }
}

const getOrdinal = (rank) => {
    return rank > 0 ? moment.localeData().ordinal(rank) : 'N/A'
};

onMounted(async () => {
    await getStats();
});
</script>
