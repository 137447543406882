<template>
    <div class="panel bg-primary text-white mb-8 text-center p-4">
        Please wait whilst your answers are being processed...
    </div>
</template>
<script>
export default {

}
</script>
