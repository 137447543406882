<script setup>
    import {computed} from "vue";

    const props = defineProps({
       title: {
           type: String,
           default: 'Round 1'
       },
        primaryColour: {
            type: String,
            default: '',
        },
        secondaryColour: {
            type: String,
            default: ''
        },
        accentColour: {
            type: String,
            default: ''
        }
    });
    const primaryBgColor = computed(() => {
        return props.primaryColour ? { backgroundColor: props.primaryColour } : {};
    });

    const secondaryTextColor = computed(() => {
        return props.secondaryColour ? { color: props.secondaryColour } : {};
    });
</script>

<template>
    <div :style="primaryBgColor" class="panel text-white flex-grow">
        <span class="tag" :style="secondaryTextColor">Round 1 / 1</span>
        <h2 class="text-xl mb-4" :style="secondaryTextColor">{{ title }}</h2>
        <ul class="flex flex-wrap gap-4 sm:gap-12 mb-4 mt-4">
            <li class="w-full sm:w-auto">
                <span class="font-bold">Round ends in:</span>
                <span :style="secondaryTextColor">
                        <p class="tag text-center"></p>
                        <span class="countdown-outer timer flex flex-wrap relative">
                            <span class="countdown-inner">23h 46m 50s</span>
                        </span>
                </span>
            </li>
            <li class="w-full sm:w-auto">
                <span class="font-bold">Duration</span>
                <span :style="secondaryTextColor" class="text-xl sm:text-1xl font-bold block">1m</span>
            </li>
            <li class="w-full sm:w-auto">
                <span class="font-bold">No. of questions</span>
                <span :style="secondaryTextColor" class="text-xl sm:text-1xl font-bold block">0</span>
            </li>
        </ul>
        <div class="flex gap-4 mt-6">
            <div class="btn btn-tertiary" :style="{ backgroundColor: props.accentColour }">Enter</div>
            <div class="btn btn-outline-white">How to play</div>
        </div>
    </div>
</template>

<style scoped>


</style>
