<template>
    <Label :label="label" :position="labelPosition" :has-error="hasErrors" :error-message="getErrorMessage">
        <div class="relative" ref="colourPickerRef">
            <div @click="toggleColourPicker()" :style="`background-color: ${colourValue}`"
                 class="w-10 h-10 rounded-full border border-grey-200 shadow-md" :class="[{'border-red-200': hasErrors}]"></div>
            <div class="absolute z-10" v-if="isToggled">
                <Vue3ColorPicker v-model="colourValue" :showAlpha="false" :showPickerMode="false" :showEyeDrop="false"
                                 :showColorList="false" :showInputMenu="false" mode="solid" type="HEX" inputType="rgb"/>
            </div>
        </div>
        <input type="hidden" :value="colourValue" :name="name">
    </Label>
</template>
<script setup>
import '@cyhnkckali/vue3-color-picker/dist/style.css'
import Label from "./Label.vue";
import {Vue3ColorPicker} from "@cyhnkckali/vue3-color-picker";
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    modelValue: String,
    value: {
        type: String,
        default: '',
    },
    labelPosition: {
        type: String,
        default: 'left',
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const colourPickerRef = ref(null);
const colourValue = ref(props.modelValue);
const isToggled = ref(false);

const toggleColourPicker = () => {
    isToggled.value = !isToggled.value;
}

const handleClickOutside = (event) => {
    if (colourPickerRef.value && !colourPickerRef.value.contains(event.target)) {
        isToggled.value = false;
    }
}

onMounted(() => {
    window.document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
    window.document.removeEventListener('click', handleClickOutside);
});

// Watch for changes in the modelValue prop to update the colourValue ref
watch(() => props.modelValue, (newValue) => {
    colourValue.value = newValue;
});

// Watch for changes in the colourValue ref and emit them to the parent component
watch(colourValue, (newValue) => {
    emit('update:modelValue', newValue);
});
</script>
