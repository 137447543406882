<template>
    <button type="button" class="btn" :disabled="isLoading" :class="isLoading ? 'btn--is-loading' : ''" @click="emit('click')">
        <span class="loader" v-if="isLoading"></span>
        <slot></slot>
    </button>
</template>
<script setup>

const emit = defineEmits(['click'])

const props = defineProps({
    isLoading: {
        type: Boolean,
        default: false,
    },
})
</script>
