<template>
    <div class="mb-5 flex flex-col gap-2 justify-center" v-if="!isQuizClosed">
        <button
            v-if="hasCurrentRound && !currentRound.startDate && !hasRoundStarted && !hasRoundEnded"
            :disabled="isActionLoading('startRound')"
            @click="handleAction('startRound')"
            class="btn btn-tertiary btn--large"
        >
            <i class="fas fa-stopwatch mr-2"></i>
            {{ getButtonText('startRound') }}
        </button>
        <button
            v-else-if="hasCurrentRound && currentRound.startDate && !hasRoundStarted && !hasRoundEnded"
            :disabled="isActionLoading('cancelRound')"
            @click="handleAction('cancelRound')"
            class="btn btn-tertiary btn--large"
        >
            {{ getButtonText('cancelRound') }}
        </button>
        <button
            v-else-if="hasRoundStarted && !hasRoundEnded"
            :disabled="isActionLoading('closeRound')"
            @click="handleAction('closeRound')"
            class="btn btn-tertiary btn--large"
        >
            {{ getButtonText('closeRound') }}
        </button>
        <button
            v-else-if="typeof nextRound !== 'undefined'"
            :disabled="isActionLoading('nextRound')"
            @click="handleAction('nextRound')"
            class="btn btn btn-tertiary btn--large"
        >
            {{ getButtonText('nextRound') }}
        </button>
        <button
            v-else
            :disabled="isActionLoading('closeQuiz')"
            @click="handleAction('closeQuiz')"
            class="btn btn btn-tertiary btn--large"
        >
            {{ getButtonText('closeQuiz') }}
        </button>
    </div>
    <button
        v-if="getEnv === 'local'"
        :disabled="isActionLoading('resetQuiz')"
        @click="handleAction('resetQuiz')"
        class="btn btn btn-red btn--large w-full"
    >
        {{ getButtonText('resetQuiz') }}
    </button>
</template>
<script setup>
import {useConfigStore} from "../../../stores/config";
import {RoundData} from "../../../composibles/roundData.js";
import {QuizData} from "../../../composibles/quizData.js";
import {QuizAdminActions} from "../../../composibles/quizAdminActions.js";

const {getEnv} = useConfigStore();

const {isQuizClosed, currentRound, nextRound} = QuizData();
const {hasRoundStarted, hasRoundEnded, hasCurrentRound} = RoundData();
const {startRound, closeRound, loadNextRound, resetQuiz, cancelRound, closeQuiz, getCurrentAction} = QuizAdminActions();

const handleAction = async (action) => {
    switch (action) {
        case 'startRound' :
            await startRound();
            break;
        case 'cancelRound' :
            await cancelRound();
            break;
        case 'closeRound' :
            await closeRound();
            break;
        case 'nextRound' :
            await loadNextRound();
            break;
        case 'closeQuiz' :
            await closeQuiz();
            break;
        case 'resetQuiz' :
            if (confirm('Are you sure you want to restart the quiz. All participant progress will be deleted.')) {
                await resetQuiz()
            }
            break;
    }
}

const isActionLoading = (action)  =>{
    return getCurrentAction.action === action;
}

const getButtonText = (action) => {
    if (isActionLoading(action)) {
        return getCurrentAction.text;
    }
    if (action === "startRound") {
        return "Start Round";
    }
    else if (action === "cancelRound") {
        return "Cancel Round";
    }
    else if (action === "nextRound") {
        return "Load Next Round";
    }
    else if (action === "closeRound") {
        return "Close Round";
    }
    else if (action === "resetQuiz") {
        return "Restart Quiz";
    }
    else {
        return "Close Quiz";
    }
}
</script>
