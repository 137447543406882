<template>
    <div v-if="!this.pinchSubmitted && !this.timeExpired">

        <div class="text-center mb-4">
            <CountdownTimer
                :start-date="this.startDate"
                :end-date="this.endDate"
                type="small"
                @timer-ended="handleTimeExpired"
            />
        </div>

        <div class="bg-neutral text-sm rounded p-3 font-medium text-center mb-2">{{ this.message }}</div>

        <div class="mb-12 text-center panel panel--no-shadow border border-grey-200 font-sans mb-2.5">
            <span class="tag text-secondary">Question</span>
            {{ question }}
        </div>

        <Search
            placeholder="Search participants..."
            @search="filterParticipants"
        />

        <Form @on-submit="handleFormSubmit" :id="id" :errors="errors">
            <ParticipantAnswersTable
                :participant-answers="getFilteredParticipants"
                :answer-id="answerId"
                @answerSelected="(answer) => setAnswerId(answer.answer_id)"
            />
            <div class="text-center mb-8">
                <button
                    type="submit"
                    class="btn btn-primary disabled:btn-grey-200"
                    :disabled="answerId === null">
                    Pinch Answer
                </button>
            </div>
        </Form>
    </div>
    <div v-else>
        {{ this.noticeMessage }}
    </div>
</template>
<script>
import api from "../../../api/axios-api";
import CountdownTimer from "../Global/CountdownTimer.vue";
import Search from "../../Global/Search.vue";
import Form from "../../Form/Form.vue";
import ParticipantAnswersTable from "./ParticipantAnswersTable.vue";


export default {
    components: {
        ParticipantAnswersTable,
        Form,
        Search,
        CountdownTimer,
    },
    name: "PowerUpPinchForm",

    props: {
        questionId: false,
        message: '',
        startDate: false,
        endDate: false,
        question: String,
        answers: {
            type: Array,
            default: [
                {
                    name: '',
                    participant_id: null,
                    time_taken: null
                }
            ]
        },
    },
    data() {
        return {
            id: 'PowerUpPinchForm',
            answerId: null,
            searchTerm: '',
            pinchSubmitted: false,
            modalHideTimeout: null,
            timeExpired: false,
            noticeMessage: ''
        }
    },
    beforeDestroy() {
        if (this.modalHideTimeout) {
            clearTimeout(this.modalHideTimeout);
        }
    },
    computed: {
        getFilteredParticipants() {
            if (this.searchTerm.length > 0) {
                return this.answers.filter(a => a.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
            } else {
                return this.answers;
            }
        },
    },
    methods: {
        handleFormSubmit(e) {
            let self = this;
            e.preventDefault();

            api.post('/quiz/' + this.getQuizId + '/round/' + this.currentRound.id + '/participant/' + this.participant.id + '/powerup', {
                'action': 'pinch',
                'question_id': this.questionId,
                'answer_id': this.answerId,
            })
            .then(function (response) {
                self.pinchSubmitted = true;

                this.$store.commit('setNotice', {
                    message: response.data.message,
                    type: 'success'
                });

                this.modalHideTimeout = setTimeout(function () {
                    self.$modal.hide('power-up-pinch-form');
                }, 4000);

            }).catch(error => {
                this.setFormErrors(error);
        });
        },
        setAnswerId(id) {
            this.answerId = id;
        },
        handleTimeExpired() {
            let self = this;
            self.timeExpired = true;
            self.noticeMessage = 'Time Expired!'
            this.modalHideTimeout = setTimeout(function () {
                self.$parent.$modal.hide('power-up-pinch-form');
            }, 4000);
        },
        filterParticipants(e) {
            this.searchTerm = e.target.value;
        }
    },
}
</script>
