<template>
    <button @click="handleClick" class="btn btn-tertiary w-full rounded-t-xl !rounded-b-none flex gap-4 items-center justify-center">
        <span>Jump to answers</span>
        <i class="fas fa-long-arrow-alt-down"></i>
    </button>
</template>

<script setup>
    const emit = defineEmits(['clicked']);
    const handleClick = () => {
        emit('clicked');
    }
</script>

<style scoped>

</style>
