<template>
    <div class="h-full">
        <div v-if="isScreenLoading" class="flex flex-col h-screen justify-center items-center">
            <FadeLoader
                :loading="isScreenLoading"
                color="#E8E7EB"
            ></FadeLoader>
        </div>
        <div v-else class="quiz-screen h-full">
            <div class="login-screen h-full">
                <router-link
                    class="nav-link block my-6"
                    data-toggle="collapse"
                    :to="{ name: 'home' }">
                    <img v-if="data.customLogoSrc.length > 0" alt="" :src="data.customLogoSrc" class="h-11 mx-auto">
                    <img v-else alt="Quick As You Can" :src="asset('images/logo.svg')" class="w-32 mx-auto">
                </router-link>
                <div class="mx-auto max-w-[34rem] px-8">
                    <div class="panel">
                        <div class="text-center mb-6">
                            <h1 class="mb-2 text-lg">{{quiz.title}}</h1>
                            <p class="text-xs text-grey-500">Hosted by: {{quiz.createdBy}}</p>
                        </div>
                        <DonationForm :quiz-id="quiz.id"/>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {ScreenData} from "../../../composibles/screenData.js";
import {QuizData} from "../../../composibles/quizData.js";
import Footer from "../../../layouts/Footer.vue";
import {useUserStore} from "../../../stores/user.js";
import {ParticipantData} from "../../../composibles/participantData.js";
import DonationForm from "../../../components/Quiz/Payment/DonationForm.vue";
const userStore = useUserStore();

const {setQuizData, quiz} = QuizData();
const {setIsLoading, isScreenLoading} = ScreenData();
const {maybeParticipateInRound} = ParticipantData();

const props = defineProps({
    quizData: Object
})

const data = ref({
    customLogoSrc: ''
});

const setState = async () => {
    setIsLoading(true);
    await setQuizData(props.quizData);
    setIsLoading(false);
}

onMounted(async () => {
    await setState();
});
</script>
