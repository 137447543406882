<template>
    <div class="panel">
        <div class="flex justify-between mb-8">
            <h1 class="text-xl">My Plan</h1>
        </div>

        <div v-if="data.isLoading" class="flex flex-col min-h-[36rem] justify-center items-center">
            <FadeLoader
                :loading="data.isLoading"
                color="#E8E7EB"
            />
        </div>
        <div v-else>
            <div class="flex space-x-4 justify-between">
                <PlanCard v-for="plan in PAYMENT_PLANS"
                          :title="plan.title"
                          :is-current-plan="userHasRole(plan.name)"
                          :benefits="plan.benefits"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "vue-toast-notification";
import PlanCard from "./PaymentPlans/PlanCard.vue";
import {PAYMENT_PLANS} from "../../utils/constants.js";
import {useUserData} from "../../composibles/userData.js";

const data = ref({
    isLoading: false,
});

const {userHasRole} = useUserData();

const router = useRouter();
const route = useRoute();
const $toast = useToast();

const handleUpgradePlan = () => {
    console.log('success');
}

</script>
