<template>
    <div class="panel mb-8">
        <p class="tag text-center mb-6">{{ title }}</p>

        <div class="text-center pt-4 relative top-5" v-if="isRefreshable">
            <button @click="refreshAnswers" class="btn btn-primary btn--with-icon items-center py-2 px-4">
                Refresh Answers <span class="rounded tag bg-neutral text-primary px-1.5 py-0.5 mb-0 ml-2"
                                      v-if="refreshCount > 0">{{ refreshCount }}</span>
            </button>
        </div>

        <div v-for="(question, index) in answers">
            <div class="mb-12 panel panel--no-shadow border border-grey-200 font-sans"
                 :class="question?.is_new ? 'fade-out-bg' : ''">
                <div class="flex flex-wrap md:flex-nowrap md:gap-8">
                    <div class="w-full">
                        <p class="inline-block text-secondary font-bold mb-4">Q{{ index + 1 }}</p>
                        <h3 class="mb-4 font-bold select-none text-md sm:text-lg" onpaste="return false;"
                            oncopy="return false;">
                            {{ question.question }}</h3>
                        <ul>
                            <li v-for="(answer, index) in question.answers" class="flex flex-wrap items-center mb-2">
                                <div
                                    class="flex gap-1 flex-wrap border border rounded-md px-4 py-3 justify-between items-center w-full md:w-7/12 text-sm sm:text-base"
                                    :class="answer.is_correct ? 'bg-green-100 border-green-200' : question.participant_answer_id === answer.id && !answer.is_correct ? 'bg-red-100 border-red-200' : 'border-grey-200'">
                                    <span>
                                        {{ answer.answer }}
                                     </span>
                                    <div class="flex gap-2">
                                      <span
                                          v-if="question.participant_answer_id && question.participant_answer_id === answer.id && !answer.is_correct"
                                          class="rounded tag px-1.5 py-1 flex items-center text-white text-tiny mb-0 bg-red-200"
                                      >
                                        <i class="fas fa-times-circle mr-1"></i>
                                        Your answer
                                    </span>
                                        <span v-if="answer.is_correct"
                                              class="rounded tag px-1.5 py-1 bg-green-200 flex items-center text-white text-tiny mb-0">
                                        <i class="fas fa-check-circle mr-1"></i> Correct
                                    </span>
                                    </div>
                                </div>
                                <span class="mt-2 md:mt-0 md:pl-3 font-bold opacity-70 text-xs sm:text-sm w-1/12">
                                    {{ getAnswerPercentageAnswered(answer.answer_count, question.total_count) }}%
                                </span>
                                <span v-if="answer.participants" class="flex gap-0.5 mt-2 md:mt-0 mb-2 md:mb-0"
                                      @click="loadMoreParticipantsAnswers(question.question, question.question_id, answer.id)">
                                    <ProfilePic v-for="(name, id) in answer.participants" :key="id"
                                                :name="name" class="bg-primary !text-tiny w-6 h-6 relative"/>

                                    <template v-if="getAdditionalParticipantAnswerCount(answer) > 0">
                                        <span
                                            class="self-center hover:cursor-pointer text-primary font-bold ml-2 text-grey-400 text-tiny relative">+ {{
                                                getAdditionalParticipantAnswerCount(answer)
                                            }}</span>
                                    </template>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div v-if="question.image" class="w-full md:w-4/12">
                        <img class="rounded-md mb-4 md:mb-0" alt="" :src="question.image">
                    </div>
                </div>

                <div v-if="typeof question.fact !== 'undefined' && question.fact"
                     class="mt-2 sm:mt-5 border-secondary bg-neutral border p-3 sm:p-6 rounded-md text-md">
                    <i class="fas fa-info-circle mb-3"></i>
                    <p class="tag text-secondary">Did you know?</p>
                    {{ question.fact }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import ParticipantAnswersPopup from "./ParticipantAnswersPopup.vue";
import ProfilePic from "../Participant/ProfileIcon.vue";
import {computed} from "vue";
import {useModal, useModalSlot} from "vue-final-modal";
import Modal from "../../Global/Modal.vue";

const emit = defineEmits(['refresh']);

const props = defineProps({
    title: {
        type: String,
        default: 'Round Answers'
    },
    refreshCount: {
        type: Number,
        default: 0
    },
    answers: {
        type: Array,
        default: []
    }
});

const isRefreshable = computed(() => {
    return props.refreshCount > 0;
});

const refreshAnswers = () => {
    emit('refresh');
}
const getAnswerPercentageAnswered = (answerCount, totalCount) => {
    return Math.round(parseInt(answerCount) / parseInt(totalCount) * 100);
};

const getAdditionalParticipantAnswerCount = (answer) => {
    return parseInt(answer.answer_count) - Object.keys(answer.participants).length;
}

const loadMoreParticipantsAnswers = (question, questionId, answerId) => {
    const { open, close } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel',
            clickToClose: true,
            adaptive: true,
            draggable: false,
            scrollable: true,
            height: 'auto',
            width: '100%',
            maxWidth: 550,
        },
        slots: {
            default: useModalSlot({
                component: ParticipantAnswersPopup,
                attrs: {
                    question: question,
                    questionId: questionId,
                    answerId: answerId,
                },
                onClose() {
                    close()
                },
            })
        }
    });
    open();
}
</script>
