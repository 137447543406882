import {prepareRound, useQuizStore} from "../stores/quiz";
import {computed, ref, watch} from "vue";
import {useParticipantStore} from "../stores/participant";
import api from "../api/axios-api";
import {QuizData} from "./quizData";
import {useScreenStore} from "../stores/screen.js";

export function ParticipantData() {
    const screenStore = useScreenStore();
    const {quiz, isQuizOpen, currentRound, isQuizClosed} = QuizData();
    const participantStore = useParticipantStore();

    const answerQueueInterval = ref(null);

    const participant = computed(() => {
       return participantStore.participant;
    });

    const isParticipant = computed(() => {
        return typeof participantStore.participant !== 'undefined' && participantStore.participant.id !== null;
    });

    const isParticipatingInRound = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        return typeof participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id) !== 'undefined';
    });

    const getRoundParticipant = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        return participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id) ?? false;
    });

    const hasTimeStarted = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        let roundStartTime;

        if (quiz.value.automaticStart) {
            roundStartTime = participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id)?.startDate ?? false;
        } else {
            roundStartTime = currentRound?.value.startDate ?? false;
        }
        return roundStartTime ? new Date(roundStartTime) <= screenStore.now : false;
    });

    const hasTimeExpired = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        return participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id)?.expired ?? false;
    })

    const hasCompletedRound = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        return participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id)?.completed ?? false;
    });

    const roundAnswersFinishedProcessing = computed(() => {
        if (typeof currentRound.value === 'undefined') return false;
        return participantStore.participant.rounds.find((r) => r.roundId === currentRound.value.id)?.answerJobStatus === 'completed' ?? false;
    });

    const maybeParticipateInRound = async ()  => {
        if (!isParticipatingInRound.value && currentRound.value?.id) {
            await participantStore.participateInRound({
                quizId: quiz.value.id,
                roundId: currentRound.value.id,
                participantId: participant.value.id,
            });
        }
    }

    const setQuizParticipantData = async (participantData)=> {
        await participantStore.loadParticipant({
            participant: participantData,
            quizId: quiz.value.id,
            shouldJoinPresenceChannel: !quiz.value.automaticStart
        });

        if (participantData.participant_id) {
            subscribeToParticipantChannelEvent()
        }
    }

    const maybeSendHeartbeatResponse = async ()=> {
        const maxRetries = 3;
        let retries = 0;

        const sendHeartbeatWithRetry = async () => {
            try {
                await sendHeartbeat();
            } catch (error) {
                retries++;
                console.error(`Error sending heartbeat (Retry ${retries} of ${maxRetries}):`, error.message);

                if (retries < maxRetries) {
                    // Retry after a delay (e.g., 5 seconds)
                    setTimeout(async () => {
                        await sendHeartbeatWithRetry();
                    }, 5000);
                } else {
                    throw new Error(`Maximum retries (${maxRetries}) reached. Unable to send heartbeat.`);
                }
            }
        };

        if (participantStore.participant.id && isQuizOpen && !isQuizClosed && quiz.value.automaticStart) {
            await sendHeartbeatWithRetry();
            this.heartBeatInterval = setInterval(async () => {
                await sendHeartbeatWithRetry();
            }, 10000);
        }
    }


    const sendHeartbeat = async ()=> {
        try {
            await api.post(`/quiz/${quiz.value.id}/participant/${participantStore.participant.id}/heartbeat`);
            console.log('Heartbeat sent successfully');
        } catch (error) {
            console.error('Error sending heartbeat:', error.message);
        }
    }

    const subscribeToParticipantChannelEvent = () => {
        Echo.private(`quiz.${quiz.value.id}.participant.${participant.value.id}`)
            .listen('.QuizRoundParticipantUpdateEvent', (data) => {
                participantStore.setRoundAnswerJobStatus({
                    roundId: data.roundId,
                    status: data.data.answer_job_status
                });
            }).subscribed(() => {
            console.log('Subscribed');
        }).error((error) => {
            console.error(error);
        })
    }

    // Watch for changes to the hasTimeExpired computed property
    watch(hasTimeExpired, async (newValue, oldValue) => {
        if (newValue && !oldValue) {
            if (getRoundParticipant.value.answerJobStatus !== 'completed') {
                answerQueueInterval.value = setInterval(async () => {
                    await checkAnswerJobStatus();
                }, 5000);
            }
        }
    });

    const checkAnswerJobStatus = async ()=> {
        try {
            const response = await api.post(`/quiz/${quiz.value.id}/round/${currentRound.value.id}/participant/${participantStore.participant.id}/process_answers`);
            if (response.data.success) {
                clearInterval(answerQueueInterval.value);
            }
        } catch (error) {
            console.error('Error sending heartbeat:', error.message);
        }
    }


    const resetParticipantStore = () => {
        participantStore.$reset();
    }

    return {
        participant,
        isParticipant,
        isParticipatingInRound,
        getRoundParticipant,
        hasTimeStarted,
        hasTimeExpired,
        hasCompletedRound,
        roundAnswersFinishedProcessing,
        maybeParticipateInRound,
        setQuizParticipantData,
        maybeSendHeartbeatResponse,
        sendHeartbeat,
        subscribeToParticipantChannelEvent,
        resetParticipantStore
    }
}

