<template>
    <div>
        <div class="mb-4" v-if="quizStore.getRoundWinner">
            <QuizWinner v-if="quizStore.getRoundWinner"
                        :name="quizStore.getRoundWinner.name"
                        title="Round winner is"
            />
        </div>
        <div v-if="isRoundWaitingToStart" class="mb-4">
            <UpcomingRound/>
        </div>
        <div class="mb-4">
            <Leaderboard/>
        </div>

        <Answers
            :answers="answers"
        />
    </div>
</template>

<script setup>
import api from "../../../api/axios-api";
import {onMounted, ref} from "vue";
import Answers from "../../../components/Quiz/Round/Answers.vue";
import QuizWinner from "../../../components/QuizWinner.vue";
import UpcomingRound from "../../../components/Quiz/Round/UpcomingRound.vue";
import {RoundData} from "../../../composibles/roundData.js";
import {QuizData} from "../../../composibles/quizData.js";
import Leaderboard from "../../../components/Quiz/Global/Leaderboard.vue";
import {useQuizStore} from "../../../stores/quiz.js";


const quizStore = useQuizStore();
const {quiz, currentRound} = QuizData();
const {isRoundWaitingToStart, getRoundWinner} = RoundData();

const answers = ref([]);


const loadAnswers = async () => {
    const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/answers');
    answers.value = response.data.answers;
}

onMounted(async () => {
    await loadAnswers();
})
</script>
