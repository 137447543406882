<template>
    <div class="panel my-6 flex flex-wrap justify-between items-center bg-primary border border-secondary">
        <div class="mb-4 sm:mb-0">
            <h4 class="tag text-white text-branded-default-dark">Final results will be shown</h4>
            <p class="block text-secondary text-branded-default-dark font-bold">{{ currentRound.endDate }}</p>
        </div>
<!--        <button class="btn btn-outline-secondary btn&#45;&#45;large text-white" @click="notify"><i class="fas fa-bell mr-2"></i> Notify me</button>-->
    </div>
</template>

<script setup>
import {QuizData} from "../../../../composibles/quizData.js";
import {useModal, useModalSlot} from "vue-final-modal";
import Modal from "../../../Global/Modal.vue";
import RecieveNotifications from "../../../Account/RecieveNotifications.vue";

const {quiz, currentRound} = QuizData();

const notify = () => {
    const { open, close } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel !bg-primary',
            closable: true,
            draggable: false,
            header: 'Subscribe to Quiz Notifications'
        },
        slots: {
            default: useModalSlot({
                component: RecieveNotifications,
                attrs: {

                },
                onClose() {
                    close()
                },
            })
        }
    });
    open();
}
</script>
