<template>
    <div>
        <Label :label="label" :has-error="hasErrors" :error-message="getErrorMessage">
            <select :id="name" :name="name" v-model="selectedTimezone" class="block py-4 px-3.5 text-md font-medium font-sans border border-grey-300 rounded-lg">
                <option value="">{{ placeholder }}</option>
                <optgroup v-for="(timezones, continent) in timezonesByContinent" :label="continent">
                    <option v-for="timezone in timezones" :value="timezone.key">{{ timezone.label }}</option>
                </optgroup>
            </select>
        </Label>
    </div>
</template>

<script setup>
import Label from "./Label.vue";
import {computed, ref} from "vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: 'Select a timezone'
    },
    timezones: {
        type: Array,
        required: true
    },
    modelValue: {
        type: String,
        default: '',
    },
    errors: {
        type: Object,
        default: () => ({})  // Ensure errors defaults to an empty object
    }
});

const hasErrors = computed(() => {
    return props.errors[props.name]?.length > 0;
});

const getErrorMessage = computed(() => {
    return props.errors[props.name]?.length ? props.errors[props.name][0] : ''
});

const selectedTimezone = ref(props.modelValue || '')

const  timezonesByContinent = computed(() => {
    return props.timezones.reduce((result, zone) => {
        const [continent, city] = zone.split('/');
        result[continent] = result[continent] || [];
        result[continent].push({
            key: zone,
            label: city ? city.replace('_', ' ') : zone.slice(zone.lastIndexOf('/') + 1).replace('_', ' ')
        });
        return result;
    }, {});
})
</script>
