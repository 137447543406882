<template>
    <Form @submit="handleSubmit" :error-message="errorMessage">
        <h1 class="text-xl mb-4">Reset password</h1>

        <div class="mt-8 mb-8">
            <TextInput
                label="New Password"
                v-model="data.password"
                name="password"
                type="password"
                placeholder="Enter Password"
                :errors="errors"
            />

            <TextInput
                label="Confirm Password"
                v-model="data.password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Re-enter Password"
                :errors="errors"
            />
        </div>

        <div class="flex flex-col items-center">
            <button type="submit"
                    class="btn btn-secondary text-white" :disabled="data.isLoading" :class="data.isLoading ? 'btn--is-loading' : ''">
                <span class="loader" v-if="data.isLoading"></span> Reset Password
            </button>
        </div>
    </Form>
</template>

<script setup>
import api from "../../api/axios-api";
import Form from "../Form/Form.vue";
import TextInput from "../Form/TextInput.vue";
import {useRoute} from "vue-router";
import {ref} from "vue";
import {useFormData} from "../../composibles/formData.js";

const route = useRoute();

const emit = defineEmits(['success']);

const {errors, errorMessage, setFormErrors} = useFormData();

const data = ref({
    id: 'ResetPasswordForm',
    password: '',
    password_confirmation: '',
    isLoading: false
});

const handleSubmit = (e) => {
    data.value.isLoading = true;
    e.preventDefault();
    api.post('/reset-password', {
        email: route.params.email,
        password: data.value.password,
        password_confirmation: data.value.password_confirmation,
        token: route.params.token
    })
        .then(response => {
            emit('success');
        })
        .catch(error => {
            setFormErrors(error);
        })
        .finally(() => {
            data.value.isLoading = false;
        })
}
</script>
