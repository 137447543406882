<template>
    <div v-if="question !== false && question.question.length" class="panel">
        <div class="w-full lg:w-10/12">
            <p class="tag">
                Question {{ currentQuestion }} of {{ getQuestionCount }}
            </p>

            <ProgressBar
                :progress="currentProgress"
                :primary-colour="getQuizThemeColours.primary"
                :secondary-colour="getQuizThemeColours.accent"
            />

            <Form ref="formRef" @on-submit="formSubmit">
                <div class="grid grid-cols-1 gap-6">
                    <div class="question">
                        <div class="flex flex-wrap md:flex-nowrap">
                            <div v-if="question.upload.url" class="md:w-6/12 flex-grow md:mr-4">
                                <div v-if="question.upload.file_type === 'audio/mpeg'">
                                    <audio controls>
                                        <source :src="question.upload.url" :type="question.upload.file_type">
                                        Your browser does not support the audio tag.
                                    </audio>
                                </div>
                                <div v-else>
                                    <img alt="" class="mb-8 rounded-md" :src="question.upload.url">
                                </div>
                            </div>
                            <h3 class="text-lg mb-6 md:mb-9 md:text-xl w-6/12 flex-grow select-none"
                                onpaste="return false;" oncopy="return false;">{{ question.question }}</h3>
                        </div>
                        <div v-for="answer in question.answers">
                            <input required
                                   type="radio"
                                   name="answer_id"
                                   class="sr-only"
                                   :id="'answer_' + answer.id"
                                   v-model="answer_id"
                                   :value="answer.id"
                                   @click="handleFormSubmit(answer.id)"
                            >
                            <label :for="'answer_' + answer.id"
                                   class="block relative mb-4 py-3 px-2 md:py-5 md:px-4 text-md md:text-lg font-medium font-sans border border-grey-200 p-3 rounded-lg cursor-pointer transition-all md:hover:bg-neutral md:hover:border-secondary">
                                <span class="text-gray-900 ml-1">{{ answer.answer }}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="mt-8" v-if="currentRound.powerUps?.length">
                    <h4 class="tag mb-4 flex">
                        Power ups:</h4>
                    <div class="flex items-start">
                        <div class="flex gap-4">
                            <label v-for="powerUp in currentRound.powerUps"
                                   @mouseover="showPowerUpToolTip(powerUp.instruction)"
                                   @mouseleave="clearPowerUpToolTip"
                                   :class="getPowerUpClass(powerUp)"
                                   class="text-center cursor-pointer">
                                <span
                                    class="block power-up-icon rounded-full w-14 h-14 p-4 shadow-lg mb-1 border-2 border-transparent hover:border-secondary">
                                    <img alt="" :src="'/images/' + powerUp.name.toLowerCase() + '.svg'"
                                         class="w-full h-full">
                                </span>
                                <span class="font-bold text-sm">{{ powerUp.name }}</span>
                                <input type="radio"
                                       :value="powerUp.id"
                                       class="sr-only peer"
                                       name="powerup_id"
                                       @click="handlePowerUp(powerUp.id)">
                            </label>
                        </div>
                        <div class="powerup-tooltip bg-neutral text-sm rounded p-3 ml-4 max-w-xs font-medium shadow-lg"
                             v-if="powerUpToolTip?.length">
                            {{ powerUpToolTip }}
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>
<script setup>

import api from "../../../api/axios-api";
import ProgressBar from "../Round/ProgressBar.vue";
import Form from "../../Form/Form.vue";
import {QuizData} from "../../../composibles/quizData.js";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {ParticipantData} from "../../../composibles/participantData.js";
import {useParticipantStore} from "../../../stores/participant.js";

const {quiz, currentRound, getQuizThemeColours} = QuizData()
const {getRoundParticipant, participant} = ParticipantData();

const participantStore = useParticipantStore();

const currentQuestion = ref(0);

const formRef = ref(null);

const data = ref({
    id: 'QuestionAnswerForm',
    loaded: false,
    processing: false,
    answer_id: '',
    power_up_id: false,
    powerUpToolTip: '',
    usedPowerUps: []
});

const question = ref({
    question: '',
    question_id: '',
    upload: {
        url: '',
        file_type: ''
    },
    answers: {
        id: '',
        answer: '',
    }
})

const getQuestionCount = computed(() => {
    return currentRound.value.questionCount;
});

const getAvailablePowerUps = computed(() => {
    return getRoundParticipant.value.availablePowerUps;
});

const currentProgress = computed(() => {
    return (currentQuestion.value - 1) / currentRound.value.questionCount * 100
});


const getPowerUpClass = (powerUp) => {
    let className = '';
    if ((powerUp.name === 'Push' && currentQuestion.value === getQuestionCount.value) ||
        getAvailablePowerUps.value.map((p => p.id)).includes(powerUp.id) === false ||
        props.usedPowerUps.includes(powerUp.id)) {
        className = 'disabled';
    }

    return className;
}

const showPowerUpToolTip = (description) => {
    data.value.powerUpToolTip = description;
}

const clearPowerUpToolTip = () => {
    data.value.powerUpToolTip = '';
}

const removeSelectedAnswerFromCache = (e) => {
    e.preventDefault();
    return e.returnValue = "Are you sure you want to exit?";
}
window.addEventListener('beforeunload', removeSelectedAnswerFromCache);

const loadQuestion = async () => {
    try {
        const response = await api.get('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/participant/' + participant.value.id + '/question');

        prepareQuestion(response.data.question);


        currentQuestion.value = response.data.current_question;
    } catch (error) {
        console.error(error);
    }
}

const formSubmit = async () => {
    if (data.value.processing === true) {
        return;
    }
    try {
        const response = await api.post('/quiz/' + quiz.value.id + '/round/' + currentRound.value.id + '/participant/' + participant.value.id + '/answer', {
            'question_id': question.value.question_id,
            'answer_id': data.value.answer_id,
            'power_up_id': data.value.power_up_id
        });

        if (response.data.success) {
            if (data.value.power_up_id) {
                data.value.usedPowerUps.push(data.value.power_up_id);
            }

            if (response.data.next_question.question) {
                prepareQuestion(response.data.next_question.question);
            } else {
                question.value = false;
                participantStore.setRoundAsCompleted({
                    roundId: currentRound.value.id,
                })
            }

            currentQuestion.value++;
            data.value.processing = false;
            data.value.answer_id = '';
            data.value.power_up_id = null;
        }
    } catch (error) {
        data.value.processing = false;
    }
}

const handlePowerUp = (id) => {
    data.value.power_up_id = id;
    this.$refs.form.onsubmit = formSubmit();
}

const handleFormSubmit = (answerId) => {
    data.value.answer_id = answerId;
    formRef.value.onsubmit = formSubmit();
}

const prepareQuestion = (questionData) => {
    question.value.question = questionData.question;
    question.value.question_id = questionData.id;
    question.value.answers = questionData.answers;

    if (questionData.upload !== null) {
        question.value.upload.url = questionData.upload.url;
        question.value.upload.file_type = questionData.upload.file_type;
    } else {
        question.value.upload.url = '';
        question.value.upload.file_type = '';
    }
    data.value.loaded = true;
}

onMounted(async () => {
    await loadQuestion();
});

onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', removeSelectedAnswerFromCache);
})
</script>
