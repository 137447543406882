<template>
    <div>
        <div class="panel">
            <div class="flex items-end mb-8">
                <h1 class="text-xl"><span>Round {{data.roundOrderId}}:</span> {{ data.roundTitle }}</h1>
                <router-link
                    :to="{
                            name: 'account/my-quizzes/edit-round',
                            params: { quizId: getRouteQuizId, roundId: getRouteRoundId}}"
                    class="text-grey-300 ml-2">
                    <i class="far fa-edit"></i> Edit
                </router-link>
            </div>
            <div id="questions">
                <h2 class="tag">Questions ({{questionCount}})</h2>
                <div class="flex justify-between items-center mb-2 bg-grey-100 rounded font-bold px-5 py-3.5 text-sm items-center justify-between"
                     v-for="(question, question_index) in editRoundQuestionsStore.getQuestions">
                    <div class="flex items-center">
                        <img alt="" v-if="question.upload?.url" :src="question.upload?.url" class="w-10 h-10 mr-4 rounded-sm object-cover">
                        <span class="truncate max-w-[34rem]">{{ question.question }}</span>
                    </div>
                    <div class="flex gap-0.25">
                        <router-link  :to="{
                            name: 'account/my-quizzes/edit-question',
                            params: { quizId: getRouteQuizId, roundId: getRouteRoundId, questionId: question.id}}">
                            <button type="button" class="ml-2 bg-primary text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                                <i class="fas fa-pencil-alt" aria-label="Edit Question"></i>
                            </button>
                        </router-link>

                        <button type="button" @click="editRoundQuestionsStore.deleteQuestion(question.id, getRouteQuizId ,getRouteRoundId)"
                                class="ml-2 bg-primary text-white p-1 rounded-full text-md min-w-[32px] min-h-[32px]">
                            <i class="far fa-trash-alt" aria-label="Delete Question"></i>
                        </button>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center mt-8">
                    <router-link
                        :to="{ name: 'account/my-quizzes/add-question', params: { quizId: route.params.quizId, roundId: route.params.roundId }}"
                        class="btn btn-primary">
                        <i class="fas fa-plus mr-1"></i>Add question
                    </router-link>
                    <span class="my-2 text-grey-300 text-sm">or</span>
                    <button @click="openQuestionLibrary" class="btn btn-tertiary">
                        <i class="fas fa-search mr-1"></i>
                        Browse Question library
                    </button>
                    <span class="my-2 text-grey-300 text-sm">or</span>
                    <label class="btn btn-grey-200">
                        <i class="fas fa-upload mr-1"></i>
                        Upload Questions
                        <input type="file" @change="handleFileUpload" style="display:none;">
                    </label>
                </div>
                <div class="mt-8">
                    <router-link
                                 :to="{ name: 'account/my-quizzes/view-quiz', params: {quizId: route.params.quizId}}"
                                 class="btn btn-primary">
                        Go back
                    </router-link>
                    <router-link v-if="editRoundQuestionsStore.getQuestions.length > 0"
                        :to="{ name: 'account/my-quizzes/view-quiz', params: {quizId: route.params.quizId}}"
                        class="btn btn-tertiary">
                        Save
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {useEditRoundQuestions} from "../../../stores/quiz-edit-round-questions";
import api from "../../../api/axios-api";
import {useNoticesStore} from "../../../stores/notices";
import {useRoute} from "vue-router";
import {useModal, useModalSlot} from "vue-final-modal";
import Modal from "../../Global/Modal.vue";
import QuestionLibrary from "../../QuestionLibrary/QuestionLibrary.vue";
import {useToast} from "vue-toast-notification";

const route = useRoute();

const editRoundQuestionsStore = useEditRoundQuestions();
const {addNotice} = useNoticesStore();

const data = ref({
    roundTitle: '',
    roundOrderId: null,
});

const getRouteQuizId = computed(() => {
    return route.params.quizId;
});

const getRouteRoundId = computed(() => {
    return route.params.roundId;
})

const questionCount = computed(() => {
    return editRoundQuestionsStore.getQuestions.length;
});

onMounted(() => {
    getQuestionData();
});

onBeforeUnmount(() => {
    editRoundQuestionsStore.clearQuestions();
});

const emit = defineEmits(['saved']);

const $toast = useToast();

const handleFileUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
        const response = api.post('/quiz/' + route.params.quizId + '/round/' + route.params.roundId + '/question/upload',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function (progressEvent) {
                    console.log(progressEvent);
                },
            });

        editRoundQuestionsStore.setQuestions(response.data.questions);
    } catch (error) {
        if (error.response) {
            $toast.open({
                message: error.response.data.message,
                type: 'error',
                duration: 100000,
                dismissible: false,
                position: 'top'
            });
        }
    }
}

const getQuestionData = async (e) => {
    const response = await api.get('/quiz/' + route.params.quizId + '/round/' + route.params.roundId + '/questions');
    if (response.data.success && response.data.questions) {
        editRoundQuestionsStore.setQuestions(response.data.questions);
        data.value.roundTitle = response.data.round_title;
        data.value.roundOrderId = response.data.round_order;
    }
}

const editQuestion = (question_id) => {
    emit('saved', {
        screen: 'editQuestion',
        questionId: question_id
    })
}

const openQuestionLibrary = () => {
    const { open, close } = useModal({
        component: Modal,
        attrs: {
            classes: 'panel',
            clickToClose: true,
            adaptive: true,
            draggable: false,
            scrollable: true,
            height: 'auto',
            width: '100%',
            maxWidth: '600px;',
        },
        slots: {
            default: useModalSlot({
                component: QuestionLibrary,
                onClose() {
                    close()
                },
            })
        }
    });

    open();
}

</script>
